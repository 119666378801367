header.header-management {
    width: 100%;
    min-height: 100vh;
    position: relative;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 5.67%, rgba(0, 0, 0, 0.488542) 10.88%, rgba(0, 0, 0, 0) 30.38%);
    background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%);
    background: url('../images/headermanagement.webp') no-repeat top;
    background-position: top left;
    background-size: cover;
}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}


header.header-management .animation-gradient {
    width: 100%;
    position: absolute;
    object-fit: cover;
    height: 100vh;
    z-index: 1;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    opacity: 0.4;
    animation: gradient 7s ease-in infinite;
    height: 100vh;
}

header.header-management .animation-svg {
    width: 100%;
    position: absolute;
    object-fit: cover;
    height: 100vh;
    z-index: 1;
    opacity: 0.7;
}

header.header-management .animation-svg svg {
    max-height: 100vh;
    height: 100%;
    width: 100%;
}

header.header-management .hero {
    min-height: 100vh;
    position: relative;
    z-index: 2;

}

header.header-management .hero-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

header.header-management .hero-text {
    padding-left: 5px;
    /* border-left:5px solid #F11576; */
    position: relative;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    /* max-width: 1100px !important; */
    margin-left: 15% !important;
    margin-right: auto !important;
}

header.header-management .hero-text:before {
    content: "";
    width: 6px;
    background: #F11576;
    height: 96%;
    position: absolute;
    top: 2%;
    left: -10px;
}

header.header-management .hero-text .sub-text1 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    /* font-size: 60px;
    line-height: 69px ; */
    letter-spacing: 1px;
    color: #FFFFFF;
}

header.header-management .hero-text .sub-text2 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    /* font-size: 43px; */
    /* line-height:20px !important; */
    letter-spacing: 1px;
    color: #FFFFFF;
    margin-bottom: -20px !important;
}


header.header-management .hero-text h6 { 
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    /* font-size: 40px !important; */
    line-height: 97.99% !important;
    line-height: 69px;
    letter-spacing: 1px;
    color: #FFFFFF;
}

header.header-management .hero-text-2 h5 { 
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    /* font-size: 40px !important; */
    line-height: 120% !important;
    letter-spacing: 1px;
    color: #FFFFFF;
    margin-top: 20px;
    margin-left: 15%;
    margin-right: 10%;
}



header.header-management .hero-text h2 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    /* font-size: 80px !important; */
    line-height: 70%;
    /* line-height: 105% !important; */
    /* letter-spacing: 1px; */
    color: #ffffff;
}

header.header-management .hero-text .spanh2 {
    color: #F9F5AA;
}

header.header-management .navbar-nav a {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.393478px;
    color: #FFFFFF;
}



/* For 480 Resolution */  
@media only screen   
and (min-device-width : 320px)   
and (max-device-width : 767px)  
{ /* STYLES GO HERE */

    header.header-management {
        /* background: url('../images/header-solution-bg-mb.webp') no-repeat top; */
        background-position: 50%;
    }
    
    header.header-management .hero-content {
        top:50%;
    }

    header.header-management .animation-svg svg {
        height: 100%;
    }

    header.header-management .hero-text {
        max-width: 315px !important;
    }
 
    header.header-management .hero-text h6 {
        font-size: 20px !important;
        line-height: 20px !important;
    }

    header.header-management .hero-text h2 {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    header.header-management .hero-text:before {
        width: 4px;
        left: -4px;
    }

    header.header-management .hero-text {
        margin-left: 10% !important;
        margin-right: auto !important;
    }

    header.header-management .hero-text-2 h5 {
        font-size: 18px !important;
        margin-left: 10% !important;
    }
} 




@media only screen  and (min-device-width : 768px) and (max-device-width : 1024px) { 

    
    header.header-management .hero-text {
        margin-left: 5%;
    }

    header.header-management .hero-text:before {
        top: 0px;
    }

    header.header-management .hero-text h6 {
        font-size: 36px;
        line-height: 42px;
    }

    header.header-management .hero-text h2 {
        font-size: 60px;
        line-height: 60px;
    }

    header.header-management .hero-text-2 h5 {
        font-size: 28px !important;
    }

} 





/* For 1024 Resolution */ 
@media  (min-width: 1024px)  and (max-width: 1280px)  
{ 

    header.header-management .hero-content {
        top:55%;
    }

    header.header-management .hero-text:before {
        height: 96%;
        top: 1%;
    }

    /* 84 */
    header.header-management .hero-text {
        max-width: 800px !important;
        margin: auto;
    }

    header.header-management .hero-text h2 {
        font-size: 84px;
        line-height: 97.99%;
    }

    header.header-management .hero-text h6 {
        font-size: 42px;
    } 

    header.header-management .hero-text h6 {
        font-size: 36px;
        line-height: 42px;
    }

    header.header-management .hero-text h2 {
        font-size: 60px;
        line-height: 60px;
    }

    header.header-management .hero-text-2 h5 {
        font-size: 28px !important;
    }
        
    
}  


@media  (min-width: 1280px)  and (max-width: 1360px)  
{ 
    header.header-management .hero-text:before {
        height: 96%;
        top: 1%;
    }

    /* 90 */
    header.header-management .hero-text {
        max-width: 870px !important;
        margin: auto;
    }

    header.header-management .hero-text h2 {
        font-size: 90px;
        line-height: 97.99%;
    }

    header.header-management .hero-text h6 {
        font-size: 45px;
    } 

    header.header-management .hero-text-2 h5 {
        font-size: 28px !important;
    }

}  

@media  (min-width: 1360px)  and (max-width: 1440px)  
{  
    header.header-management .hero-text:before {
        height: 96%;
        top: 1%;
    }

    /* 96 */ 
    header.header-management .hero-text {
        max-width: 900px;
        margin: auto;
    }
    
    header.header-management .hero-text h2 {
        font-size: 96px;
        line-height: 97.99%;
    }

    header.header-management .hero-text h6 {
        font-size: 48px;
    } 
    header.header-management .hero-text-2 h5 {
        font-size: 28px !important;
    }
}  

@media  (min-width: 1440px)  and (max-width: 1680px)  
{  

    header.header-management .hero-text:before {
        height: 96%;
        top: 0%;
    }


    /* 102 */
    header.header-management .hero-text {
        max-width: 920px;
        margin: auto;
    }

    header.header-management .hero-text h2 {
        font-size: 102px;
        line-height: 97.99%;
    }


    header.header-management .hero-text h6 {
        font-size: 51px;
    } 

    header.header-management .hero-text-2 h5 {
        font-size: 28px !important;
    }
}  

@media  (min-width: 1680px)  and (max-width: 1919px)  
{ 

    header.header-management .hero-text:before {
        height: 96%;
        top: 1%;
    }

    /* 108 */
    header.header-management .hero-text {
        max-width: 970px;
        margin: auto;
    }

    header.header-management .hero-text h2 {
        font-size: 108px;
        line-height: 97.99%;
    }

    header.header-management .hero-text h6 {
        font-size: 54px;
    } 

    header.header-management .hero-text-2 h5 {
        font-size: 28px !important;
    }


}  
