.whatwedo1-section .content-box {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    vertical-align: middle;
    padding-right: 10%;
    height: 100%;
}

.whatwedo1-section {
    /* margin-top: 30px; */
    margin-bottom: 160px;
}
.whatwedo1-section .relative-positin {
    position: relative;

}

.whatwedo1-section .content-box .h3 {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    line-height: 65px;
    text-transform: capitalize;
    color: #05304D;
    transform: translateY(35px);
    font-size: 40px !important;
    line-height: 59px !important;
    margin-left: 27px;
}

.whatwedo1-section .content-box .strok {
    width: fit-content;
    text-align: center;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px rgba(132, 207, 200, 0.7);
    -webkit-text-stroke: 1px rgba(132, 207, 200, 0.7);
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    /* font-size: 160px; */
    text-transform: capitalize;
    font-size: 145px;
    line-height: 180px;
    left: 50px;
    top: 5px;
    position: absolute;
    font-size: 100px !important;
    line-height: 150px !important;
    z-index: -1;
    white-space: nowrap;
}


.whatwedo1-section .content-box h2 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 69px;

    color: #05304D;
}

.whatwedo1-section .content-box .h1 {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    font-size: 110px;
    line-height: 141px;
    /* text-transform: capitalize; */
    color: #05304D;
    font-size: 80px !important;
    line-height: 100px !important;
}


.whatwedo1-section .content-box .h1.anima span.anima-underline {
    color: #05304D;
    display: inline-block;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    vertical-align: bottom;
    margin-left: 25px;
}

.whatwedo1-section .content-box .h1.anima span.anima-underline:after {
    background: linear-gradient(to bottom, rgba(20, 20, 20, 0.8), rgba(20, 20, 20, 0.8)) center 1.08em/100% 5px no-repeat;
    content: "";
    height: 100%;
    right: 0;
    position: absolute;
    top: 0;
    width: 400%;
    will-change: transform;
    z-index: 1;
    -webkit-animation: underline-gradient 6s linear infinite;
    animation: underline-gradient 6s linear infinite;
    background-image: linear-gradient(270deg, rgba(241, 21, 118, 1) 0%, rgba(1, 198, 178, 1) 20%, rgba(241, 21, 118, 1) 40%, rgba(1, 198, 178, 1) 60%, rgba(241, 21, 118, 1) 80%, rgba(1, 198, 178, 1) 100%);
}

.whatwedo1-section img {
    width: 100%;
    height: 587px;
    border-radius: 22px;

}

.whatwedo1-section .img-text {
    margin-top: -220px;
    padding: 30px;
}

.whatwedo1-section .img-text .img-heading {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 46px;
    /* identical to box height */

    text-transform: capitalize;

    color: #FFFFFF;
}

.whatwedo1-section .img-text .img-paragraph {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    /* or 26px */


    color: #FFFFFF;

}

.whatwedo1-section .side-div h4 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    text-transform: capitalize;

    color: #000000;
}

.whatwedo1-section .side-div p {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    font-size: 20px;
    line-height: 150%;
    /* or 27px */

    /* text-align: right; */

    color: #000000;

}

.whatwedo1-section .padding-div {
    /* padding-top: 90px; */
    padding-left: 20px;
    
}

.whatwedo1-section .padding-div2 {
    /* padding-top: 0px; */
    padding-right: 30px;
    margin-top: -10px;
}

.whatwedo1-section .marker
 {
    position: absolute;
    width: 65.54px;
    height: 65.54px;
    /* left: 90%; */
    right: 0%;
    background: #01C6B2;
    opacity: 0.1;
    transform: rotate(-60deg);
}
.whatwedo1-section .marker2
 {
    position: absolute;
    width: 65.54px;
    height: 65.54px;
    left: -3%;
    background: #F11576;
    opacity: 0.1;
    transform: rotate(-60deg);
}
.whatwedo1-section .marker3
 {
    position: absolute;
    width: 65.54px;
    height: 65.54px;
    right: 0%;
    background: #F11576;
    opacity: 0.1;
    transform: rotate(-60deg);
}
.whatwedo1-section .marker4
 {
    position: absolute;
    width: 65.54px;
    height: 65.54px;
    left: -3%;
    background: #01C6B2;
    opacity: 0.1;
    transform: rotate(-60deg);
}
.whatwedo1-section .mobile{
    display: none;
}


.whatwedo1-section .content-box.mobile {
    max-width: 250px;
    margin: auto;
}

/* .whatwedo1-section .marker {
    top: 205%;
}
.whatwedo1-section .marker2 {
    top: 220%;
} */
 /* .whatwedo1-section .gredient-border:before,
.whatwedo1-section .gredient-border:after {
    content: "";
    position: absolute;
    width: 50%;
    height: 50%;
    border-radius: 20px;
    display: block;
    z-index: -1;
} 

.whatwedo1-section .gredient-border:before {
    border-width:2px 0 0 2px;
    background:#FE54BB;
    background: linear-gradient(45deg, #FE54BB , transparent);
    left: -2px;
    top: -3px;
}

.whatwedo1-section .gredient-border:after {
    border-width:0 2px 2px 0;
    background:#11E9C7;
    background: linear-gradient(45deg, #11E9C7 , transparent);
    bottom: -2px;
    right: -2px;
} */
/* For 480 Resolution */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .whatwedo1-section .desktop{
        display: none;
    }
    .whatwedo1-section .mobile{
        display: block;
       
        /* position: absolute;
        top: 20px;
        left: 70%; */
    }


    .whatwedo1-section .content-box.mobile {
        max-width: 230px;
        margin: auto;
    }


    .whatwedo1-section{
        padding-bottom: 90px;
    }

   
    
    .whatwedo1-section .content-box .h3 {
        color: #000;
        font-size: 26px !important;
        font-family: Helvetica;
        transform: translateY(-10px);
         margin-left: 0px; 
    }

    .whatwedo1-section .content-box .strok {
        width: fit-content;
        text-align: left;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 1px rgba(132, 207, 200, 0.7);
        -webkit-text-stroke: 1px rgba(132, 207, 200, 0.7);
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 700;
        /* font-size: 160px; */
        text-transform: capitalize;
        font-size: 60px !important;
        line-height: 70px !important;
        left: 0px;
        top: -10px;
        position: absolute;
        z-index: -1;

    }
    .whatwedo1-section .content-box .mobile-strok2{
        position: absolute;
        left: 0px;
    }

    .whatwedo1-section .content-box .h1.anima span.anima-underline{
        color: #000;
        margin-left: 0px;
        transform: translateY(-15px);
        width: 156px;
    }

    .whatwedo1-section .content-box .h1 {
        color: #000;
        font-size: 50px !important; 
        font-family: Helvetica;
        font-weight: 700;
        line-height: 60px !important;
        position: relative;
        transform: translateY(-35px);
        /* top: -150% !important; */
        left: 0px;
    }


    .whatwedo1-section .content-box .h1.anima {
        line-height: 70px !important;
    }

    .whatwedo1-section .content-box .h1.anima span.anima-underline:after {
        margin-top: 10px;
    }

    .whatwedo1-section .content-box .mobile-h1{
        position: relative;
        left: 0px;
    }

    .whatwedo1-section .row{
        display: flex;
        flex-direction: column;
    }
    .whatwedo1-section img {
        width: 100%;
        height: 250px;
    }

    .whatwedo1-section .img-text {
        margin-top: -180px;
        padding: 30px;
    }

    .whatwedo1-section .img-text .img-heading {

        font-size: 18px;
        line-height: 46px;
        /* identical to box height */

        text-transform: capitalize;

        color: #FFFFFF;
    }

    .whatwedo1-section .img-text .img-paragraph {

        font-size: 14px;
        font-family: Helvetica;
        line-height: 130%;

        color: #FFFFFF;

    }

    .whatwedo1-section .side-div h4 {
        color: #000;
        font-size: 20px !important;
        font-family: Helvetica;
        font-weight: 700 !important;
        text-transform: capitalize;
        text-align: left;
    }

    .whatwedo1-section .side-div p {
        color: #000;
        font-size: 12px;
        font-size: 18px;
        font-family: Helvetica;
        line-height: 150%;
        text-align: left;

    }

    .whatwedo1-section .marker,.whatwedo1-section .marker2,.whatwedo1-section .marker4,.whatwedo1-section .marker3 {
        width: 34.951px;
        height: 34.951px;
        transform: rotate(-60deg);
        flex-shrink: 0;
        position: absolute;
        left: -3px;
    }

    /* .whatwedo1-section .marker2 {
        width: 34.951px;
        height: 34.951px;
        transform: rotate(-60deg);
        flex-shrink: 0;
        position: absolute;
        left: -3px;
    } */

    .whatwedo1-section .padding-div {
        padding-top: 0px;
        padding-left: 0px;

    }
    .whatwedo1-section .mt-div{
        margin-top: 20px !important;
    }
    .whatwedo1-section .mt-div2{
        margin-top: 0px !important;

    }
    .whatwedo1-section {
        /* margin-top: 30px; */
        margin-bottom: -40px !important;
    }
    .whatwedo1-section .marker3 {
        background: #01C6B2;

    }

    .whatwedo1-section .marker4 {
        background: #F11576;

    }

}
/* For 640 Resolution */
@media only screen and (min-device-width : 481px) and (max-device-width : 767px) {
    .whatwedo1-section .desktop{
        display: none;
    }
    .whatwedo1-section .mobile{
        display: block;
        /* position: absolute;
        top: 20px;
        left: 70%; */
    }


    .whatwedo1-section .content-box.mobile {
        max-width: 240px;
        margin: auto;
    }

    .whatwedo1-section{
        padding-bottom: 90px;
    }
    .whatwedo1-section .content-box .h3 {
        color: #000;
        font-size: 26px !important;
        font-family: Helvetica;
        transform: translateY(-25px);
        margin-left: 0px; 
    }

    .whatwedo1-section .content-box .strok {
        width: fit-content;
        text-align: left;
        font-size: 60px !important;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 1px rgba(132, 207, 200, 0.7);
        -webkit-text-stroke: 1px rgba(132, 207, 200, 0.7);
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 700;
        /* font-size: 160px; */
        text-transform: capitalize;
        line-height: 70px !important;
        left: 0px;
        top: -25px;
        position: absolute;
        z-index: -1;

    }
    .whatwedo1-section .content-box .mobile-strok2{
        position: absolute;
        left: 0px;
    }

    .whatwedo1-section .content-box .h1.anima span.anima-underline{
        color: #000;
        margin-left: 0px;
        line-height: 70px;
        width: 156px;
    }

    .whatwedo1-section .content-box .h1.anima span.anima-underline:after {
        margin-top: 10px;
    }

    .whatwedo1-section .content-box .h1 {
        color: #000;
        font-size: 50px !important;
        font-family: Helvetica;
        font-weight: 700;
        line-height: 30px !important;
        position: relative;
        transform: translateY(-35px);
        /* top: -20%; */
        left: 0px;
    }

    .whatwedo1-section .content-box .mobile-h1{
        position: relative;
        left: 26px;
    }
    .whatwedo1-section .row{
        display: flex;
        flex-direction: column;
    }
    .whatwedo1-section img {
        width: 100%;
        height: 250px;
    }

    .whatwedo1-section .img-text {
        margin-top: -180px;
        padding: 30px;
    }

    .whatwedo1-section .img-text .img-heading {

        font-size: 18px;
        line-height: 46px;
        /* identical to box height */

        text-transform: capitalize;

        color: #FFFFFF;
    }

    .whatwedo1-section .img-text .img-paragraph {

        font-size: 16px;
        font-family: Helvetica;
        line-height: 130%;

        color: #FFFFFF;

    }

    .whatwedo1-section .side-div h4 {
        color: #000;
        font-size: 20px !important;
        font-family: Helvetica;
        font-weight: 700 !important;
        text-transform: capitalize;
        text-align: left;
    }

    .whatwedo1-section .side-div p {
        color: #000;
        font-size: 12px;
        font-size: 18px;
        font-family: Helvetica;
        line-height: 150%;
        text-align: left;

    }

    .whatwedo1-section .marker,.whatwedo1-section .marker2,.whatwedo1-section .marker4,.whatwedo1-section .marker3 {
        width: 34.951px;
        height: 34.951px;
        transform: rotate(-60deg);
        flex-shrink: 0;
        position: absolute;
        left: -3px;
    }

    /* .whatwedo1-section .marker2 {
        width: 34.951px;
        height: 34.951px;
        transform: rotate(-60deg);
        flex-shrink: 0;
        position: absolute;
        left: -3px;
    } */

    .whatwedo1-section .padding-div {
        padding-top: 0px;
        padding-left: 0px;

    }
    .whatwedo1-section .mt-div{
        margin-top: 20px !important;
    }
    .whatwedo1-section .mt-div2{
        margin-top: 0px !important;

    }
    .whatwedo1-section {
        /* margin-top: 30px; */
        margin-bottom: -40px !important;
    }
    .whatwedo1-section .marker3 {
        background: #01C6B2;

    }

    .whatwedo1-section .marker4 {
        background: #F11576;

    }

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
    .whatwedo1-section .desktop{
        display: none;
    }
    .whatwedo1-section .mobile{
        display: block;
    }

    .whatwedo1-section .content-box.mobile {
        max-width: 280px;
        margin: auto;
    }

    .whatwedo1-section .col-md-6{
        width: 100%;
    }

    

    .whatwedo1-section{
        padding-bottom: 90px;
    }
    .whatwedo1-section .content-box .h3 {
        color: #000;
        font-size: 26px !important;
        font-family: Helvetica;
        transform: translateY(-25px);
        margin-left: 0px; 
    }

    .whatwedo1-section .content-box .strok {
        width: fit-content;
        text-align: left;
        font-size: 60px !important;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 1px rgba(132, 207, 200, 0.7);
        -webkit-text-stroke: 1px rgba(132, 207, 200, 0.7);
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 700;
        /* font-size: 160px; */
        text-transform: capitalize;
        line-height: 70px !important;
        left: 0px;
        top: -25px;
        position: absolute;
        z-index: -1;

    }
    .whatwedo1-section .content-box .mobile-strok2{
        position: absolute;
        left: 0px;
    }

    .whatwedo1-section .content-box .h1.anima span.anima-underline{
        color: #000;
        margin-left: 0px;
        line-height: 60px;
        width: 156px;
    }

    .whatwedo1-section .content-box .h1 {
        color: #000;
        font-size: 50px !important;
        font-family: Helvetica;
        font-weight: 700;
        line-height: 30px !important;
        position: relative;
        transform: translateY(-35px);
        /* top: -20%; */
        left: 0px;
    }

    .whatwedo1-section .content-box .mobile-h1{
        position: relative;
        left: 26px;
    }
    .whatwedo1-section .row{
        display: flex;
        flex-direction: column;
    }
    .whatwedo1-section img {
        width: 100%;
        height: auto;
    }

    .whatwedo1-section .img-text {
        margin-top: -180px;
        padding: 30px;
    }

    .whatwedo1-section .img-text .img-heading {

        font-size: 18px;
        line-height: 46px;
        /* identical to box height */

        text-transform: capitalize;

        color: #FFFFFF;
    }

    .whatwedo1-section .img-text .img-paragraph {

        font-size: 18px;
        font-family: Helvetica;
        line-height: 130%;

        color: #FFFFFF;

    }

    .whatwedo1-section .side-div h4 {
        color: #000;
        font-size: 20px !important;
        font-family: Helvetica;
        font-weight: 700 !important;
        text-transform: capitalize;
        text-align: left;
    }

    .whatwedo1-section .side-div p {
        color: #000;
        font-size: 12px;
        font-size: 18px;
        font-family: Helvetica;
        line-height: 150%;
        text-align: left;

    }

    .whatwedo1-section .marker,.whatwedo1-section .marker2,.whatwedo1-section .marker4,.whatwedo1-section .marker3 {
        width: 34.951px;
        height: 34.951px;
        transform: rotate(-60deg);
        flex-shrink: 0;
        position: absolute;
        left: -3px;
    }

    /* .whatwedo1-section .marker2 {
        width: 34.951px;
        height: 34.951px;
        transform: rotate(-60deg);
        flex-shrink: 0;
        position: absolute;
        left: -3px;
    } */

    .whatwedo1-section .padding-div {
        padding-top: 0px;
        padding-left: 0px;

    }
    .whatwedo1-section .mt-div{
        margin-top: 20px !important;
    }
    .whatwedo1-section .mt-div2{
        margin-top: 0px !important;

    }
    .whatwedo1-section {
        /* margin-top: 30px; */
        margin-bottom: -40px !important;
    }
    .whatwedo1-section .marker3 {
        background: #01C6B2;

    }

    .whatwedo1-section .marker4 {
        background: #F11576;

    }

}


@media (max-device-width : 1023px) {
    .whatwedo1-section .order1{
        order: 1;
    }
}
@media (min-width: 1024px) and (max-width: 1080px) {
    .whatwedo1-section .content-box .h3 {
        transform: translateY(26px);
        font-size: 38.5px;
        line-height: 45.5px;
    }
    .whatwedo1-section .content-box .strok {
        font-size: 112px;
        line-height: 126px;
    }
    .whatwedo1-section .content-box h2 {       
        font-size: 42px;
        line-height: 48.3px;
    }
    .whatwedo1-section .content-box .h1 {
        font-size: 84px;
        line-height: 98.7px;
    }
    /* content */
    /* img */
    .whatwedo1-section img {
        height: 410.9px;
    }
    /* imgtext margin */
    .whatwedo1-section .img-text {
        margin-top: -190px;
    }
    .whatwedo1-section .img-text2{
        margin-top: -218px;
    }
    .whatwedo1-section .img-text .img-heading {
        font-size: 28px;
        line-height: 32.2px;
    }
    
    .whatwedo1-section .img-text .img-paragraph {
        font-size: 14px;
        line-height: 91%;
    }
    /* side div text */
    .whatwedo1-section .side-div h4 {
        font-size: 19.6px;
        line-height: 22.4px;
    }
    
    .whatwedo1-section .side-div p {
        font-size: 12.6px;
        line-height: 105%;
    }
    /* marker */
    .whatwedo1-section .marker,.whatwedo1-section .marker2,.whatwedo1-section .marker3,.whatwedo1-section .marker4 {
        width: 45.878px;
        height: 45.878px;
    }

}
@media (min-width: 1080px) and (max-width: 1280px) {
    .whatwedo1-section .content-box .h3 {
        transform: translateY(28px);
        font-size: 41.25px;
        line-height: 48.75px;
    }
    .whatwedo1-section .content-box .strok {
        font-size: 120px;
        line-height: 135px;
    }
    .whatwedo1-section .content-box h2 {       
        font-size: 45px;
        line-height: 51.75px;
    }
    .whatwedo1-section .content-box .h1 {
        font-size: 90px;
        line-height: 105.75px;
    }
     /* content */
    /* img */
    .whatwedo1-section img {
        height: 440.25px;
    }
    /* imgtext margin */
    .whatwedo1-section .img-text {
        margin-top: -190px;
    }
    .whatwedo1-section .img-text2{
        margin-top: -210px;
    }
    .whatwedo1-section .img-text .img-heading {
        font-size: 30px;
        line-height: 34.5px;
    }
    
    .whatwedo1-section .img-text .img-paragraph {
        font-size: 15px;
        line-height: 97.5%;
    }
    /* side div text */
    .whatwedo1-section .side-div h4 {
        font-size: 21px;
        line-height: 24px;
    }
    
    .whatwedo1-section .side-div p {
        font-size: 13.5px;
        font-size: 15px;
        line-height: 112.5%;
    }
    /* marker */
    .whatwedo1-section .marker,.whatwedo1-section .marker2,.whatwedo1-section .marker3,.whatwedo1-section .marker4  {
        width: 49.155px;
        height:49.155px;
    }
    
}
@media (min-width: 1280px) and (max-width: 1360px) {
    .whatwedo1-section .content-box .h3 {
        transform: translateY(28px);
        font-size: 44px;
        line-height: 52px;
    }
    .whatwedo1-section .content-box .strok {
        font-size: 128px;
        line-height: 144px;
    }
    .whatwedo1-section .content-box h2 {       
        font-size: 48px;
        line-height: 55.2px;
    }
    .whatwedo1-section .content-box .h1 {
        font-size: 96px;
        line-height: 112.8px;
    }
  /* content */
    /* img */
    .whatwedo1-section img {
        height: 469.6px;
    }
    /* imgtext margin */
    .whatwedo1-section .img-text {
        margin-top: -190px;
    }
    .whatwedo1-section .img-text2{
        margin-top: -210px;
    }
    .whatwedo1-section .img-text .img-heading {
        font-size: 32px;
        line-height: 36.8px;
    }
    
    .whatwedo1-section .img-text .img-paragraph {
        font-size: 16px;
        line-height: 104%;
    }
    /* side div text */
    .whatwedo1-section .side-div h4 {
        font-size: 22.4px;
        line-height: 25.6px;
    }
    
    .whatwedo1-section .side-div p {
        font-size: 14.4px;
        font-size: 16px;
        line-height:120%;
    }
    /* marker */
    .whatwedo1-section .marker,.whatwedo1-section .marker2,.whatwedo1-section .marker3,.whatwedo1-section .marker4  {
        width: 52.432px;
        height:52.432px;
    }
    
}
@media (min-width: 1360px) and (max-width: 1440px) {
    .whatwedo1-section .content-box .h3 {
        font-size:46.75px;
        line-height: 55.25px;
        transform: translateY(28px);
    }
    .whatwedo1-section .content-box .strok {
        font-size:136px;
        line-height: 153px;
    }
    .whatwedo1-section .content-box h2 {       
        font-size: 51px;
        line-height: 58.65px;
    }
    .whatwedo1-section .content-box .h1 {
        font-size: 102px;
        line-height: 119.85px;
    }
     /* content */
    /* img */
    .whatwedo1-section img {
        height: 498.95px;
    }
    /* imgtext margin */
    .whatwedo1-section .img-text {
        margin-top: -190px;
    }
    .whatwedo1-section .img-text2{
        margin-top: -210px;
    }
    .whatwedo1-section .img-text .img-heading {
        font-size: 34px;
        line-height: 39.1px;
    }
    
    .whatwedo1-section .img-text .img-paragraph {
        font-size: 17px;
        line-height: 110.5%;
    }
    /* side div text */
    .whatwedo1-section .side-div h4 {
        font-size: 23.8px;
        line-height: 27.2px;
    }
    
    .whatwedo1-section .side-div p {
        font-size: 15.3px;
        font-size: 17px;
        line-height:127.5%;
    }
    /* marker */
    .whatwedo1-section .marker,.whatwedo1-section .marker2,.whatwedo1-section .marker3,.whatwedo1-section .marker4  {
        width: 55.709px;
        height:55.709px;
    }
    
}
@media (min-width: 1440px) and (max-width: 1680px) {
    .whatwedo1-section .content-box .h3 {
        font-size:49.5px;
        line-height: 58.5px;
        transform: translateY(28px);
    }
    .whatwedo1-section .content-box .strok {
        font-size:144px;
        line-height: 162px;
    }
    .whatwedo1-section .content-box h2 {       
        font-size: 54px;
        line-height: 62.1px;
    }
    .whatwedo1-section .content-box .h1 {
        font-size: 108px;
        line-height: 126.9px;
    }
    /* content */
    /* img */
    .whatwedo1-section img {
        height: 528.3px;
    }
    /* imgtext margin */
    .whatwedo1-section .img-text {
        margin-top: -190px;
    }
    .whatwedo1-section .img-text2{
        margin-top: -210px;
    }
    .whatwedo1-section .img-text .img-heading {
        font-size: 36px;
        line-height: 41.4px;
    }
    
    .whatwedo1-section .img-text .img-paragraph {
        font-size: 18px;
        line-height: 117%;
    }
    /* side div text */
    .whatwedo1-section .side-div h4 {
        font-size: 25.2px;
        line-height: 28.8px;
    }
    
    .whatwedo1-section .side-div p {
        font-size: 16.2px;
        font-size: 18px;
        line-height:135%;
    }
    /* marker */
    .whatwedo1-section .marker,.whatwedo1-section .marker2,.whatwedo1-section .marker3,.whatwedo1-section .marker4  {
        width: 58.986px;
        height:58.986px;
    }

}
@media (min-width: 1680px) and (max-width: 1919px) {
    .whatwedo1-section .content-box .h3 {
        font-size:52.25px;
        line-height: 61.75px;
        transform: translateY(28px);
    }
    .whatwedo1-section .content-box .strok {
        font-size:152px;
        line-height: 171px;
    }
    .whatwedo1-section .content-box h2 {       
        font-size: 57px;
        line-height: 65.55px;
    }
    .whatwedo1-section .content-box .h1 {
        font-size: 114px;
        line-height: 133.95px;
    }
    /* content */
    /* img */
    .whatwedo1-section img {
        height: 557.65px;
    }
    /* imgtext margin */
    .whatwedo1-section .img-text {
        margin-top: -190px;
    }
    .whatwedo1-section .img-text2{
        margin-top: -210px;
    }
    .whatwedo1-section .img-text .img-heading {
        font-size: 38px;
        line-height: 43.7px;
    }
    
    .whatwedo1-section .img-text .img-paragraph {
        font-size: 19px;
        line-height: 123.5%;
    }
    /* side div text */
    .whatwedo1-section .side-div h4 {
        font-size: 26.6px;
        line-height: 30.4px;
    }
    
    .whatwedo1-section .side-div p {
        font-size: 17.1px;
        font-size: 19px;
        line-height:142.5%;
    }
    /* marker */
    .whatwedo1-section .marker,.whatwedo1-section .marker2,.whatwedo1-section .marker3,.whatwedo1-section .marker4  {
        width: 62.263px;
        height:62.263px;
    }
}
@media only screen and (min-width: 1920px) {}

@media (min-width: 1680px)  {

    .whatwedo1-section .container {
        max-width: 1540px;
    }    
}