.casestudy-section img {
    width: 100%;
    min-height: 250px;
    max-height: 250px;
    /* opacity: 0.5;
background-color: #042421 !important;
mix-blend-mode: multiply; */
}

.casestudy-section h2 {
    color: #000;
    font-family: Helvetica;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.casestudy-section p {
    color: #000;
font-family: Helvetica;
font-size: 18px;
font-style: normal;
font-weight: 300;
line-height: normal;
}

.casestudy-section button {
    color: #03AD9B;
    text-align: center;
    font-family: Helvetica;
    font-size: 18px;
    line-height: 21px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    /* width: 313px;
    height: 83px;
    padding: 15px 17px; */
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid #03A796;
    background-color: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
}
.casestudy-section p.anima span.anima-underline {
    color: transparent;
    display: inline-block;
    overflow: hidden;
    position: relative;
    width: 179px;
    text-decoration: none;
    vertical-align: bottom;
}

.casestudy-section p.anima span.anima-underline:after {
    background: linear-gradient(to bottom, rgba(20, 20, 20, 0.8), rgba(20, 20, 20, 0.8)) center 1.08em/100% 5px no-repeat;
    content: "";
    height: 100%;
    margin-top: 0px;
    right: 0;
    position: absolute;
    top: -15px;
    width: 400%;
    will-change: transform;
    z-index: 1;
    -webkit-animation: underline-gradient 6s linear infinite;
    animation: underline-gradient 6s linear infinite;
    background-image: linear-gradient(270deg, rgba(241, 21, 118, 1) 0%, rgba(1, 198, 178, 1) 20%, rgba(241, 21, 118, 1) 40%, rgba(1, 198, 178, 1) 60%, rgba(241, 21, 118, 1) 80%, rgba(1, 198, 178, 1) 100%);

}
/* .casestudy-section .row:nth-child(4){
    margin-top: 30px !important;
    padding-top: 30px !important;
} */