.aboutteam-section {
    height: 1120px;
    height: auto;
    /* min-height: 100vh; */
    left: 1792px;
    top: 0px;
padding-bottom: 500px;
    background: linear-gradient(121.92deg, #041E30 13.01%, #000000 74.52%);
    /* transform: matrix(-1, 0, 0, 1, 0, 0); */
}

.aboutteam-section .content-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    vertical-align: middle;
    height: auto;
    /* margin-top: 150px; */
    position: relative;
    min-height: 250px;
}



.aboutteam-section .content-box h2 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 70px;
    text-transform: capitalize;

    color: #FFFFFF;

    z-index: 1;
    position: relative;
    top: -100px;
    left: -80px;

    font-size: 40px !important;
    line-height: 59px !important;
    position: absolute;
    top: 15px;
    left: -80px;
    /* transform: translateX(-70px); */
}

.aboutteam-section .content-box .strok {
    width: fit-content;
    text-align: center;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px rgba(132, 207, 200, 0.7);
    font-family: 'Helvetica';
    position: relative;
    font-weight: 700;
    font-size: 160px;
    line-height: 180px;
    left: 250px;
    top: -200px;

    position: absolute;
    font-size: 100px !important;
    line-height: 120px !important;
    left: 130px;
    top: 15px;


}


.aboutteam-section .content-box .h1 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 120px;
    line-height: 141px;
    text-transform: capitalize;

    color: #FFFFFF !important;
    z-index: 1;
    position: relative;
    top: -320px;
    left: -80px;

    position: absolute;
    font-size: 80px !important;
    line-height: 100px !important;
    top: 50px;
    left: -80px;
}

.aboutteam-section .content-box .h1.anima span.anima-underline {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 120px;
    line-height: 141px;
    /* identical to box height */

    text-transform: capitalize;
    font-size: 80px !important;
    line-height: 100px !important;
    color: #FFFFFF !important;
    display: inline-block;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    vertical-align: bottom;

}

.aboutteam-section .content-box .h1.anima span.anima-underline:after {
    background: linear-gradient(to bottom, rgba(20, 20, 20, 0.8), rgba(20, 20, 20, 0.8)) center 1.08em/100% 5px no-repeat;
    content: "";
    height: 100%;
    right: 0;
    position: absolute;
    top: 0;
    width: 400%;
    will-change: transform;
    z-index: 1;
    -webkit-animation: underline-gradient 6s linear infinite;
    animation: underline-gradient 6s linear infinite;
    background-image: linear-gradient(270deg, rgba(241, 21, 118, 1) 0%, rgba(1, 198, 178, 1) 20%, rgba(241, 21, 118, 1) 40%, rgba(1, 198, 178, 1) 60%, rgba(241, 21, 118, 1) 80%, rgba(1, 198, 178, 1) 100%);
}

/* senior team */

.aboutteam-section .content-box.senior-team {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    vertical-align: middle;
    height: auto;
    /* margin-top: 150px; */
    position: relative;
    min-height: 280px;
    max-width: 280px;
    margin: auto; 
}



.aboutteam-section .content-box.senior-team h2 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 70px;
    text-transform: capitalize;

    color: #FFFFFF;

    z-index: 1;
    position: relative;
    top: -100px;
    left: -80px;

    font-size: 40px !important;
    line-height: 59px !important;
    position: absolute;
    top: 15px;
    left: 0px;
    /* transform: translateX(-70px); */
}

.aboutteam-section .content-box.senior-team .strok {
    width: fit-content;
    text-align: center;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px rgba(132, 207, 200, 0.7);
    font-family: 'Helvetica';
    position: relative;
    font-weight: 700;
    font-size: 160px;
    line-height: 180px;
    left: -50px;
    top: -200px;

    position: absolute;
    font-size: 100px !important;
    line-height: 120px !important;
    left: 30px;
    top: 15px;


}


.aboutteam-section .content-box.senior-team .h1 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 120px;
    line-height: 141px;
    text-transform: capitalize;

    color: #FFFFFF !important;
    z-index: 1;
    position: absolute;
    font-size: 80px !important;
    line-height: 100px !important;
    top: 50px;
    left: 0px;
}

.aboutteam-section .content-box.senior-team .h1.anima span.anima-underline {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 120px;
    line-height: 141px;
    /* identical to box height */

    text-transform: capitalize;
    font-size: 80px !important;
    line-height: 100px !important;
    color: #FFFFFF !important;
    display: inline-block;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    vertical-align: bottom;

}

.aboutteam-section .content-box.senior-team .h1.anima span.anima-underline:after {
    background: linear-gradient(to bottom, rgba(20, 20, 20, 0.8), rgba(20, 20, 20, 0.8)) center 1.08em/100% 5px no-repeat;
    content: "";
    height: 100%;
    right: 0;
    position: absolute;
    top: 10px;
    width: 400%;
    will-change: transform;
    z-index: 1;
    -webkit-animation: underline-gradient 6s linear infinite;
    animation: underline-gradient 6s linear infinite;
    background-image: linear-gradient(270deg, rgba(241, 21, 118, 1) 0%, rgba(1, 198, 178, 1) 20%, rgba(241, 21, 118, 1) 40%, rgba(1, 198, 178, 1) 60%, rgba(241, 21, 118, 1) 80%, rgba(1, 198, 178, 1) 100%);
}


@-webkit-keyframes underline-gradient {
    0% {
        transform: translate3d(0%, 0%, 0);
    }

    100% {
        transform: translate3d(75%, 0%, 0);
    }
}

@keyframes underline-gradient {
    0% {
        transform: translate3d(0%, 0%, 0);
    }

    100% {
        transform: translate3d(75%, 0%, 0);
    }
}



.aboutteam-section .s-team {
    text-align: center;
    max-width: 313px;
    width: 100%;
    margin: auto;
}

.aboutteam-section .s-team img {
     width: 100%;
     max-width: 313px;
     margin: 20px auto;
     border-radius: 20px;      
}

.aboutteam-section .s-team h4 {
    color: #FFF;
    text-align: center;
    font-family: Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.aboutteam-section .s-team h5 {
    color: #FFF;
    text-align: center;
    font-family: Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 23.4px */
    text-transform: capitalize;
}

.aboutteam-section .s-team p {
    color: #FFF;
    text-align: justify;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 18.2px */
    top: 10px;
}

.aboutteam-section .s-team .relative.img-box {
    max-width: 313px;
    margin: auto;
}

.aboutteam-section .s-team .relative .linkedin-icon {
    position: absolute;
    bottom: 30px;
    right: 20px;
}

.aboutteam-section .imgdiv1 {
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: relative;
    top: -50%; */
    /* margin-top: -250px; */
    position: relative;
    justify-content: flex-start;
    /* margin-top: -300px; */
}

.aboutteam-section .imgdiv1 img {
    width: 100%;
    max-width: 306px;
}

.bgblue {
    position: relative;
    background: #01C6B2;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 25px;
    text-align: center;
    position: absolute;
    max-width: 320px;
    width: 100%;
    left: 50px;
    bottom:-50px;
    max-height: 120px;
  
}

.bgblue h4 {
    color: #FFF;
    text-align: left;
    font-family: Helvetica;
    font-size: 33.968px;
    font-style: normal;
    font-weight: 700;
    /* line-height: normal; */
    text-transform: capitalize;

}

.bgblue h5 {
    color: #FFF;
    font-family: Helvetica;
    font-size: 22.079px;
    font-style: normal;
    text-align: left;
    font-weight: 300;
    /* line-height: 130%; */
    text-transform: capitalize;
}


.aboutteam-section .imgdiv {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    text-align: right;
    position: relative;
    /* top: 100px;
    left: 45%; */
    
}

.aboutteam-section .imgdiv img {
    width: 100%;
    max-width: 306px;
}

.hover-text-box .hover-text-box, .hover-text-box .hoverimg {
    display: none;
}

.hover-text-box:hover .imgdef ,.hover-text-box {
    display: none;
}
/* 
.aboutteam-section .imgdiv1 .imgdef:hover {
    display: none;
}

.aboutteam-section .imgdiv1 .imgdef:hover + .hoverimg {
    display: block;
} */

.aboutteam-section .imgdiv .team-image-box  {
    max-width: 306px;
    margin-left: auto;
}

.aboutteam-section .imgdiv1 .team-image-box  {
    max-width: 306px;
}

/* backup */

.aboutteam-section .team-hover-box .team-image-box:hover .imgdef, .hover-text-box:hover ~ .team-hover-box .team-image-box .imgdef{
        display: none !important;
}

.aboutteam-section .team-hover-box .team-image-box:hover .hoverimg , .hover-text-box:hover ~ .team-hover-box .team-image-box .hoverimg{
    display: block !important;
    cursor: pointer;
}

.aboutteam-section .team-hover-box .team-image-box:hover ~ .hover-text-box{

    display: flex;
}

.team-hover-box .hover-text-box:hover {
    display: flex;
}

.team-hover-box .hoverimg,
.team-hover-box .hover-text-box {
    display: none;
}

.imgdiv1 .hover-text-box {
    position: absolute;
    flex-direction: column;
    gap: 10px;
    top: 5px;
    right: 15%;
}

.imgdiv .hover-text-box {
    position: absolute;
    flex-direction: column;
    gap: 10px;
    top: 5px;
    left: 15%;
}

.hover-text-box .hover-text {
    display: flex; 
    width: 288px;
    height: 80px;
    padding: 10px 28px 20px;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
}

.hover-text-box .hover-text p {
    color: #FFF;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    line-height: 130%;
}

.hover-text-box .hover-text h4 {
    color: #FFF;
    text-align: justify;
    font-family: Helvetica;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 39px */
}

.imgdiv .hover-text-box .hover-text {
    border: 2px solid #FFF;
    background: linear-gradient(231deg, #B50553 0%, #FE4798 100%);
}

.imgdiv1 .hover-text-box .hover-text {
    border: 2px solid #FFF;
    background: linear-gradient(231deg, #01C6B2  0%, #01C6B2 100%);
}

.aboutteam-section .para1,
.aboutteam-section .para2 {
     color: #FFF;
    text-align: justify;
    font-family: Helvetica;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}


.aboutteam-section .para1 {
    margin-top: 80px;
    padding-top: 20px;
    margin-left: 50px;
    /* margin-right: 20px; */
}

.aboutteam-section .para2 {
    margin-top: 80px;
    padding-top: 20px;
    margin-right: 50px;
    /* margin-left: 40px; */
}

.bgpink {
    background: #F11576;
    text-align: center !important;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 25px;
    text-align: center;
    position: absolute;
    max-width: 300px;
    width: 100%;
    right: 50px;
    bottom:-50px;
    max-height: 120px;

}

.bgpink h4 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 35.4839px;
    line-height: 41px;
    text-align: left;
    text-transform: capitalize;
    /* padding-top: 15px; */
    display: block;
    color: #FFFFFF;
    /* Inside auto layout */
    /* flex: none;
order: 0;
flex-grow: 0; */
}

.bgpink h5 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 300;
    text-align: left;
    font-size: 23.0645px;
    line-height: 130%;
    text-transform: capitalize;
    color: #FFFFFF;

    /* flex: none;
    order: 1;
    flex-grow: 0; */
}

/* .seconddiv{
    position: absolute;
    right: 40px;
} */

.aboutteam-section .mobile {
    display: none;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .aboutteam-section {
        height: 100%;
        padding-bottom: 120px;
        margin-bottom: 0%;
        margin-top: 20px;
        overflow-x: hidden;
    }

    .aboutteam-section .mobile {
        position: relative !important;
    }
    
    .aboutteam-section .team-container{
        position: relative;
    }

    .aboutteam-section .ordersm-1 {
        order: 1;
    }
    .aboutteam-section .content-box {
        display: flex;
        justify-content: center !important;
        flex-direction: column;
        vertical-align: middle;
        height: auto;
        position: relative;
        text-align: center;
        top: 0%;
        left: 0%;
        min-height: 80px;
    }


    .aboutteam-section .content-box .strok {
        width: fit-content;
        text-align: center !important;
        font-size: 140px;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 1px rgba(132, 207, 200, 0.7);
        font-family: 'Helvetica';
        font-weight: 700;
        font-size: 58px !important;
        line-height: 180px !important;
        left: 20px;
        top: -30px;
        position: absolute;
    }

    .aboutteam-section .content-box .h3 {
        color: #000;
        font-size: 26px;
        font-family: Helvetica;
        transform: translateY(8px);
        position: relative;
        left: 50px;
        z-index: 2;
    }

    .aboutteam-section .content-box h2 {
        position: absolute;
        top: 20px;
        left: 30px;
        color: #FFF;
        font-size: 26px !important;
        font-weight: 400;
        font-family: Helvetica;
    }

    .aboutteam-section .content-box .h1 {
        color: #FFF;
        font-size: 50px !important;
        font-family: Helvetica;
        font-weight: 700;
        left: 30px;
        top: 35px;
        z-index: 1;
    }

    /* .aboutteam-section.mobile .content-box  .anima-underline { */
    .aboutteam-section .content-box .h1.anima span.anima-underline {
        color: #FFF;
        font-size: 50px !important;
        font-family: Helvetica;
        font-weight: 700;
    }

    .aboutteam-section .content-box .h1.anima span.anima-underline:after {
        top: 20px;
    }

    .aboutteam-section .imgdiv img,
    .aboutteam-section .imgdiv1 img {
        width: 170.364px;
    }

    .hover-text-box .hover-text-box,.hover-text-box .hoverimg {
        display: none;
    }

    
    .aboutteam-section .team-hover-box .team-image-box:hover .imgdef, .hover-text-box:hover ~ .team-hover-box .team-image-box .imgdef{
        /* .aboutteam-section .team-hover-box:hover .imgdef { */
        display: block;
    }
        
    .aboutteam-section .team-hover-box .team-image-box:hover .hoverimg , .hover-text-box:hover ~ .team-hover-box .team-image-box .hoverimg{
        /* .aboutteam-section .team-hover-box:hover .hoverimg { */
        display: none;
    }

    .aboutteam-section .team-hover-box .team-image-box:hover ~ .hover-text-box{
            /* .aboutteam-section .team-hover-box:hover .hover-text-box { */
        display: none;
    }

    .bgpink {
        max-width: 200px;
        max-height: 80px;
        padding: 10px 15px;
        right: 18px;
        bottom: -30px;
    }

    .bgblue {
        max-width: 200px;
        max-height: 80px;
        padding: 10px 15px;
        left: 18px;
        bottom: -30px;
    }

    .bgpink h4,
    .bgblue h4 {
        font-size: 18.559px;
        line-height: 36px;
        font-weight: 700;
        text-align: left;
    }

    .bgpink h5,
    .bgblue h5 {
        font-size: 13.363px;
        font-weight: 300;
        line-height: 50%;
        text-align: left;
    }


    .aboutteam-section .imgdiv1 {
        margin-left: 10px;
    }

    .aboutteam-section .imgdiv {
        margin-right: 10px;
    }

    .aboutteam-section .para2 {
        margin-top: 0px;
        padding-bottom: 40px;
        margin-right: 10px;
        margin-left: 10px;
        padding-top: 0px;
        padding-right: 0px;
        font-size: 14px;
        line-height: 130%;
        text-align: justify;
    }

    .aboutteam-section .para1 {
        margin-top: 0px;
        margin-right: 10px;
        margin-left: 10px;
        padding-left: 0px;
        padding-top: 0px;
        font-size: 14px;
        line-height: 130%;
        text-align: justify;
    }

    .aboutteam-section .s-team p {
        font-size: 14px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .aboutteam-section p {
        color: #FFF;
        text-align: justify;
        font-size: 12px;
        font-family: Helvetica;
        line-height: 130%;
        position: relative;
        top: 60px;
    }


    .aboutteam-section .senior-teams {
        flex-direction: column;
    }

    .aboutteam-section .content-box.senior-team {
        justify-content: flex-start !important;
        max-width: 300px !important;
        min-height: 180px !important;
    }

    .aboutteam-section .content-box.senior-team h2 {
        font-size: 26px !important; 
        left: 30px;
    }

    .aboutteam-section .content-box.senior-team .h1 {
        font-size: 50px !important;
        left: 30px;
    }

    .aboutteam-section .content-box.senior-team .h1.anima span.anima-underline {
        font-size: 50px !important;
    }

    .aboutteam-section .content-box.senior-team .h1.anima span.anima-underline:after {
        top: 24px;
    }

    .aboutteam-section .content-box.senior-team .strok {
        font-size: 58px !important;
        left: 20px;
    }


    .aboutteam-section.mobile .teampara2{
        padding-left: 40px;
        /* padding-top: 40px; */
    } 

    .aboutteam-section.mobile .teampara1{
        padding-right: 40px;
    }
    .aboutteam-section.mobile .img1,
    .aboutteam-section.mobile .img2 {
        width: 170.364px;
        height: 170.364px;
        flex-shrink: 0;
    }
    .aboutteam-section.mobile .img1{
        float: right;
    }
   
    .aboutteam-section.mobile h4 {
        color: #FFF;
        text-align: center;
        font-size: 20.559px;
        font-family: Helvetica;
        font-weight: 700;
        text-transform: capitalize;
    }
    .aboutteam-section.mobile h5{
        color: #FFF;
        text-align: justify;
        font-size: 13.363px;
        font-family: Helvetica;
        font-weight: 300;
        line-height: 50%;
        text-transform: capitalize;
    }
    .aboutteam-section.mobile .bgpink-mobile {
        width: 180.406px;
        height: 71.957px;
        flex-shrink: 0;
        background: #F11576;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction:column;
        position: absolute;
        top: 20%;
        left: 47%;
        /* float: right; */
    }

    .aboutteam-section.mobile .bgblue-mobile {
        width: 180.406px;
        height: 71.957px;
        flex-shrink: 0;
        background: #01C6B2;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction:column;
        position: absolute;
        top: 71%;
        left: 10%;
        /* margin-bottom: 50px; */
    }

    .aboutteam-section .desktop {
        display: none;
    }
    .aboutteam-section .mobile {
        display: block;
    }

    .aboutteam-section .imgdiv .team-image-box
    .aboutteam-section .imgdiv1 .team-image-box {
        margin: auto !important;
        max-width: 100% !important;
    }

    .aboutteam-section .imgdiv img, .aboutteam-section .imgdiv1 img {
        width: 100%;
    }

 
}

@media only screen and (min-device-width : 481px) and (max-device-width : 767px) {
    .aboutteam-section {
        height: 100%;
        padding-bottom: 120px;
        margin-bottom: 80px;
        overflow-x: hidden;
    }
    .aboutteam-section .mobile {
        position: relative !important;
    }
    .aboutteam-section .team-container{
        position: relative;
    }
    .aboutteam-section .mobile .content-box {
        min-height: 180px;
    }


    .aboutteam-section .content-box .strok {
        width: fit-content;
        text-align: center !important;
        font-size: 60px !important;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 1px rgba(132, 207, 200, 0.7);
        font-family: 'Helvetica';
        
        font-weight: 700;
        right: -20px;
        line-height: 180px!important;
        position: absolute;
        text-align: center!important;
        top: -20px;
    }

    .aboutteam-section .mobile .content-box .h3 {
        color: #000;
        font-size: 26px !important;
        font-weight: 300 !important;
        font-family: Helvetica;
        transform: translateY(8px);
        position: relative;
        left: -42px;
        z-index: 2;
    }

    .aboutteam-section  .content-box h2 {
        color: #FFF;
        font-size: 26px !important;
        font-weight: 300;
        font-family: Helvetica;
        position: absolute;
        top: 15px;
        left: 30px;
    }

    .aboutteam-section .content-box .h1 {
        color: #FFF;
        font-size: 50px !important;
        font-family: Helvetica;
        font-weight: 700;
        left: 30px;
        top: 35px;
        z-index: 1;
    }

    .aboutteam-section .content-box .h1.anima span.anima-underline {
        color: #FFF;
        font-size: 50px !important;
        font-family: Helvetica;
        font-weight: 700;
    }

    .aboutteam-section .content-box .h1.anima span.anima-underline:after {
        top: 25px;
    }

    .aboutteam-section.mobile p {
        color: #FFF;
        text-align: justify;
        font-size: 12px;
        font-family: Helvetica;
        line-height: 130%;
        position: relative;
        top: 60px;
    }
    .aboutteam-section.mobile .teampara2{
        padding-left: 40px;
    } 
    .aboutteam-section.mobile .teampara1{
        padding-right: 40px;
    }
    .aboutteam-section.mobile .img1,
    .aboutteam-section.mobile .img2 {
        width: 170.364px;
        height: 170.364px;
        flex-shrink: 0;
    }
    .aboutteam-section.mobile .img1{
        float: right;
    }
   
    .aboutteam-section.mobile h4 {
        color: #FFF;
        text-align: center;
        font-size: 20.559px;
        font-family: Helvetica;
        font-weight: 700;
        text-transform: capitalize;
    }
    .aboutteam-section.mobile h5{
        color: #FFF;
        text-align: justify;
        font-size: 13.363px;
        font-family: Helvetica;
        font-weight: 300;
        line-height: 50%;
        text-transform: capitalize;
    }
    .aboutteam-section.mobile .bgpink-mobile {
        width: 180.406px;
        height: 71.957px;
        flex-shrink: 0;
        background: #F11576;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction:column;
        position: absolute;
        top: 20%;
        left: 56%;
        /* float: right; */
    }

    .aboutteam-section.mobile .bgblue-mobile {
        width: 180.406px;
        height: 71.957px;
        flex-shrink: 0;
        background: #01C6B2;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction:column;
        position: absolute;
        top: 71%;
        left: 10%;
    }

    .aboutteam-section .team-hover-box .team-image-box:hover .imgdef, .hover-text-box:hover ~ .team-hover-box .team-image-box .imgdef{
        /* .aboutteam-section .team-hover-box:hover .imgdef { */
        display: block;
    }
        
    .aboutteam-section .team-hover-box .team-image-box:hover .hoverimg , .hover-text-box:hover ~ .team-hover-box .team-image-box .hoverimg{
        /* .aboutteam-section .team-hover-box:hover .hoverimg { */
        display: none;
    }

    .aboutteam-section .team-hover-box .team-image-box:hover ~ .hover-text-box{
            /* .aboutteam-section .team-hover-box:hover .hover-text-box { */
        display: none;
    }

    .bgpink h4, .bgblue h4 {
        font-size: 28.559px;
        line-height: 35px;
        font-weight: 700;
        text-align: left;
    }

    .bgpink h5, .bgblue h5 {
        font-size: 23.363px;
        line-height: 30px;
        font-weight: 700;
        text-align: left;
    }

    .aboutteam-section .desktop {
        display: none;
    }
    .aboutteam-section .mobile {
        display: block;
    }
 
    .aboutteam-section .content-box.senior-team {
        justify-content: flex-start !important;
        max-width: 320px !important;
        min-height: 200px !important;
    }

    .aboutteam-section .content-box.senior-team h2 {
        font-size: 26px !important; 
        left: 30px;
    }

    
    .aboutteam-section .content-box.senior-team .h1 {
        font-size: 50px !important;
        left: 30px;
        top: 10px;
    }

    .aboutteam-section .content-box.senior-team .h1.anima span.anima-underline {
        font-size: 50px !important;    
        line-height: 60px !important;
    }

    .aboutteam-section .content-box.senior-team .h1.anima span.anima-underline:after {
        top:0px !important;
    }   
    .aboutteam-section .content-box.senior-team .strok {
        font-size: 58px !important;
        left: 50px;
        top: 0px;
    }

    .aboutteam-section .imgdiv .team-image-box
    .aboutteam-section .imgdiv1 .team-image-box {
        margin: auto !important;
        max-width: 100% !important;
    }

    .aboutteam-section .imgdiv img, .aboutteam-section .imgdiv1 img {
        width: 100%;
    }


    .aboutteam-section .para1,
    .aboutteam-section .para2 ,
    .aboutteam-section .s-team p {
        font-size: 16px;
        margin-left: 20px;
        margin-right: 20px;
    }
    
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {

    .aboutteam-section .desktop {
        display: none;
    }    

    .aboutteam-section .mobile {
        display: block;
    }    

    .aboutteam-section .g-5 .col-md-6,
    .aboutteam-section .senior-teams .col-md-4 {
        max-width: 640px;
        margin: auto;
        width: 100% !important;
    }


    .aboutteam-section {
        height: 100%;
        padding-bottom: 120px;
        margin-bottom: 80px;
    }
    .aboutteam-section .mobile {
        position: relative !important;
    }

    .aboutteam-section .team-container{
        position: relative;
    }

    
    .aboutteam-section .content-box {
        display: flex;
        justify-content: center;
        flex-direction: column;
        vertical-align: middle;
        height: auto;
        /* margin-top: 150px; */
        position: relative;
        min-height: 250px;
        max-width: 400px;
        margin: auto;
    }


    .aboutteam-section .content-box h2 {
        top: -100px;
        left: -80px;
        font-size: 40px !important;
        line-height: 59px !important;
        position: absolute;
        top: 15px;
        left: 0px;
        /* transform: translateX(-70px); */
    }


    .aboutteam-section .content-box .strok {
        position: absolute;
        font-size: 100px !important;
        line-height: 120px !important;
        left: 0px;
        top: 15px;
    }


    .aboutteam-section .content-box .h1 {
        position: absolute;
        font-size: 80px !important;
        line-height: 100px !important;
        top: 50px;
        left: 0px;
        z-index: 1;
    }
    
    .aboutteam-section.mobile .content-box {
        min-height: 180px;
    }


    .aboutteam-section.mobile .content-box .strok {
        width: fit-content;
        text-align: center !important;
        font-size: 140px;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 1px rgba(132, 207, 200, 0.7);
        font-family: 'Helvetica';

        font-weight: 700;
        left: 50px;
        line-height: 180px!important;
        position: absolute;
        text-align: center!important;
        top: -10px;
    }

    .aboutteam-section.mobile .content-box .h3 {
        color: #000;
        font-size: 26px;
        font-family: Helvetica;
        transform: translateY(8px);
        position: relative;
        left: -42px;
        z-index: 2;
    }

    .aboutteam-section.mobile .content-box h2 {
        color: #FFF;
        font-size: 26px;
        font-family: Helvetica;
        position: absolute;
        top: 35px;
        left: 30px;
    }

    .aboutteam-section.mobile .content-box .h1 {
        color: #FFF;
        font-size: 50px;
        font-family: Helvetica;
        font-weight: 700;
        left: 30px;
        top: 35px;
        z-index: 1;
    }

    .aboutteam-section.mobile .content-box .anima-underline {
        color: #FFF;
        font-size: 50px !important;
        font-family: Helvetica;
        font-weight: 700;
    }

    .aboutteam-section.mobile .content-box .h1.anima span.anima-underline:after {
        top: 50px;
    }

    .aboutteam-section.mobile p {
        color: #FFF;
        text-align: justify;
        font-size: 12px;
        font-family: Helvetica;
        line-height: 130%;
        position: relative;
        top: 60px;
    }
    .aboutteam-section.mobile .teampara2{
        padding-left: 40px;
    } 
    .aboutteam-section.mobile .teampara1{
        padding-right: 40px;
    }
    .aboutteam-section.mobile .img1,
    .aboutteam-section.mobile .img2 {
        width: 170.364px;
        height: 170.364px;
        flex-shrink: 0;
    }
    .aboutteam-section.mobile .img1{
        float: right;
    }
   
    .aboutteam-section.mobile h4 {
        color: #FFF;
        text-align: center;
        font-size: 20.559px;
        font-family: Helvetica;
        font-weight: 700;
        text-transform: capitalize;
    }
    .aboutteam-section.mobile h5{
        color: #FFF;
        text-align: justify;
        font-size: 13.363px;
        font-family: Helvetica;
        font-weight: 300;
        line-height: 50%;
        text-transform: capitalize;
    }
    .aboutteam-section.mobile .bgpink-mobile {
        width: 180.406px;
        height: 71.957px;
        flex-shrink: 0;
        background: #F11576;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction:column;
        position: absolute;
        top: 20%;
        left: 56%;
        /* float: right; */
    }

    .aboutteam-section.mobile .bgblue-mobile {
        width: 180.406px;
        height: 71.957px;
        flex-shrink: 0;
        background: #01C6B2;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction:column;
        position: absolute;
        top: 71%;
        left: 10%;
    }

    .aboutteam-section .team-hover-box .team-image-box:hover .imgdef, .hover-text-box:hover ~ .team-hover-box .team-image-box .imgdef{
        /* .aboutteam-section .team-hover-box:hover .imgdef { */
        display: block;
    }
        
    .aboutteam-section .team-hover-box .team-image-box:hover .hoverimg , .hover-text-box:hover ~ .team-hover-box .team-image-box .hoverimg{
        /* .aboutteam-section .team-hover-box:hover .hoverimg { */
        display: none;
    }

    .aboutteam-section .team-hover-box .team-image-box:hover ~ .hover-text-box{
            /* .aboutteam-section .team-hover-box:hover .hover-text-box { */
        display: none;
    }

    .bgpink h4, .bgblue h4 {
        font-size: 18.559px;
        line-height: 41px;
        font-weight: 700;
        text-align: center;
    }

    .aboutteam-section .desktop {
        display: none;
    }
    .aboutteam-section .mobile {
        display: block;
    }
 
    .aboutteam-section .content-box.senior-team {
        justify-content: flex-start !important;
        max-width: 320px !important;
        min-height: 200px !important;
    }

    .aboutteam-section .content-box.senior-team h2 {
        font-size: 26px !important; 
        left: 30px;
    }

    .aboutteam-section .content-box.senior-team .h1 {
        font-size: 50px !important;
        left: 30px;
    }

    .aboutteam-section .content-box.senior-team .strok {
        font-size: 58px !important;
        left: 20px;
    }

    .aboutteam-section .imgdiv .team-image-box
    .aboutteam-section .imgdiv1 .team-image-box {
        margin: auto !important;
        max-width: 100% !important;
    }

    .aboutteam-section .imgdiv img, .aboutteam-section .imgdiv1 img {
        width: 100%;
    }

    .aboutteam-section .s-team p {
        font-size: 20px;
    }

    .bgpink h4, .bgblue h4 {
        font-size: 28.559px;
        line-height: 35px;
        font-weight: 700;
        text-align: left;
    }

    .bgpink h5, .bgblue h5 {
        font-size: 23.363px;
        line-height: 30px;
        font-weight: 700;
        text-align: left;
    }
}




@media (min-width: 1024px) and (max-width: 1280px) {

    .aboutteam-section .content-box h2 {
        font-size: 42px;
        top: 0px;
        left: 40px;
    }
 
    .aboutteam-section .content-box .strok {
        font-size: 98px;
        line-height: 112px;
        left: 260px;
        top: -5px;
    }

    .aboutteam-section .content-box .h1.anima span.anima-underline {
        font-size: 84px;
        line-height: 90px;
    }

    .aboutteam-section .content-box .h1 {
        font-size: 84px;
        line-height: 90px;
        left: 40px;
        top: 40px;
    }

    .bgblue h4, .bgpink h4 {
        font-size: 23.7776px;
    }

    .bgblue h5, .bgpink h5 {
        font-size: 15.4553px;
    }

    .aboutteam-section .para1, .aboutteam-section .para2 {
        font-size: 14px;
    }   

}


@media (min-width: 1280px) and (max-width: 1360px) {
    
    .aboutteam-section .content-box h2 {
        font-size: 45px;
        top: 0px;
        left: 40px;
    }

    .aboutteam-section .content-box .strok {
        font-size: 105px;
        line-height: 120px;
        left: 260px;
        top: -5px;
    }

    .aboutteam-section .content-box .h1.anima span.anima-underline {
        font-size: 90px;
        line-height: 105px;
    }

    .aboutteam-section .content-box .h1 {
        font-size: 90px;
        line-height: 105px;
        left: 40px;
        top: 40px;
    }

    .bgblue h4, .bgpink h4 {
        font-size: 25.476px;
    }

    .bgblue h5, .bgpink h5 {
        font-size: 16.55925px;
    }

    .aboutteam-section .para1, .aboutteam-section .para2 {
        font-size: 15px;
    }

}

@media (min-width: 1360px) and (max-width: 1440px) {

    .aboutteam-section .content-box h2 {
        font-size: 48px;
        top: 0px;
        left: 40px;
    }

    .aboutteam-section .content-box .strok {
        font-size: 112px;
        line-height: 128px;
        left: 260px;
        top: -10px;
    }

    .aboutteam-section .content-box .h1.anima span.anima-underline {
        font-size: 96px;
        line-height: 112px;
    }

    .aboutteam-section .content-box .h1 {
        font-size: 96px;
        line-height: 112px;
        left: 40px;
        top: 40px;
    }

    .bgblue h4, .bgpink h4 {
        font-size: 27.1744px;
    }

    .bgblue h5, .bgpink h5 {
        font-size: 17.6632px;
    }

    .aboutteam-section .para1, .aboutteam-section .para2 {
        font-size: 16px;
    }

}

@media (min-width: 1440px) and (max-width: 1680px) {

    .aboutteam-section .content-box h2 {
        font-size: 51px;
        top: 0px;
        left: 40px;
    }

    .aboutteam-section .content-box .strok {
        font-size: 119px;
        line-height: 136px;
        left: 260px;
        top: -5px;
    }

    .aboutteam-section .content-box .h1.anima span.anima-underline {
        font-size: 102px;
        line-height: 119px;
    }

    .aboutteam-section .content-box .h1 {
        font-size: 102px;
        line-height: 119px;
        left: 40px;
        top: 40px;
    }

    .bgblue h4, .bgpink h4 {
        font-size: 28.8728px;
    }

    .bgblue h5, .bgpink h5 {
        font-size: 18.76715px;
    }

    .aboutteam-section .para1, .aboutteam-section .para2 {
        font-size: 17px;
    }

}


@media (min-width: 1680px) and (max-width: 1919px) {

    .aboutteam-section .content-box h2 {
        font-size: 51px;
        top: 0px;
        left: 40px;
    }

    .aboutteam-section .content-box .strok {
        font-size: 126px;
        line-height: 144px;
        left: 260px;
        top: -10px;
    }

    .aboutteam-section .content-box .h1.anima span.anima-underline {
        font-size: 108px;
        line-height: 126px;
    }

    .aboutteam-section .content-box .h1 {
        font-size: 108px;
        line-height: 126px;
        left: 40px;
        top: 40px;
    }

    .bgblue h4, .bgpink h4 {
        font-size: 30.5712px;
    }

    .bgblue h5, .bgpink h5 {
        font-size: 19.8711px;
    }

    .aboutteam-section .para1, .aboutteam-section .para2 {
        font-size: 18px;
    }

}

@media only screen and (min-width: 1920px) {}