.getint-section {
  background-color: White;
  background-position: top center;
  background-size: cover;
  width: 100%;
  /* min-height: 100vh; */
  position: relative;
  overflow: hidden;
}


.getintbg {
  /* width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background: #03A796;
  position: absolute;
  left: -100%;
  top: 0;
  z-index: 1;
  background: url('../images/getintouch-bgo.webp') no-repeat;
  background-size: contain;
  background-position: top left;
  z-index: 0; */

  content: "";
  background: #D0F7F0;
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  opacity: 0.7;
  position: absolute;
  height: 344px;
  width: 344px;
  left: -172px;
  top: 5%;
}

.getint-section .container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.getint-section .container .row {
  z-index: 2;
}

.getint-section .content-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  vertical-align: middle;
  /* padding-right: 10%; */
  height: 100%;
}

.getint-section .content-box h2 {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 69px;
  font-size: 40px !important;
  line-height: 59px !important;
  margin-bottom: 0px !important;
  color: #05304D;
}

.getint-section .content-box .h1 {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 120px;
  line-height: 100px;
  font-size: 80px !important;
  line-height: 60px !important;
  color: #05304D;
  word-spacing: -15px;
}

.getint-section .content-box .h1 span.animate-underline:after {
  bottom: 0px !important;
}

.getint-section .get-para {
  color: #05304D;
font-size: 20px;
font-family: Helvetica;
font-family: 'Work Sans';
font-style: normal;
font-weight: 400;
line-height: normal;
}
/* form */

.getint-section label {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #03AD9B;
}

.getint-section input[type=text],
.getint-section input[type=email],
.getint-section input[type=tel],
.getint-section textarea,
.getint-section select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 2px solid #03AD9B;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: transparent;
  border: 2px solid #03AD9B;
  border-radius: 10px;
}

.getint-section input[type=text],
.getint-section input[type=email],
.getint-section select {
  height: 60px;
}

.getint-section textarea {
  height: 150px;
  background-color: transparent;
}

.getint-section input[type=submit] {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
}

.getint-section input[type=submit]:hover {
  background-color: #45a049;
}

.getint-section button {
  padding: 20px 35px;
  padding: 20px !important;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  background: linear-gradient(180deg, #01C6B2 0%, #048D7F 128.35%);
  border: 1px solid #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  text-align: right;
}

.text-right {
  text-align: right;
}
.getint-section .errormsg{
  color: red;
  font-weight: 600;
}

/*or 480 Resolution */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .getint-section.mobile h2 {
    color: #05304D;
    font-size: 26px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: -10px !important;
  }
  .getint-section .errormsg{
    color: red;
    font-weight: 500;
  }
  .getint-section.mobile .animate-underline::after{
    /* margin-top: 40px !important; */
    position: absolute;
    top: 10%;
  }

  .getint-section.mobile .h1 {
    color: #05304D;
    font-size: 50px !important;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    line-height: normal !important;
    word-spacing: -10px;
  }

  .getint-section.mobile .get-para {
    color: #05304D;
    font-size: 16px !important;
    font-family: Helvetica;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: justify;
    margin-top: 20px;
    
  }

  .getint-section.mobile .container {
    height: auto;

  }
  .getint-section.mobile input[type=text],
.getint-section.mobile input[type=email],
.getint-section.mobile select {
  height: 43px;
   width: 100% !important; 
   padding: 12px -20px;
}
.getint-section.mobile textarea {
  height: 115px;
}
.getint-section.mobile  label{
  color: #03AD9B;
font-size: 12px;
font-family: Work Sans;
font-style: normal;
font-weight: 400;
line-height: 130%;
}
.getint-section input[type=submit] {
  color: var(--neutral-white, #FFF);
text-align: center;
font-size: 14px;
font-family: Helvetica;
font-style: normal;
font-weight: 700;
line-height: normal;
display: flex;
width: 180px;
height: 48px;
padding: 15px 20px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
}

.getint-section button{
  padding: 15px 10px !important;
  font-size: 14px;
  line-height: 14px;
  height: 48px;
  font-family: Helvetica;
}

.getint-section.mobile .form-text{
  /* padding-right: 0% !important; */
  padding-left: 10px !important;
}

}

/* For 640 Resolution */
@media only screen and (min-device-width : 481px) and (max-device-width : 767px) {
  .getint-section.mobile h2 {
    color: #05304D;
    font-size: 26px !important;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: -20px !important;
  }
  .getint-section .errormsg{
    color: red;
    font-weight: 500;
  }
  .getint-section.mobile .animate-underline::after{
    /* margin-top: 40px !important; */
    position: absolute;
    top: 10%;
  }

  .getint-section.mobile .h1 {
    color: #05304D;
    font-size: 50px !important;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    line-height: normal !important;
    word-spacing: -10px;
  }

  .getint-section.mobile .get-para {
    color: #05304D;
    font-size: 16px !important;
    font-family: Helvetica;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: justify;
    margin-top: 20px;
    
  }

  .getint-section.mobile .container {
    height: auto;

  }
  .getint-section.mobile input[type=text],
.getint-section.mobile input[type=email],
.getint-section input[type=tel],
.getint-section.mobile select {
  height: 43px;
   width: 100% !important; 
   padding: 12px -20px;
}
.getint-section.mobile textarea {
  height: 115px;
}
.getint-section.mobile  label{
  color: #03AD9B;
font-size: 12px;
font-family: Work Sans;
font-style: normal;
font-weight: 400;
line-height: 130%;
}

.getint-section input[type=submit] {
  color:  #FFF;
  text-align: center;
  font-size: 14px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  width: 180px;
  height: 48px;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.getint-section button{
  padding: 15px 10px !important;
  font-size: 14px;
  line-height: 14px;
  height: 48px;
  font-family: Helvetica;
}

.getint-section.mobile .form-text{
  /* padding-right: 0% !important; */
  padding-left: 10px !important;
}

}



@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
  
  .getint-section .content-box {
    text-align: center;
  }

  .getint-section.mobile h2 {
    color: #05304D;
    font-size: 26px !important;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: -20px !important;
  }
  .getint-section .errormsg{
    color: red;
    font-weight: 500;
  }
  .getint-section.mobile .animate-underline::after{
    /* margin-top: 40px !important; */
    position: absolute;
    top: 10%;
  }

  .getint-section.mobile .h1 {
    color: #05304D;
    font-size: 50px !important;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    line-height: normal !important;
    word-spacing: -5px;
  }

  .getint-section.mobile .get-para {
    color: #05304D;
    font-size: 16px !important;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-top: 20px;
    
  }

  .getint-section.mobile .container {
    height: auto;
    width: 480px;
    margin: auto;
  }
  
  .getint-section.mobile input[type=text],
.getint-section.mobile input[type=email],
.getint-section input[type=tel],
.getint-section.mobile select {
  height: 43px;
   width: 100% !important; 
   padding: 12px -20px;
}
.getint-section.mobile textarea {
  height: 115px;
}
.getint-section.mobile  label{
  color: #03AD9B;
font-size: 12px;
font-family: Work Sans;
font-style: normal;
font-weight: 400;
line-height: 130%;
}

.getint-section input[type=submit] {
  color:  #FFF;
  text-align: center;
  font-size: 14px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  width: 180px;
  height: 48px;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.getint-section button{
  padding: 15px 10px !important;
  font-size: 14px;
  line-height: 14px;
  height: 48px;
  font-family: Helvetica;
}

.getint-section.mobile .form-text{
  /* padding-right: 0% !important; */
  padding-left: 10px !important;
}

}


@media (min-width: 1024px) and (max-width: 1080px) {
  .getint-section .content-box h2 {       
      font-size: 42px;
      line-height: 48.3px;
  }
  .getint-section .content-box .h1 {
      font-size: 84px;
      line-height: 70px;
  }
  .getint-section .get-para {
  font-size: 14px;
  }
  
  .getint-section label {
    font-size: 14px;
    line-height: 91%;
  }
  
  .getint-section button {
    font-size: 10.8px;
    line-height: 12.6px;
  }

}
@media (min-width: 1081px) and (max-width: 1280px) {
  .getint-section .content-box h2 {       
      font-size: 45px;
      line-height: 51.75px;
  }
  .getint-section .content-box .h1 {
      font-size: 90px;
      line-height: 75px; 
  }
  .getint-section .get-para {
    font-size: 15px;
    }
    
    .getint-section label {
      font-size: 15px;
      line-height: 97.5%;
    }
    .getint-section button {
      font-size: 12.6px;
      line-height: 13.2px;
    }
    
}
@media (min-width: 1281px) and (max-width: 1360px) {
  /* .getint-section {
    padding: 5% 0px;
  } */
  
  .getint-section .content-box h2 {       
      font-size: 48px;
      line-height: 55.2px;
  }
  .getint-section .content-box .h1 {
      font-size: 96px;
      line-height: 80px;
  }
  .getint-section .get-para {
    font-size: 16px;
    }
    
    .getint-section label {
      font-size: 16px;
      line-height: 104%;
    }

    .getint-section button {
      font-size: 13.6px;
    }
  
}
@media (min-width: 1361px) and (max-width: 1440px) {
  /* .getint-section { 
    padding: 5% 0px;
  } */

  .getint-section .content-box h2 {       
      font-size: 51px;
      line-height: 58.65px;
  }
  .getint-section .content-box .h1 {
      font-size: 102px;
      line-height: 85px;
  }
  .getint-section .get-para {
    font-size: 17px;
    }
    
    .getint-section label {
      font-size: 17px;
      line-height: 110.5%;
    }

    .getint-section button {
      font-size: 15.3px;
    }

}
@media (min-width: 1441px) and (max-width: 1680px) {
  
  .getint-section .content-box h2 {       
      font-size: 54px;
      line-height: 62.1px;
  }
  .getint-section .content-box .h1 {
      font-size: 108px;
      line-height: 90px;
  }
  .getint-section .get-para {
    font-size: 18px;
    }
    
    .getint-section label {
      font-size: 18px;
      line-height: 117%;
    }
    .getint-section button {
      font-size: 15.3px;
    }
}
@media (min-width: 1681px) and (max-width: 1919px) {
  
  .getint-section .content-box h2 {       
      font-size: 57px;
      line-height: 65.55px;
  }
  .getint-section .content-box .h1 {
      font-size: 114px;
      line-height: 95px;
  }
  .getint-section .get-para {
    font-size: 19px;
    }
    
    .getint-section label {
      font-size: 19px;
      line-height: 123.5%;
    }

    .getint-section button {
      font-size: 16.2px;
    }
}
@media only screen and (min-width: 1920px) {}


@media (max-height: 600px)  and (min-width: 1024px)  {
  .getint-section { 
    padding: 7% 0px;
  }
}

@media (max-height: 800px) and (min-width: 1024px)  {
  .getint-section { 
    padding: 5% 0px;
  }
}