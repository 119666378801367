.jobopeningbody-section .form-section {
    padding-bottom: 150px;
}

.jobopeningbody-section .form-section h2 {
    color: #000;
    font-family: Helvetica;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.jobopeningbody-section .form-section label {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #03AD9B;
}

.jobopeningbody-section .form-section input[type=text],
.jobopeningbody-section .form-section input[type=email],
.jobopeningbody-section .form-section input[type=tel],
.jobopeningbody-section .form-section textarea,
.jobopeningbody-section .form-section select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 2px solid #03AD9B;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: transparent;
    border: 2px solid #03AD9B;
    border-radius: 10px;
}

.jobopeningbody-section .form-section input[type=file] {
    /* width: 100%; */
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    box-sizing: border-box;
    background-color: transparent;
    border: 2px dashed #03AD9B;
    border-radius: 10px;
    height: 90px;

}

.jobopeningbody-section .form-section input[type=text],
.jobopeningbody-section .form-section input[type=email],
.jobopeningbody-section .form-section input[type=tel],
.jobopeningbody-section .form-section select {
    height: 60px;
}

.jobopeningbody-section .form-section textarea {
    height: 226px;
    background-color: transparent;
}

.jobopeningbody-section .form-section button {
    padding: 30px 92px;
    color: #ffffff;
    background: linear-gradient(180deg, #01C6B2 0%, #048D7F 128.35%);
    border: 1px solid #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    text-align: right;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
}

.jobopeningbody-section .form-section .errormsg {
    color: red;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
}

.jobopeningbody-section .jobinfo-section h2 {
    color: #000;
    font-family: Helvetica;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.jobopeningbody-section .jobinfo-section h5 {
    color: #01C6B2;
    text-align: justify;
    font-family: Helvetica;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 28.6px */
    text-transform: capitalize;
}

.jobopeningbody-section .jobinfo-section ul li {
    color: #000;
    text-align: left;
    font-family: Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    /* 32.4px */
    /* text-transform: capitalize; */
    /* padding-right: 10px; */
}

.jobopeningbody-section .jobinfo-section ul li {
    list-style-type: disc;
}

.jobopeningbody-section .job-upper-div {
    margin-top: 50px;
    display: flex;
    /* justify-content: center; */
    /* align-items:center; */
}

.jobopeningbody-section .job-upper-div button {
    color: #FFF;

    /* Overline */
    font-family: Helvetica;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 15px */
    letter-spacing: 0.15px;
    border-radius: 30.5px;
    background: #0A142F;
    display: flex;
    padding: 0px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.jobopeningbody-section .job-upper-div svg {
    fill: #151515;
    transform: translateY(2px);

}

.jobopeningbody-section .job-upper-div .job-upper-para {
    color: #0A142F !important;

    /* Overline */
    font-family: Helvetica;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 15px */
    letter-spacing: 0.15px;
    transform: translateY(8px);
}

.jobopeningbody-section .jobinfo-section,.jobopeningbody-section .job-upper-div {
    transform: translateX(-40px);
}
.jobopeningbody-section .form-section {
    transform: translateX(40px);
}
/* For 480 Resolution */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .jobopeningbody-section .jobinfo-section,.jobopeningbody-section .job-upper-div {
        transform: translateX(0px);
    }
    .jobopeningbody-section .form-section {
        transform: translateX(0px);
    }


    .jobopeningbody-section .jobinfo-section h2,
    .jobopeningbody-section .form-section h2 {
        font-size: 30px;
    }
    
    .jobopeningbody-section .jobinfo-section h5 {
        font-size: 18px;
    }

    .jobopeningbody-section .jobinfo-section ul li {
        font-size: 16px;    
        line-height: 150%;
        margin: 10px 0px;
    }
    
    .jobopeningbody-section .form-section input[type=text], 
    .jobopeningbody-section .form-section input[type=email], 
    .jobopeningbody-section .form-section input[type=tel],
    .jobopeningbody-section .form-section select {
        height: 50px;
    }

    .jobopeningbody-section .form-section button {
        font-size: 14px;
        padding: 15px 25px;
    }
}
/* For 640 Resolution */
@media only screen and (min-device-width : 481px) and (max-device-width : 767px) {
    .jobopeningbody-section .jobinfo-section,.jobopeningbody-section .job-upper-div {
        transform: translateX(0px);
    }
    .jobopeningbody-section .form-section {
        transform: translateX(0px);
    }


    
    .jobopeningbody-section .jobinfo-section h2,
    .jobopeningbody-section .form-section h2 {
        font-size: 30px;
    }
    
    .jobopeningbody-section .jobinfo-section h5 {
        font-size: 20px;
    }

    .jobopeningbody-section .jobinfo-section ul li {
        font-size: 18px;    
        line-height: 150%;
        margin: 10px 0px;
    }
    
    .jobopeningbody-section .form-section input[type=text], 
    .jobopeningbody-section .form-section input[type=email], 
    .jobopeningbody-section .form-section input[type=tel],
    .jobopeningbody-section .form-section select {
        height: 50px;
    }

    .jobopeningbody-section .form-section button {
        font-size: 14px;
        padding: 15px 25px;
    }

}



@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
    .jobopeningbody-section .jobinfo-section,.jobopeningbody-section .job-upper-div {
        transform: translateX(0px);
    }
    .jobopeningbody-section .form-section {
        transform: translateX(0px);
    }

    .jobopeningbody-section .col-md-6 {
        width: 100%;
    }
    
    .jobopeningbody-section .jobinfo-section h2,
    .jobopeningbody-section .form-section h2 {
        font-size: 40px;
    }
    
    .jobopeningbody-section .jobinfo-section h5 {
        font-size: 22px;
    }

    .jobopeningbody-section .jobinfo-section ul li {
        font-size: 18px;    
        line-height: 150%;
        margin: 10px 0px;
    }
    
    .jobopeningbody-section .form-section input[type=text], 
    .jobopeningbody-section .form-section input[type=email], 
    .jobopeningbody-section .form-section input[type=tel],
    .jobopeningbody-section .form-section select {
        height: 50px;
    }

    .jobopeningbody-section .form-section button {
        font-size: 16px;
        padding: 15px 25px;
    }

}



/* For 1024 Resolution */ 
@media  (min-width: 1024px)  and (max-width: 1280px)  {
    /* .jobopeningbody-section .form-section {
        padding-bottom: 150px;
    }
     */
    .jobopeningbody-section .form-section h2 {
        font-size: 28px;
        line-height: normal;
    }
    
    .jobopeningbody-section .form-section label {
      
        font-size: 14px;
        line-height: 91%;
 
    }
    
.jobopeningbody-section .form-section input[type=file] {
    height: 44.1px;
}

.jobopeningbody-section .form-section input[type=text],
.jobopeningbody-section .form-section input[type=email],
.jobopeningbody-section .form-section input[type=tel],
.jobopeningbody-section .form-section select {
    height: 42px;
}

.jobopeningbody-section .form-section textarea {
    height: 158.2px;
 
}

.jobopeningbody-section .form-section button {
   
    font-size: 12.6px;
    line-height: 14.7px;
   
}

.jobopeningbody-section .form-section .errormsg {
    font-size: 14px;
}

.jobopeningbody-section .jobinfo-section h2 {
   
    font-size: 28px;
    /* line-height: normal; */
}

.jobopeningbody-section .jobinfo-section h5 {
   
    font-size: 15.4px;
   
    line-height: 91%;
  
}

.jobopeningbody-section .jobinfo-section ul li {
 
    font-size: 12.6px !important;
   
    line-height: 126%;
  
}


.jobopeningbody-section .job-upper-div button {
   
    font-size: 7px;
   
    line-height: 105%;
  
}
.jobopeningbody-section .job-upper-div .job-upper-para {
    font-size: 7px;
    line-height: 105%;
    /* transform: translateY(8px); */
}
.jobopeningbody-section .jobinfo-section,.jobopeningbody-section .job-upper-div {
    transform: translateX(0px);
}
.jobopeningbody-section .form-section {
    transform: translateX(0px);
}

}
@media  (min-width: 1280px)  and (max-width: 1360px) {
    .jobopeningbody-section .form-section h2 {
        font-size: 30px;
        line-height: normal;
    }
    
    .jobopeningbody-section .form-section label {
        font-size: 15px;
        line-height: 97.5%;
    }
    
.jobopeningbody-section .form-section input[type=file] {
    height: 47.25px;
}

.jobopeningbody-section .form-section input[type=text],
.jobopeningbody-section .form-section input[type=email],
.jobopeningbody-section .form-section input[type=tel],
.jobopeningbody-section .form-section select {
    height: 45px;
}

.jobopeningbody-section .form-section textarea {
    height: 169.5px;
}

.jobopeningbody-section .form-section button {
    font-size: 13.5px;
    line-height: 15.75px;
}

.jobopeningbody-section .form-section .errormsg {
    font-size: 15px;
}
.jobopeningbody-section .jobinfo-section h2 {
    font-size: 30px;
}

.jobopeningbody-section .jobinfo-section h5 {
    font-size: 16.5px;
    line-height: 97.5%;
}
.jobopeningbody-section .jobinfo-section ul li {
    font-size: 13.5px !important;
    line-height: 135%;
}

.jobopeningbody-section .job-upper-div button {
    font-size: 7.5px;
    line-height: 112.5%;
}
.jobopeningbody-section .job-upper-div .job-upper-para {
    font-size: 7.5px;
    line-height: 112.5%;
}
.jobopeningbody-section .jobinfo-section,.jobopeningbody-section .job-upper-div {
    transform: translateX(-20px);
}
.jobopeningbody-section .form-section {
    transform: translateX(20px);
}
}
@media  (min-width: 1360px)  and (max-width: 1440px) {
    .jobopeningbody-section .form-section h2 {
        font-size: 32px;
        line-height: normal;
    }
    
    .jobopeningbody-section .form-section label {
        font-size: 16px;
        line-height: 104%;
    }
    
.jobopeningbody-section .form-section input[type=file] {
    height: 50.4px;
}

.jobopeningbody-section .form-section input[type=text],
.jobopeningbody-section .form-section input[type=email],
.jobopeningbody-section .form-section input[type=tel],
.jobopeningbody-section .form-section select {
    height: 48px;
}

.jobopeningbody-section .form-section textarea {
    height: 180.8px;
}

.jobopeningbody-section .form-section button {
    font-size: 14.4px;
    line-height: 16.8px;
}

.jobopeningbody-section .form-section .errormsg {
    font-size: 16px;
}
.jobopeningbody-section .jobinfo-section h2 {
    font-size: 32px;
}

.jobopeningbody-section .jobinfo-section h5 {
    font-size: 17.6px;
    line-height: 104%;
}
.jobopeningbody-section .jobinfo-section ul li {
    font-size: 14.4px !important;
    line-height: 144%;
}

.jobopeningbody-section .job-upper-div button {
    font-size: 8px;
    line-height: 120%;
}
.jobopeningbody-section .job-upper-div .job-upper-para {
    font-size: 8px;
    line-height: 120%;
}
.jobopeningbody-section .jobinfo-section,.jobopeningbody-section .job-upper-div {
    transform: translateX(-20px);
}
.jobopeningbody-section .form-section {
    transform: translateX(20px);
}
}
@media  (min-width: 1440px)  and (max-width: 1680px) {
    .jobopeningbody-section .form-section h2 {
        font-size: 34px;
        line-height: normal;
    }
    
    .jobopeningbody-section .form-section label {
        font-size: 17px;
        line-height: 110.5%;
    }
    
.jobopeningbody-section .form-section input[type=file] {
    height: 53.55px;
}

.jobopeningbody-section .form-section input[type=text],
.jobopeningbody-section .form-section input[type=email],
.jobopeningbody-section .form-section input[type=tel],
.jobopeningbody-section .form-section select {
    height: 51px;
}

.jobopeningbody-section .form-section textarea {
    height: 192.1px;
}

.jobopeningbody-section .form-section button {
    font-size: 15.3px;
    line-height: 17.85px;
}

.jobopeningbody-section .form-section .errormsg {
    font-size: 17px;
}
.jobopeningbody-section .jobinfo-section h2 {
    font-size: 34px;
}

.jobopeningbody-section .jobinfo-section h5 {
    font-size: 18.7px;
    line-height: 110.5%;
}
.jobopeningbody-section .jobinfo-section ul li {
    font-size: 15.3px !important;
    line-height: 153%;
}

.jobopeningbody-section .job-upper-div button {
    font-size: 8.5px;
    line-height: 120%;
}
.jobopeningbody-section .job-upper-div .job-upper-para {
    font-size: 8.5px;
    line-height: 127.5%;
}
.jobopeningbody-section .jobinfo-section,.jobopeningbody-section .job-upper-div {
    transform: translateX(-20px);
}
.jobopeningbody-section .form-section {
    transform: translateX(20px);
}
}
@media  (min-width: 1680px)  and (max-width: 1919px){
    .jobopeningbody-section .form-section h2 {
        font-size: 36px;
        line-height: normal;
    }
    
    .jobopeningbody-section .form-section label {
        font-size: 18px;
        line-height: 117%;
    }
    
.jobopeningbody-section .form-section input[type=file] {
    height: 56.7px;
}

.jobopeningbody-section .form-section input[type=text],
.jobopeningbody-section .form-section input[type=email],
.jobopeningbody-section .form-section input[type=tel],
.jobopeningbody-section .form-section select {
    height: 54px;
}

.jobopeningbody-section .form-section textarea {
    height: 203.4px;
}

.jobopeningbody-section .form-section button {
    font-size: 16.2px;
    line-height: 18.9px;
}

.jobopeningbody-section .form-section .errormsg {
    font-size: 18px;
}
.jobopeningbody-section .jobinfo-section h2 {
    font-size: 36px;
}

.jobopeningbody-section .jobinfo-section h5 {
    font-size: 19.8px;
    line-height: 117%;
}
.jobopeningbody-section .jobinfo-section ul li {
    font-size: 16.2px !important;
    line-height: 162%;
}

.jobopeningbody-section .job-upper-div button {
    font-size: 9px;
    line-height: 135%;
}
.jobopeningbody-section .job-upper-div .job-upper-para {
    font-size: 9px;
    line-height: 135%;
}
.jobopeningbody-section .jobinfo-section,.jobopeningbody-section .job-upper-div {
    transform: translateX(-40px);
}
.jobopeningbody-section .form-section {
    transform: translateX(40px);
}
}
@media only screen  and (min-width: 1920px) {}