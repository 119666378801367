header.header-dataanalyst {
    width: 100%;
    min-height: 100vh;
    position: relative;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 5.67%, rgba(0, 0, 0, 0.488542) 10.88%, rgba(0, 0, 0, 0) 30.38%);
    background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%); 
    background: url('../images/dataanalystheader.webp') no-repeat top;
    background-position: top left;
    background-size: cover;
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


header.header-dataanalyst .animation-gradient {
    width: 100%;
    position: absolute;
    object-fit: cover;
    height: 100vh;
    z-index: 1;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 200% 200%;
    opacity: 0.4;
	animation: gradient 7s ease-in infinite;
	height: 100vh;
}

header.header-dataanalyst .animation-svg {
    width: 100%;
    position: absolute;
    object-fit: cover;
    height: 100vh;
    z-index: 1;
    opacity: 0.7;
}

header.header-dataanalyst .animation-svg svg {
    max-height: 100vh;
    height: 100%;
    width: 100%;
}

header.header-dataanalyst .hero {
    min-height: 100vh;
    position: relative;
    z-index: 2;

}

header.header-dataanalyst .hero-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
}

header.header-dataanalyst .hero-text {
    padding-left: 5px;
    /* border-left:5px solid #F11576; */
    position: relative;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    margin-left: 15% !important;
    margin-right: auto !important;
}

header.header-dataanalyst .hero-text:before {
    content: "";
    width: 6px;
    background: #F11576;
    height: 98%;
    position: absolute;
    top: 0%;
    left: -10px;
}

header.header-dataanalyst .hero-text h6{ 
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    /* font-size: 40px !important; */
    line-height: 70%;
    letter-spacing: 1px;
    color: #FFFFFF;
}

header.header-dataanalyst .hero-text-2 h5 { 
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    /* font-size: 40px !important; */
    line-height: 120% !important;
    letter-spacing: 1px;
    color: #FFFFFF;
    margin-top: 20px;
    margin-left: 15%;
    margin-right: 10%;
}

header.header-dataanalyst .hero-text h3 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    /* font-size: 80px !important; */
    line-height: 97.99%;
    letter-spacing: 1px;
    color: #F9F5AA;
}

header.header-dataanalyst .hero-text h2 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    /* font-size: 80px !important; */
    line-height: 97.99%;
    letter-spacing: 1px;    
    color: #ffffff;
}
header.header-dataanalyst .hero-text .spanh2{
    color: #F9F5AA;
}
header.header-dataanalyst .navbar-nav a {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.393478px;
    color: #FFFFFF;
}


/* For 480 Resolution */  
@media only screen   
and (min-device-width : 320px)   
and (max-device-width : 767px)  
{ /* STYLES GO HERE */

    header.header-dataanalyst {
        /* background: url('../images/header-solution-bg-mb.webp') no-repeat top; */
        background-position: 30%;
    }
    
    header.header-dataanalyst .hero-content {
        top:50%;
    }

    header.header-dataanalyst .animation-svg svg {
        height: 100%;
    }

    header.header-dataanalyst .hero-text {
        max-width: 280px !important;
        margin: auto;
    }
 
    header.header-dataanalyst .hero-text h6 {
        font-size: 16px !important;
        line-height: 20px !important;
    }

    header.header-dataanalyst .hero-text h2 {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    header.header-dataanalyst .hero-text:before {
        width: 4px;
        left: -4px;
    }

    header.header-dataanalyst .hero-text {
        margin-left: 10% !important;
        margin-right: auto !important;
    }

    header.header-dataanalyst .hero-text-2 h5 {
        font-size: 18px !important;
        margin-left: 10% !important;
    }
} 


@media only screen  and (min-device-width : 768px) and (max-device-width : 1024px) { 

    header.header-dataanalyst .hero-text {
        margin-left: 5%;
    }

    header.header-dataanalyst .hero-text:before {
        top: 3;
        /* left: 3%; */
    }

    header.header-dataanalyst .hero-text h6 {
        font-size: 36px;
        line-height: 42px;
    }

    header.header-dataanalyst .hero-text h2 {
        font-size: 60px;
        line-height: 60px;
    }

    header.header-dataanalyst .hero-text-2 h5 {
        font-size: 28px !important;
    }

} 


/* For 1024 Resolution */ 
@media  (min-width: 1024px)  and (max-width: 1280px)  
{ 
    /* 84 */
    /* header.header-dataanalyst .hero-text {
        max-width: 800px;
        margin: auto;
    } */

    header.header-dataanalyst .hero-text h2 {
        font-size: 84px;
        line-height: 97.99%;
    }

    header.header-dataanalyst .hero-text h3 {
        font-size: 42px;
    } 

    header.header-financial .hero-text h6 {
        font-size: 36px;
        line-height: 42px;
    }

    header.header-financial .hero-text h2 {
        font-size: 60px;
        line-height: 60px;
    }

    header.header-financial .hero-text-2 h5 {
        font-size: 28px !important;
    }
        
}  



@media  (min-width: 1280px)  and (max-width: 1360px)  
{ 
    /* 90 */
    /* header.header-dataanalyst .hero-text {
        max-width: 800px;
        margin: auto;
    } */

    header.header-dataanalyst .hero-text h2 {
        font-size: 90px;
        line-height: 97.99%;
    }

    header.header-dataanalyst .hero-text h3 {
        font-size: 45px;
    } 

    header.header-financial .hero-text-2 h5 {
        font-size: 28px !important;
    }

}  

@media  (min-width: 1360px)  and (max-width: 1440px)  
{  
    /* 96 */ 
    /* header.header-dataanalyst .hero-text {
        max-width: 850px;
        margin: auto;
    } */
    
    header.header-dataanalyst .hero-text h2 {
        font-size: 96px;
        line-height: 97.99%;
    }

    header.header-dataanalyst .hero-text h3 {
        font-size: 48px;
    } 

    header.header-financial .hero-text-2 h5 {
        font-size: 28px !important;
    }
}  

@media  (min-width: 1440px)  and (max-width: 1680px)  
{  
    /* 102 */
    /* header.header-dataanalyst .hero-text {
        max-width: 900px;
        margin: auto;
    } */

    header.header-dataanalyst .hero-text h2 {
        font-size: 102px;
        line-height: 97.99%;
    }


    header.header-dataanalyst .hero-text h3 {
        font-size: 51px;
    } 

    header.header-financial .hero-text-2 h5 {
        font-size: 28px !important;
    }

}  

@media  (min-width: 1680px)  and (max-width: 1919px)  
{ 
    /* 108 */
    /* header.header-dataanalyst .hero-text {
        max-width: 900px;
        margin: auto;
    } */

    header.header-dataanalyst .hero-text h2 {
        font-size: 108px;
        line-height: 97.99%;
    }

    header.header-dataanalyst .hero-text h3 {
        font-size: 54px;
    } 

    header.header-financial .hero-text-2 h5 {
        font-size: 28px !important;
    }

}  
