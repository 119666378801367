.casestudybody3-section {
    width: 100%;
}


.casestudybody3-section .container {
    align-items: center;
    color: #ffffff;
}

/* Style the list */
ul.breadcrumb {
    list-style: none;
    align-items: center;
  }
  
  /* Display list items side by side */
  ul.breadcrumb li {
    display: inline;
 }

 ul.breadcrumb li.last a:hover {
    color:#ffffff;
 } 

 ul.breadcrumb li { 
    color: #0A142F;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; 
    letter-spacing: 0.15px; 
 }

 ul.breadcrumb li.last {
    display: flex;
    padding: 2px 9px;
    justify-content: center;
    align-items: center;
    border-radius: 9.5px;
    background: #0A142F;
}

ul.breadcrumb li.last a{
    color: #ffffff;
}
  /* Add a slash symbol (/) before/behind each list item */
  /* ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: "/\00a0";
  }
   */

.relative {
    position: relative;
}

/* .casestudybody3-section .clintrow {
    align-items: center;
} */

.casestudybody3-section .container .left-box {
    position: absolute;
    background: #ffffff;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    width: 150%;
    height: 80%;
    padding: 0px 20px 0px 20px;
    display: flex;
}

.casestudybody3-section.outcome .container .left-box {
    height: 85%;
}

.casestudybody3-section .clintrow .right-box img {
    width:100%;
}

.casestudybody3-section .content-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    vertical-align: middle;
    position: relative;

}

.casestudybody3-section .content-box h3 {
    color: #FFF;
    font-family: Work Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.casestudybody3-section .content-box h2 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: normal;
    color:#05304D;
    z-index: 1;
}

.casestudybody3-section .content-box .h1 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 100px;
    font-size: 80px;
    line-height: 60px;
    color:#05304D;
}

.casestudybody3-section .content-box P {
    font-family: Helvetica;
    color: #000;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    font-size: 20px !important;
    line-height: 1.3em;
}


.casestudybody3-section p.anima {
    margin-top: 20px;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 300;
    font-size: 1px !important;
    line-height: 15px !important;
    color: #FFFFFF;

}

.casestudybody3-section  p.anima span.only-anima-underline {
    color: transparent;
    display: inline-block;
    overflow: hidden;
    position: relative;
    width: 300px;
    text-decoration: none;
    vertical-align: bottom;
}

.casestudybody3-section p.anima span.only-anima-underline:after {
    background: linear-gradient(to bottom, rgba(20, 20, 20, 0.8), rgba(20, 20, 20, 0.8)) center 1.08em/100% 5px no-repeat;
    content: "";
    height: 100%;
    margin-top: 0px;
    right: 0;
    position: absolute;
    top: 0;
    width: 400%;
    will-change: transform;
    z-index: 1;
    -webkit-animation: underline-gradient 6s linear infinite;
    animation: underline-gradient 6s linear infinite;
    background-image: linear-gradient(270deg, rgba(241, 21, 118, 1) 0%, rgba(1, 198, 178, 1) 20%, rgba(241, 21, 118, 1) 40%, rgba(1, 198, 178, 1) 60%, rgba(241, 21, 118, 1) 80%, rgba(1, 198, 178, 1) 100%);

}


.casestudybody3-section .icon-list {
    display: flex;
    text-align: center;
    justify-content: space-around;
    padding: 30px 0px;
}


.casestudybody3-section .icon-list p {
    margin-top:20px;
}

.casestudybody3-section .list-text {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap:20px;
    margin-top:5px;
    position: relative;
}

.casestudybody3-section .list-text:after {
    content: "";
    height: 100%;
    left: 25px;
    width: 3px;
    top: 60px;
    background: #03A393;
    position: absolute;
    z-index: 1;
    height: calc( 100% - 60px );
    transform: translateX(-50%);
}




.casestudybody3-section .list-text .icon {
    position: relative;
    max-width: 50px;
    width: 100%;
}

/* .casestudybody3-section .list-text .icon:after {
    content: "";
    height: 100%;
    left: 50%;
    width: 3px;
    background: #03A393;
    position: absolute;
    z-index: 1;
    transform: translateX(-50%);
} */

.casestudybody3-section .list-text .icon img {
    width: 100%;
}

.mt-100 {
    margin-top: 100px;
}

.order-1 {
    order: 0 !important;
    margin-top: 0px;
    margin-bottom: 0px;
}


.casestudybody3-section.bg-gredient-dark {
    background: linear-gradient(172deg, #041E30 0%, #000 100%);
    padding: 100px 0px;
    margin: 50px auto;
    color:#ffffff !important;
}

.casestudybody3-section.bg-gredient-dark p {
    margin-top: 15px;
}


.casestudybody3-section.bg-gredient-dark h1, 
.casestudybody3-section.bg-gredient-dark .h1, 
.casestudybody3-section.bg-gredient-dark h2, 
.casestudybody3-section.bg-gredient-dark p  {
    color: #ffffff;
}

.casestudybody3-section .sample-output {
    display: grid;
    grid-template-columns: auto auto;  
    gap: 20px;  
}

/* .casestudybody3-section .sample-output img:nth-child(5) {
    grid-column: 1 / 2 span;
    width: 50%;
    margin: auto;
    text-align: center;
} */


/*or 480 Resolution */
@media only screen and (min-device-width : 320px) and (max-device-width : 640px) {



    .casestudybody3-section .container .left-box {
        position: unset;
        background: #ffffff;
        top: 0;
        left: 0px;
        transform: translateY(0px);
        width: 100%;
        padding: 0px;
    }
    

    .casestudybody3-section.bg-gredient-dark {
        padding: 50px 0px;
    }   
    .casestudybody3-section .container  .clintrow.row {
        flex-direction: column;
    }

    .order-1 {
        order: 1;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .casestudybody3-section .container {
        padding: 0px 20px;
    }

    .casestudybody3-section .container .clintrow .col {
        width: 100%;
    }

    .casestudybody3-section .content-box .h1 {
        font-size: 50px;
    }

    .casestudybody3-section.mobile {
        position: relative !important;
        padding-bottom: 100px;
    }


    .casestudybody3-section.mobile .content-box {
        max-width: 100px;
        margin: auto;
    }

    .casestudybody3-section .content-box h2 {
        font-size: 26px !important;
        line-height: normal;
        margin: 0;
    }

    .casestudybody3-section.mobile .content-box .strok {
        font-size: 60px;
        line-height: 180px;
        top: 12px;
        margin: 0px;
        top: -65px;
        left: 30px;
    }

    .casestudybody3-section.mobile .content-box .h1 {
        font-size: 50px;
        line-height: normal;
        transform: translateY(-8px);
        text-align: center;
    }

    .casestudybody3-section p.anima {
        font-size: 1px !important;
        margin-top: 10px;
    }

    .casestudybody3-section p.anima {
        font-size: 1px !important;
        line-height: 40px !important;
        margin-top: -20px;

    }

    .casestudybody3-section p.anima span.only-anima-underline {
        width: 250px;
    }
 

    .casestudybody3-section.mobile .content-box .h1 span.animate-underline:after {
        position: absolute;
        top: -10px !important;
    }


    .casestudybody3-section.mobile .d-flex.middle p {
        color: #FFF;
        font-family: Work Sans;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        text-align: left;
    }

    .casestudybody3-section .content-box P {
        font-size: 16px !important;
        margin-bottom: 0px;
    }

    ul.ps-steps {
        flex-wrap: wrap;
    }

    ul.ps-steps li {
        position: relative;
        width: 50%;
        padding: 5px 0px;
    }

    ul.ps-steps li .text p {
        font-size: 16px !important;
    }
    
    .casestudybody3-section .sample-output {
        display: flex;
        flex-direction: column;
        gap: 20px;  
    }
    
    
}

@media only screen and (min-device-width : 320px) and (max-device-width : 420px) {
    ul.ps-steps li .text {
        padding: 5px 10px 5px 25px;
    }
    ul.ps-steps li .text p {
        font-size: 12px !important;
    }
}

@media only screen and (min-device-width : 640px) and (max-device-width : 767px) {
    .casestudybody3-section.mobile {
        position: relative !important;
        padding-bottom: 100px;
        /* height: auto !important; */
    }


    .casestudybody3-section .col-md-4,
    .casestudybody3-section .col-md-8 {
        width: 100%;
    } 
    
    .casestudybody3-section .container .left-box {
        position: unset;
        background: #ffffff;
        top: 0;
        left: 0px;
        transform: translateY(0px);
        width: 100%;
        padding: 0px;
    }




    .casestudybody3-section .content-box h2 {
        font-size: 26px !important;
        line-height: normal;
        margin: 0;
    }

    .casestudybody3-section.mobile .content-box .strok {
        font-size: 60px;
        line-height: 180px;
        top: 12px;
        margin: 0px;
        top: -65px;
        left: 30px;
    }

    .casestudybody3-section.mobile .content-box .h1 {
        font-size: 50px;
        line-height: normal;
        transform: translateY(-8px);
        text-align: center;
    }


    .casestudybody3-section.mobile .d-flex.middle p {
        color: #FFF;
        font-family: Work Sans;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        text-align: left;
    }



    .casestudybody3-section.mobile .container {
        height: 100%;
        display: block;
        min-height: 35vh !important;
    }


    .casestudybody3-section.mobile .link {
        position: absolute;
        bottom: 10%;
        left: 20px;
    }

    .casestudybody3-section.mobile .link h3 {
        color: #FFF;
        font-size: 20px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .casestudybody3-section.mobile .link p {
        color: #FFF;
        font-size: 14px !important;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: block;
    }

    .casestudybody3-section p.anima {
        font-size: 1px !important;
        margin-top: 10px;
    }

    .casestudybody3-section.mobile .findout-btn {
        color: #03AD9B;
        text-align: center;
        font-size: 14px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        width: 180px;
        height: 48px;
        padding: 15px 10px;
        justify-content: center !important;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        background-color: transparent;
        border: 1px solid #03A796;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
        /* top: 60%; */
        /* position: absolute; */
    }

    .casestudybody3-section .sample-output {
        display: flex;
        flex-direction: column;
        gap: 20px;  
    }
    
}


@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
    .casestudybody3-section.mobile {
        position: relative !important;
        padding-bottom: 100px;
        /* height: auto !important; */
    }

    .casestudybody3-section .container {
        max-width: 750px;
    }

    .casestudybody3-section .col-md-4,
    .casestudybody3-section .col-md-8 {
        width: 100%;
    } 
    
    .casestudybody3-section .container .left-box {
        position: unset;
        background: #ffffff;
        top: 0;
        left: 0px;
        transform: translateY(0px);
        width: 100%;
        padding: 0px;
    }




    .casestudybody3-section .content-box h2 {
        font-size: 26px !important;
        line-height: 50px;
        margin: 0;
    }

    .casestudybody3-section.mobile .content-box .strok {
        font-size: 60px;
        line-height: 180px;
        top: 12px;
        margin: 0px;
        top: -65px;
        left: 30px;
    }

    .casestudybody3-section.mobile .content-box .h1 {
        font-size: 50px;
        line-height: normal;
        transform: translateY(-8px);
        text-align: center;
    }


    .casestudybody3-section.mobile .d-flex.middle p {
        color: #FFF;
        font-family: Work Sans;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        text-align: left;
    }



    .casestudybody3-section.mobile .container {
        height: 100%;
        display: block;
        min-height: 35vh !important;
    }


    .casestudybody3-section.mobile .link {
        position: absolute;
        bottom: 10%;
        left: 20px;
    }

    .casestudybody3-section.mobile .link h3 {
        color: #FFF;
        font-size: 20px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .casestudybody3-section.mobile .link p {
        color: #FFF;
        font-size: 14px !important;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: block;
    }

    .casestudybody3-section p.anima {
        font-size: 1px !important;
        margin-top: 10px;
    }

    .casestudybody3-section.mobile .findout-btn {
        color: #03AD9B;
        text-align: center;
        font-size: 14px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        width: 180px;
        height: 48px;
        padding: 15px 10px;
        justify-content: center !important;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        background-color: transparent;
        border: 1px solid #03A796;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
        /* top: 60%; */
        /* position: absolute; */
    }
}


@media (min-width: 1024px) and (max-width: 1080px) {
    .casestudybody3-section .content-box h2 {
        font-size: 30px;
        /* line-height: 48.3px; */
    }

    .casestudybody3-section .content-box .h1 {
        font-size: 56px;
        line-height: 42px;
    }

    .casestudybody3-section .content-box P {
        font-size: 19px;
    }

    .casestudybody3-section p.anima {
        font-size: 1px;
        margin-top: 10px;
    }

    .casestudybody3-section .content-box .strok {
        font-size: 154px;
        line-height: 126px;
    }

    .casestudybody3-section .content-box  .text p {
        font-size: 15.4px;
    }

    ul.ps-steps li .text p {
        font-size: 15.4px !important;
    }
}

@media (min-width: 1080px) and (max-width: 1280px) {
    .casestudybody3-section .content-box h2 {
        font-size: 30px;
        /* line-height: 51.75px; */
    }

    .casestudybody3-section .content-box .h1 {
        font-size: 60px;
        line-height: 45px;
    }

    .casestudybody3-section .content-box P {
        font-size: 21px;
    }

    .casestudybody3-section p.anima {
        font-size: 1px;
        margin-top: 10px;
    }

    .casestudybody3-section .content-box .strok {
        font-size: 165px;
        line-height: 135px;
    }

    ul.ps-steps li .text p {
        font-size: 16px !important;
    }

}

@media (min-width: 1280px) and (max-width: 1360px) {

    .casestudybody3-section .content-box h2 {
        font-size: 32px;
        /* line-height: 55.2px; */
    }

    .casestudybody3-section .content-box .h1 {
        font-size: 64px;
        line-height: 48px;
    }

    .casestudybody3-section .content-box P {
        font-size: 22.4px;
    }

    .casestudybody3-section p.anima {
        font-size: 1px;
        margin-top: 10px;
    }

    .casestudybody3-section .content-box .strok {
        font-size: 176px;
        line-height: 144px;
    }

    ul.ps-steps li .text p {
        font-size: 17.6px !important;
    }
}

@media (min-width: 1360px) and (max-width: 1440px) {

    .casestudybody3-section .content-box h2 {
        font-size: 34px;
        /* line-height: 58.65px; */
    }

    .casestudybody3-section .content-box .h1 {
        font-size: 68px;
        line-height: 51px;
    }

    .casestudybody3-section .content-box P {
        font-size: 23.8px;
    }
    .casestudybody3-section p.anima {
        font-size: 1px;
        margin-top: 10px;
    }

    .casestudybody3-section .content-box .strok {
        font-size: 187px;
        line-height: 153px;
    }

    ul.ps-steps li .text p {
        font-size: 18.7px !important;
    }

}

@media (min-width: 1440px) and (max-width: 1680px) {

    .casestudybody3-section .content-box h2 {
        font-size: 36px;
        /* line-height: 62.1px; */
    }

    .casestudybody3-section p.anima {
        font-size: 1px;
        margin-top: 15px;
    }

    .casestudybody3-section .content-box .h1 {
        font-size: 72px;
        line-height: 54px;
    }

    .casestudybody3-section .content-box P {
        font-size: 25.2px;
    }
    .casestudybody3-section .content-box .strok {
        font-size: 198px;
        line-height: 162px;
    }

    ul.ps-steps li .text p {
        font-size: 19.8px !important;
    }

}   

@media (min-width: 1680px) and (max-width: 1919px) {

    .casestudybody3-section .content-box h2 {
        font-size: 38px;
        /* line-height: 65.55px; */
    }

    .casestudybody3-section .content-box .h1 {
        font-size: 76px;
        line-height: 57px;
    }

    .casestudybody3-section .content-box P {
        font-size: 26.6px;
    }
    .casestudybody3-section .content-box .strok {
        font-size: 209px;
        line-height: 171px;
    }

    ul.ps-steps li .text p {
        font-size: 20.9px !important;
    }

}

@media only screen and (min-width: 1920px) {}


@media (min-width: 1680px) {
    .casestudybody3-section .container {
        max-width: 1540px;
    }
}