.about-section {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    background: url('../images/about-bg.webp') no-repeat;
    background-size: contain !important;
    background-position: top left;
    overflow-x: hidden;
    position: relative;
}

  
  @media only screen   
  and (min-device-width : 320px)   
  and (max-device-width : 767px)  
  {
    .about-section {
        background: none;
    }
  
  }


    
  @media only screen   
  and (min-device-width : 768px)   
  and (max-device-width : 1023px)  
  {
    .about-section {
        background: none;
    }

    .about-section .container {
        max-width: 540px;
    }
  
  }


      
  @media only screen   
  and (min-device-width : 1024px)   
  and (max-device-width : 1440px)  
  {
    .about-section {
      background: url('../images/about-tab-bg.webp') no-repeat;
    }
  
  }
