

.course-page-mobile-gradient-border img {
    width: 100% !important;
  }
  .mobilie-section-career-page .row {
    gap: 20px 0px;
  }
  .mobilie-section-career-page .row .col-md-6:nth-child(2) {
    margin-top: 70px;
  }
  .mobilie-section-career-page .row .col-md-6:nth-child(3) , .mobilie-section-career-page .row .col-md-6:nth-child(5) {
    margin-top: -70px;
  }
  .weserve-career-section-desktop .content-box .joinus-section-career {
    width: 100%;
    left: 40px !important;
    top: -50px !important;
    font-size: 220px;
  }


/* scroll animation */

.left-fixed.col {
    height: 100vh;
}

.right-scroll {
    padding: 5% 0px;
    vertical-align: middle;
    justify-content: center;
    display: flex;
    z-index: 1;
    overflow-y: auto;
    scrollbar-width: none !important;
    -ms-overflow-style: none;
}

.scroll-section {
    height: 100vh;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-width: none !important;
    -ms-overflow-style: none;
}

::-webkit-scrollbar {
    display: none;
}


/* scroll  */
.scroll-section {
    max-height: 100%;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    /* overflow-y: hidden; */
}

.scroll-page {
    max-height: 99%;
    width: 100%;
    /* overflow-y: auto; */
    scrollbar-width: none;
}


.scroll-page::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.scroll-page::-moz-scrollbar {
    width: 0px;
    display: none;
}

/* gradient image */

.gredientbox {
    position: relative;
    cursor: pointer;
    /* overflow: hidden; */
    max-width: 313px;
    border-radius: 20px;
}


.right-scroll-weserve-section .second-classer-top {
    margin-top: 40px;
}


.gredientbox .overlay {

    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 41.67%, #000000 91.42%);
    border-radius: 20px;

}

.gredientbox .overlay-text {

    position: absolute;
    width: 100%;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
    z-index: 2;
    transition: all .3s;
}

.gredientbox:hover .overlay-text {
    font-size: 26px;
    bottom: 20%;
    transform: translate(-50% -20%);
}


.gredientbox img.card-img {
    position: relative;
    border-radius: 20px;
    max-width: 313px;
    transition: all .3s;
}

.gredientbox:hover img.card-img {
    border-radius: 20px;
    transform: scale(1.2);
}

/* .gredientbox img.card-img:before,
.gredientbox img.card-img:after {
	 content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    border: solid white;
    border-radius:20px;
    background:red;
    display: block;
    z-index:-1;
}

.gredientbox img.card-img:before {
    border-width:2px 0 0 2px;
    left: -2px;
    top: -2px;
}

.gredientbox img.card-img:after {
    border-width:0 2px 2px 0;
    bottom: -2px;
    right: -2px;
} */

/* bk */
/* 
.gredient-border {
    overflow: hidden;
}

.gredientbox:before,
.gredientbox:after {
	 content: "";
    position: absolute;
    width: 60%;
    height: 50%;
    border-radius:20px;
    display: block;
    z-index:-1;
}

.gredientbox:before {
    border-width:2px 0 0 2px;
    background:#FE54BB;
    background: linear-gradient(45deg, #FE54BB , transparent);
    left: -2px;
    top: -3px;
}

.gredientbox:after {
    border-width:0 2px 2px 0;
    background:#11E9C7;
    background: linear-gradient(45deg, #11E9C7 , transparent);
    bottom: -2px;
    right: -2px;
} */



.gredient-border {
    /* overflow: hidden; */
    position: relative;
    max-width: 313px;
    float: right;
}


.gredientbox {
    overflow: hidden;
}


#lifeps .gredient-border:before,
#lifeps .gredient-border:after {
    content: "";
    position: absolute;
    width: 50%;
    height: 50%;
    border-radius: 20px;
    display: block;
    z-index: -1;
}

#lifeps .gredient-border:before {
    border-width: 2px 0 0 2px;
    background: #FE54BB;
    background: linear-gradient(45deg, #FE54BB, transparent);
    left: -2px;
    top: -3px;
}

#lifeps .gredient-border:after {
    border-width: 0 2px 2px 0;
    background: #11E9C7;
    background: linear-gradient(45deg, #11E9C7, transparent);
    bottom: -2px;
    right: -2px;
}

@media only screen and (max-width: 767px) {
    .lifeat-ps-section-main.weserve-section-main-top .col-md-5 {
        padding-top: 0px !important;
      }
    .scroll-section.career-page-scroll-sectioner .row .col-md-6 {
        width: 50%;
    }
    .scroll-section.career-page-scroll-sectioner .row {
        padding: 10px 0px !important;
        margin: 0px !important;
    }
    .scroll-section.career-page-scroll-sectioner .row .col-md-6:nth-child(3) {
        margin-top: -40px !important;
    } 
    .scroll-section.career-page-scroll-sectioner .row .col-md-6:nth-child(4) {
        margin-top: 0px !important;
    }

}

.right-scroll-weserve-section {
    padding: 0px !important;
}
.lifeat-ps-section-main.weserve-section-main-top .col-md-5 {
    padding-top: 150px;
  }

@media only screen and (max-width: 574px) {
    .weserve-section-main-top .gredientbox .overlay-text{
        font-size: 10.74px !important;
    }
}

.weserve-section-main-top .we-serve-about-career {
    position: relative;
    z-index: 99;
}



.we-job-openings-main-section {
    background: linear-gradient(121.92deg, #041E30 13.01%, #000000 74.52%);
    padding: 94px 0px;
    font-family: Helvetica !important;
}
.we-left-job-open-column-inner .we-latest-p-section p{
    font-size: 40px;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize; 
    padding-left: 10px !important;
}

.we-bottom-jobber-section {
    display: block;
}
.we-bottom-jobber-section-mobile-viewer {
    display: none;
}
.we-left-job-open-column-inner .we-bottom-jobber-section h2 {
    font-size: 80px;
    font-weight: 700;
    line-height: normal;
    display: flex;
    gap: 15px;
}
.we-left-job-open-column-inner .we-job-absolute-text p{
    font-family: 'Work Sans', sans-serif !important; 
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #2A5450;
    -webkit-text-stroke: 1px #2A5450;
    font-size: 160px;
    line-height: 180px;
    font-weight: 700;
}
.we-job-absolute-text p{
    width: fit-content;
    position: relative;
}

/* .we-job-absolute-text p::after {
    content: '';
    width: 100%;
    height: 8px;
    position: absolute;
    bottom: 5px;
    left: 0;
    border: 1px solid #2A5450; 
} */

.we-job-absolute-text {
    position: absolute;
    top: -50px;
    left: 65px;
}

.we-bottom-jobber-section {
    position: relative;
    z-index: 99;
}
.we-bottom-jobber-section h2 span {
    display: inline-block;
    overflow: hidden;
    position: relative;
    padding-bottom: 15px !important;
}
.we-bottom-jobber-section h2 span::after {
    background: linear-gradient(to bottom, rgba(20, 20, 20, 0.8), rgba(20, 20, 20, 0.8)) center 1.08em/100% 5px no-repeat;
    content: "";
    height: 100%;
    right: 0;
    position: absolute;
    top: 25px;
    width: 400%;
    will-change: transform;
    z-index: 1;
    -webkit-animation: underline-gradient 6s linear infinite;
    animation: underline-gradient 6s linear infinite;
    background-image: linear-gradient(270deg, rgba(241, 21, 118, 1) 0%, rgba(1, 198, 178, 1) 20%, rgba(241, 21, 118, 1) 40%, rgba(1, 198, 178, 1) 60%, rgba(241, 21, 118, 1) 80%, rgba(1, 198, 178, 1) 100%);
}
.we-latest-p-section {
    margin-bottom: -12px !important;
    position: relative;
    z-index: 99;
}
.after-767-para-content {
    margin: 45px 0px 30px 0px;
    display: none;
  }


  
@media only screen and (max-width: 1200px) {
    .we-left-job-open-column-inner .we-latest-p-section p {
        padding-left: 0px !important;
    }
}


@media only screen and (max-width: 991px) {
    .weserve-botrows-section .col-md-6 {
        width: 100% !important;
    }
    .we-left-job-open-column-inner .we-latest-p-section p {
        padding-left: 0px !important;
    }
    .we-job-absolute-text {
        position: absolute;
        top: -40px;
        right: 0px;
      }
    .career-page-scroll-sectioner .col-md-6 {
        width: 50% !important;
    }
    .we-left-side-openings-header {
        margin-bottom: 40px;
      }
}


@media only screen and (max-width: 767px) {
    .we-bottom-jobber-section {
        display: none;
    }
    .careerdes-section p {
        font-size: 20px;
        line-height: 150%;
      }
    .we-bottom-jobber-section-mobile-viewer {
        display: block;
        position: relative;
    }
    .we-bottom-jobber-section-mobile-viewer h2 {
        font-size: 70px;
        font-weight: 700;
    }
    .we-left-job-open-column-inner .we-latest-p-section p {
        font-size: 30px;
    }
    .we-bottom-jobber-section-mobile-viewer .animator-we-bottom-767-after {
        position: relative;
        width: 100%;
        max-width: 200px;
        margin: -20px 200px 0px auto;
        height: 25px;
        overflow: hidden;
    }
    .we-bottom-jobber-section-mobile-viewer .animator-we-bottom-767-after::after {
        background: linear-gradient(to bottom, rgba(20, 20, 20, 0.8), rgba(20, 20, 20, 0.8)) center 1.08em/100% 5px no-repeat;
        content: "";
        height: 100%;
        right: 0;
        position: absolute;
        top: 0px;
        width: 400%;
        will-change: transform;
        z-index: 1;
        -webkit-animation: underline-gradient 6s linear infinite;
        animation: underline-gradient 6s linear infinite;
        background-image: linear-gradient(270deg, rgba(241, 21, 118, 1) 0%, rgba(1, 198, 178, 1) 20%, rgba(241, 21, 118, 1) 40%, rgba(1, 198, 178, 1) 60%, rgba(241, 21, 118, 1) 80%, rgba(1, 198, 178, 1) 100%);
    }    
    .we-job-absolute-text p::after {
        display: none;
    }
    .we-job-absolute-text p {
        font-size: 120px !important;
        margin-bottom: 0px !important;
    }
    .we-job-absolute-text {
        top: -80px;
    }
    .after-767-para-content {
        margin: 45px 0px 30px 0px;
        display: block;
    }
    .after-767-para-content p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20.8px 
    }
    .we-left-side-openings-header {
        margin-bottom: 0px !important;
    } 
    .weserve-section-main-top .container {
        padding: 0px 20px !important;
    }
}


@media only screen and (max-width: 674px) {
    .after-767-para-content {
        margin: 45px 0px 0px 0px !important;
        display: block;
    }
}

@media only screen and (max-width: 640px) {
    .weserve-section-main-top {
        padding: 80px 0px !important;
    }
}


@media only screen and (max-width: 474px) {
    .we-serve-caree-section-para {
        text-align: center !important;
        color: #000;
        text-align: center;
        font-family: Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height:  24px
    }
    .we-left-job-open-column-inner .we-latest-p-section p {
        font-size: 26px;
        line-height: normal; 
        padding-left: 0px !important;
    }
    .we-bottom-jobber-section-mobile-viewer h2 {
        font-size: 50px;
        font-weight: 700; 
    }
    .we-bottom-jobber-section-mobile-viewer .animator-we-bottom-767-after {
        max-width: 133px;
        margin: -20px auto 0px auto;
        height: 21px;
    }
    .we-job-absolute-text p {
        /* font-size: 60px !important; */
        margin-bottom: 0px !important;
        font-family: 'Work Sans', sans-serif !important; 
        font-size: 60px !important;
    }
    .we-job-absolute-text {
        position: absolute;
        top: -70px;
        right: 10%
    } 
    .left-added-text-section {
        padding-left: 20px;
    }
}