section.default-page {
    width: 100%;
    min-height: 100vh;
    position: relative;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 5.67%, rgba(0, 0, 0, 0.488542) 10.88%, rgba(0, 0, 0, 0) 30.38%);
    background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%); 
    background: url('../images/404-bg.webp') no-repeat top;
    background-position: top left;
    background-size: cover;
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


section.default-page .animation-gradient {
    width: 100%;
    position: absolute;
    object-fit: cover;
    height: 100vh;
    z-index: 1;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 200% 200%;
    opacity: 0.7;
	animation: gradient 7s ease-in infinite;
	height: 100vh;
}

section.default-page .animation-svg {
    width: 100%;
    position: absolute;
    object-fit: cover;
    height: 100vh;
    z-index: 1;
    opacity: 0.7;
}

section.default-page .animation-svg svg {
    max-height: 100vh;
    height: 100%;
    width: 100%;
}


section.default-page .hero {
    min-height: 100vh;
    position: relative;
    z-index: 2;

}

section.default-page .hero-content {
    width: 100%;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    max-width: 85%;
    max-height: 65%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
}

section.default-page .hero-text {
    /* padding-left: 5px; */
    /* border-left:5px solid #F11576; */
    position: relative;
    text-align: center;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}

/* section.default-page .hero-text:before {
    content: "";
    width: 6px;
    background: #F11576;
    height: 85%;
    position: absolute;
    top: 3%;
    left: -10px;
} */


section.default-page .hero-text .text-box {
    transform: translate(-50% , -50%);
    position: absolute;
    top: 75%;
    left: 50%;
    text-align: center;
    margin: auto;
}

section.default-page .hero-text .svg-404 {
    /* max-width: 1182px;
    max-height: 651px; */
    max-height: 65vh;
    width: 100%;
    height: 100%;
}

section.default-page .hero-text h1 {
    color: #fff;
    border: 4px solid;
    background: rgb(0, 26, 0);
    mix-blend-mode: multiply;
    font: bolder 20vw Helvetica;
    text-align: center;
    margin: 0;
  }

section.default-page .hero-text h3 {
    color: #000;
    text-align: center;
    font-family: Helvetica;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

section.default-page .hero-text p {
    color: #000;
    text-align: center;
    font-family: Helvetica;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}




section.default-page .hero-text .backto-btn {

    padding: 20px;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: 1px solid #03A796;
    background-color: #03A796;
    z-index: +1;
    max-width: 313px;
}



section.default-page .navbar-nav a {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.393478px;
    color: #FFFFFF;
}





/* For 480 Resolution */  
@media only screen   
and (min-device-width : 320px)   
and (max-device-width : 767px)  
{ /* STYLES GO HERE */

    section.default-page {
        background-position: 25%;
        background: url('../images/404-bg.webp') no-repeat top;
    }

    
    section.default-page .hero-content {
        top:50%;
    }

    section.default-page .animation-svg svg {
        height: 100%;
    }

    /* section.default-page .hero-text {
        max-width: 300px;
    } */

    section.default-page .hero-text .text-box {
        transform: translate(-50% , -50%);
        position: absolute;
        top: 60%;
        left: 50%;
        text-align: center;
        margin: auto;
    }
    
    section.default-page .hero-text .svg-404 {
        /* max-width: 1182px;
        max-height: 651px; */
        max-height: 65vh;
        width: 100%;
        height: 100%;
    }
    

    section.default-page .hero-text h3 {
        font-size: 20px;
    }

    section.default-page .hero-text p {
        font-size: 16px;
    }

    section.default-page .hero-text .backto-btn {
        font-size: 14px;
        padding: 10px 15px;
    }
} 




@media only screen   
and (min-device-width : 480px)   
and (max-device-width : 768px)  
{ 
    section.default-page {
        background-position: 55%;
        background: url('../images/404-bg.webp') no-repeat top;
    }
    
    section.default-page .hero-text h3 {
        font-size: 26px;
    }

    section.default-page .hero-text p {
        font-size: 18px;
    }

    section.default-page .hero-text .backto-btn {
        font-size: 14px;
        padding: 15px 20px;
    }
}

/* For 1024 Resolution */  
@media only screen   
and (min-device-width : 768px)   
and (max-device-width : 1024px)  
{ 
    section.default-page .mobile {
        display: none;
    }

    section.default-page .desktop {
        display: block;
    }

    section.default-page {
        background-position: 25%;
        background: url('../images/404-bg.webp') no-repeat top;
    }

    
    section.default-page .hero-content {
        top:40%;
    }


    section.default-page .hero-text h3 {
        font-size: 30px;
        line-height: normal;
    } 
       
    
    section.default-page .hero-text p {
        font-size: 18px;
        line-height: normal;
    }

    section.default-page .hero-text .backto-btn {
        font-size: 14px;
        padding: 10px 15px;
    }

} 


@media  (min-width: 1024px)  and (max-width: 1280px)  
{ 
    /* 84 */

    section.default-page .hero-text h3 {
        font-size: 28px;
        line-height: normal;
    } 
       
    
    section.default-page .hero-text p {
        font-size: 14px;
        line-height: normal;
    }

}  



@media  (min-width: 1280px)  and (max-width: 1360px)  
{ 
    /* 90 */

    section.default-page .hero-text h3 {
        font-size: 30px;
        line-height: normal;
    } 
       
    
    section.default-page .hero-text p {
        font-size: 15px;
        line-height: normal;
    }


}  

@media  (min-width: 1360px)  and (max-width: 1440px)  
{  
    /* 96 */ 
    section.default-page .hero-text h3 {
        font-size: 32px;
        line-height: normal;
    } 
       
    section.default-page .hero-text p {
        font-size: 16px;
        line-height: normal;
    } 

}  

@media  (min-width: 1440px)  and (max-width: 1680px)  
{  
    /* 102 */
    section.default-page .hero-text h3 {
        font-size: 34px;
        line-height: normal;
    } 
       
    section.default-page .hero-text p {
        font-size: 17px;
        line-height: normal;
    } 

}  

@media  (min-width: 1680px)  and (max-width: 1919px)  
{ 
    /* 108 */
    section.default-page .hero-text h3 {
        font-size: 36px;
        line-height: normal;
    } 
       
    section.default-page .hero-text p {
        font-size: 18px;
        line-height: normal;
    } 


}  

@media only screen  and (min-width: 1920px) {



} 