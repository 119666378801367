.solutiondes-section p {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: left;
    color: #000000;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .solutiondes-section p {
        color: #000;
        font-size: 16px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-align: justify;
       margin: auto;
    }
}

@media only screen and (min-device-width : 481px) and (max-device-width : 767px) {
    .solutiondes-section p {
        color: #000;
        font-size: 16px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-align: justify;
    }
}


@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
    .solutiondes-section p {
        font-size: 18px;
        text-align: justify;
    }
}



@media (min-width: 1024px) and (max-width: 1080px) {
    .solutiondes-section p {
        font-size: 19.6px;
        font-size: 14px;
    }
}

@media (min-width: 1081px) and (max-width: 1280px) {
    .solutiondes-section p {
        font-size: 21px;
        font-size: 15px;
    }
}

@media (min-width: 1281px) and (max-width: 1360px) {
    .solutiondes-section p {
        font-size: 22.4px;
        font-size: 16px;
    }
}

@media (min-width: 1361px) and (max-width: 1440px) {
    .solutiondes-section p {
        font-size: 23.8px;
        font-size: 17px;
    }
}

@media (min-width: 1441px) and (max-width: 1680px) {
    .solutiondes-section p {
        font-size: 25.2px;
        font-size: 18px;
    }
}

@media (min-width: 1680px) and (max-width: 1919px) {
    .solutiondes-section p {
        font-size: 26.6x;
        font-size: 19px;
    }
}



@media only screen and (min-width: 1920px) {}

