header.header-casestudy {
    width: 100%;
    min-height: 400px;
    position: relative;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 5.67%, rgba(0, 0, 0, 0.488542) 10.88%, rgba(0, 0, 0, 0) 30.38%);
    background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%); 
    background: url('../images/header-casestudy.webp') no-repeat top;
    background: linear-gradient(172deg, #041E30 0%, #000 100%);
    background-position: top left;
    background-size: cover;
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


header.header-casestudy .animation-gradient {
    width: 100%;
    position: absolute;
    object-fit: cover;
    height: 100vh;
    z-index: 1;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 200% 200%;
    opacity: 0.7;
	animation: gradient 7s ease-in infinite;
	height: 100vh;
}

header.header-casestudy .animation-svg {
    width: 100%;
    position: absolute;
    object-fit: cover;
    height: 100vh;
    z-index: 1;
    opacity: 0.7;
}

header.header-casestudy .animation-svg svg {
    max-height: 100vh;
    height: 100%;
    width: 100%;
}

header.header-casestudy .hero {
    position: relative;
    z-index: 2;
    min-height: 400px;
    align-items: flex-end;
    display: flex;
    padding-bottom: 40px;   
}

header.header-casestudy.header-casestudy9 .hero {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

header.header-casestudy .hero-content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%); */
}


/* header.header-casestudy.header-casestudy20 .hero-content ,
header.header-casestudy.header-casestudy21 .hero-content,
header.header-casestudy .hero-content {
    justify-content: flex-start;
    padding-left: 125px;
}

header.header-casestudy.header-casestudy22 .hero-content {
    padding-left: 80px;
} 

header.header-casestudy.header-casestudy7 .hero-content {
    justify-content: start;
}
*/


header.header-casestudy.header-casestudy23 .hero-content {
    justify-content: center;
} 

header.header-casestudy .hero-content {
    justify-content: start;
    padding-left: 0px;
}

header.header-casestudy .hero-text {
    padding-left: 25px;
    /* border-left:5px solid #F11576; */
    position: relative;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}

header.header-casestudy .hero-text:before {
    content: "";
    width: 6px;
    background: #F11576;
    height: 92%;
    position: absolute;
    top: 2%;
    left: 10px;
}

header.header-casestudy .hero-text h4 { 
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 69px;
    /* font-size: 40px !important;
    line-height: 97.99% !important; */
    letter-spacing: 1px;
    color: #FFFFFF;
}

header.header-casestudy .hero-text h3 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 97.99%;
    /* font-size: 80px !important;
    line-height: 97.99% !important; */
    letter-spacing: 1px;
}

header.header-casestudy .hero-text h2 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 118px;
    /* font-size: 80px !important;
    line-height: 97.99% !important; */
    letter-spacing: 1px;
    color: #F9F5AA;
    color:#ffffff
}

header.header-casestudy.header-casestudy11 .hero-text h2,
header.header-casestudy.header-casestudy13 .hero-text h2  {
    color: #FFFFFF !important;
} 

header.header-casestudy.header-casestudy11 .hero-text h2 span {
    color: #F9F5AA;
    color: #ffffff;
} 


header.header-casestudy .navbar-nav a {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.393478px;
    color: #FFFFFF;
}



/* For 480 Resolution */  
@media only screen and (min-device-width : 320px) and (max-device-width : 767px)  {

    header.header-casestudy {
        min-height: 200px;
    }

    header.header-casestudy .hero-content {
        justify-content: center !important;
    }

    header.header-casestudy .hero {
        min-height: 250px;    
    }

    header.header-casestudy3 .hero ,
    header.header-casestudy5 .hero ,
    header.header-casestudy6 .hero ,
    header.header-casestudy10 .hero ,
    header.header-casestudy16 .hero {
        min-height: 300px;   
    }

    header.header-casestudy .hero-content {
        padding-left:0px !important;
    }
	
    header.header-casestudy.header-casestudy4 .hero {
        min-height: 330px;
    }

    header.header-casestudy.header-casestudy10 .hero-text h2 {
        padding-left: 0px !important;
    }

    header.header-casestudy .animation-svg svg {
        height: 100%;
    }

    header.header-casestudy .hero-text {
        max-width: 80%;
        padding-left: 5px;
    }



    header.header-casestudy .hero-text h4 {
        font-size: 16px !important;
        line-height: 20px !important;
    }


    header.header-casestudy .hero-text h2 {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    header.header-casestudy .hero-text h3 {
        font-size: 30px !important;
        line-height: 30px !important;
        color: #ffffff;
    }

    header.header-casestudy .hero-text:before {
        width: 4px;
        left: -4px;
        height: 90%;
        top: 0%;
    }
} 


/* For 480 Resolution */  
@media only screen and (min-device-width : 768px) and (max-device-width : 1023px)  {

    header.header-casestudy {
        /* background-position: 40%; */
        min-height: 200px;
    }


    header.header-casestudy .container {
        max-width: 750px;
    }
    
    /* header.header-casestudy .hero-content {
        top:35%;
    } */

    header.header-casestudy .hero {
        min-height: 450px;    
    }

    header.header-casestudy .animation-svg svg {
        height: 100%;
    }

    header.header-casestudy .hero-text {
        max-width: 680px;
    }
    header.header-casestudy .hero-text h4 {
        font-size: 16px !important;
        line-height: 20px !important;
        font-size: 40px !important;
        line-height: 46px !important;
    }


    header.header-casestudy .hero-text h2 {
        font-size: 30px !important;
        line-height: 30px !important;
        font-size: 75px !important;
        line-height: 82px !important;

        font-size: 50px !important;
        line-height: 56px !important;
    }


    header.header-casestudy .hero-text h3 {
        font-size: 30px !important;
        line-height: 30px !important;
        font-size: 75px !important;
        line-height: 82px !important;

        font-size: 32px !important;
        line-height: 38px !important;
    }

    header.header-casestudy .hero-text:before {
        width: 4px;
        left: 5px;
        height: 96%;
        top: 2%;
    }
} 



@media  (min-width: 1024px)  and (max-width: 1280px)  
{ 
    /* 84 */

    header.header-casestudy {
        min-height: 350px;    
    }

    header.header-casestudy .hero {
        min-height: 350px;    
    }

    /* header.header-casestudy.header-casestudy22 .hero-content {
        max-width: 974px;
        padding: 0px;
        margin-left: auto;
        margin-right: auto;
    }
     */

    /*      
     header.header-casestudy.header-casestudy17 .hero-content {
        padding-left: 20px;
     }

     header.header-casestudy.header-casestudy17 .hero-content {
        padding-left: 0px;
     } */

     header.header-casestudy .hero-content { 
        padding-left: 0px;
        justify-content: flex-start;
     }


    header.header-casestudy .hero-text h2 {
        font-size: 68px;
        font-size: 50px;
        line-height: 60px;
    }

    header.header-casestudy .hero-text h4 {
        font-size: 30px;
        line-height: 38px;
    } 
        

    /* header.header-casestudy.header-casestudy11 .hero-text h2 {
        font-size: 60px;
    } */
}  


@media  (min-width: 1280px)  and (max-width: 1360px)  
{ 

    header.header-casestudy {
        min-height: 400px;    
    }

    header.header-casestudy .hero {
        min-height: 400px;    
    }
    

    /* 90 */
    header.header-casestudy .hero-text h2 {
        font-size: 90px;
        line-height: 97.99%;
        font-size: 84px;

        font-size: 60px;
        line-height: 92%;

    }

    header.header-casestudy .hero-text h4 {
        font-size: 45px;
        line-height: 51.7px;
    
        font-size: 33px;
        line-height: 45px;
    } 

}  

@media  (min-width: 1360px)  and (max-width: 1440px)  
{  
    /* 96 */ 
    header.header-casestudy .hero-text h2 {
        font-size: 96px;
        line-height: 97.99%;

        font-size: 60px;
        line-height: 92%;
    }

    header.header-casestudy .hero-text h4 {
        font-size: 48px;
        line-height: 55.2px;

        font-size: 33px;
        line-height: 45px;
    } 

}  

@media  (min-width: 1440px)  and (max-width: 1680px)  
{  
/* 
    header.header-casestudy.header-casestudy20 .hero-content ,
    header.header-casestudy.header-casestudy21 .hero-content,
    header.header-casestudy .hero-content {
        justify-content: flex-start;
        padding-left: 100px;
    }

    header.header-casestudy.header-casestudy17 .hero-content {
        justify-content: flex-start;
        padding-left: 60px;
    }   

    header.header-casestudy.header-casestudy11  .hero-content {
        padding-left: 10px;
    } */

    header.header-casestudy .hero-content {
        justify-content: flex-start;
        padding-left: 0px;
    }


    /* 102 */
    header.header-casestudy .hero-text h2 {
        font-size: 102px;
        line-height: 97.99%;

        font-size: 65px;
        line-height: 98%;
    }


    header.header-casestudy .hero-text h4 {
        font-size: 51px;
        line-height: 58.65px;

        font-size: 36px;
        line-height: 50px;
    } 

}  

@media  (min-width: 1680px)  and (max-width: 1919px)  
{ 

    header.header-casestudy .hero-content {
        justify-content: flex-start;
        padding-left: 0px;
    }
    
    /* 108 */
    header.header-casestudy .hero-text h2 {
        font-size: 108px;
        line-height: 97.99%;

        font-size: 75px;
        line-height: 97.99%;
    }

    header.header-casestudy .hero-text h4 {
        font-size: 54px;
        line-height: 61.2px;

        font-size: 44px;
        line-height: 58.65px;
    } 

    header.header-casestudy .container {
        max-width: 1540px;
    }

}  
