.tc-section {
    padding: 50px 0px;
}

.tc-section p {
    color: #000;
    text-align: justify;
    font-family: Helvetica;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px; 
 
}

.tc-section ul li, .tc-section ol li {
    font-size: 22px;
    font-style: normal;
}

.tc-section .tc-section h2 ,  .tc-section h3  {
    font-size: 28px;
    line-height: 41.86px;
}



/* For 480 Resolution */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
 
 
    .tc-section .tc-section h2 ,  .tc-section h3  {
        font-size: 28px;
        line-height: 1;
    }

    .tc-section p {
        font-size: 16px;
        line-height: 1.3em;
    }


    .tc-section ul li, .tc-section ol li {
        font-size: 16px;
        line-height: 1.3em;
    }

}


/* For 640 Resolution */
@media only screen and (min-device-width : 481px) and (max-device-width : 767px) {
    .tc-section p {
        font-size: 16px;
        line-height: 1.3em;
    }

    .tc-section ul li, .tc-section ol li {
        font-size: 16px;
        line-height: 1.3em;
    }


    .tc-section .tc-section h2 ,  .tc-section h3  {
        font-size: 30px;
        line-height: 149.5%;
    }    

}

/* For 640 Resolution */
@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {

   .tc-section .tc-section h2 ,  .tc-section h3  {
        font-size: 40px;
        line-height: 99.667%;
    }    

    .tc-section p {
        font-size: 20px;
        line-height: 24px;
    }

    .tc-section ul li, .tc-section ol li {
        font-size: 20px;
        line-height: 24px;
    }


}


/* For 1024 Resolution */ 
@media  (min-width: 1024px)  and (max-width: 1280px)  {
    
    .tc-section p, .tc-section ul li, .tc-section ol li {
        font-size: 15.4px;
        line-height: 23.1px;
    }
    
    .tc-section .tc-section h2 ,  .tc-section h3  {
        font-size: 28px;
        line-height: 41.86px;
    }
    
   
}
@media  (min-width: 1280px)  and (max-width: 1360px) {
    
    .tc-section p, .tc-section ul li, .tc-section ol li {
        font-size: 16.5px;
        line-height: 24.75px;
    }
    
    .tc-section .blog-content h2 ,  .tc-section .blog-content h3  {
        font-size: 30px;
        line-height: 44.85px;
    }
    
   
}
@media  (min-width: 1360px)  and (max-width: 1440px) {
    .tc-section p, .tc-section ul li, .tc-section ol li {
        font-size: 17.6px;
        line-height: 26.4px;
    }
    
    .tc-section .tc-section h2 ,  .tc-section h3  {
        font-size: 32px;
        line-height: 47.84px;
    }
    
   
}
@media  (min-width: 1440px)  and (max-width: 1680px) {
    
    .tc-section p, .tc-section ul li, .tc-section ol li {
        font-size: 18.7px;
        line-height: 28.05px;
    }
    
    .tc-section .tc-section h2 ,  .tc-section h3  {
        font-size: 34px;
        line-height: 50.83px;
    }
    
   
}
@media  (min-width: 1680px)  and (max-width: 1919px){
    
    .tc-section p, .tc-section ul li, .tc-section ol li {
        font-size: 19.8px;
        line-height: 29.7px;
    }
    
    .tc-section .tc-section h2 ,  .tc-section h3  {
        font-size: 36px;
        line-height: 53.82px;
    }
   
}
@media only screen  and (min-width: 1920px) {}