.news-section {
    padding:50px 0px;
}


.news-section .img-div img {
    width: 100%;
    min-width: 80px;
    height: auto;
    min-height: 80px;
}

.news-section .botm-border {
    position: relative;
    padding-bottom: 21px;
}

.news-section .botm-border::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    flex-shrink: 0;
    background: #172B4D;
    top: 86%;
    left: 0px;
}

.news-section .news-main-heading {
    color: #172B4D;
    font-family: Helvetica;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height:  1.1em;
    text-transform: capitalize;
}

.news-section .blog-content p ,
.news-section .blog-content  h4,
.news-section .blog-content  ul li   {
    color: #000;
    text-align: justify;
    font-family: Helvetica;
    font-size: 22px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.news-section .blog-content  h4  {
    font-weight: 700;
}
.blog-content img {
    width: 100%;
}

.news-section .blog-content h2 ,  .news-section .blog-content h3  {
    color: #172B4D;
    font-family: Helvetica;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 149.5%;
    /* 149.5% */
}


.news-section .blog-content ol li::marker {
    color: #172B4D;
    font-family: Helvetica;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 149.5%;
}

.news-section .paddingright {
    padding-right: 40px;
}

.news-section .paddingleft,.news-section .paddingleft2 {
    padding-left: 50px;

}

.news-section .latest-news {
    color: #000;
    font-family: Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 23.8px;
    /* 99.167% */
    letter-spacing: 0.84px;
    text-transform: uppercase;
    padding-bottom: 21px;
}

.news-section .tab-div h4 {
    color: #000;
    font-family: Helvetica;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    /* 105% */
    letter-spacing: 0.72px;
    text-transform: uppercase;
}

.blue {
    color:#172B4D !important;
}

ul.vertax-list { list-style:none;}
ul.vertax-list li {position:relative;}
ul.vertax-list li:before {content: "";background: url(https://sandboxmachine.com/ps/wp-content/uploads/2024/04/vuesax.png) no-repeat;position: absolute; left: -20px;top: 2px;width: 100%;    height: 100%;}


.news-section .side-para {
    color: #172B4D;
    font-family: Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 133.333% */
}


.news-section button.readreport-btn {
    
    /* background: linear-gradient(180deg, #01c6b2, #048d7f 98.44%); */
    border: 1px solid #03AD9B;
    /* box-shadow: 0 2px 8px 0 #00000040; */
    color: #03AD9B;
    background: #ffffff;
    cursor: pointer;
    font-family: Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    max-width: 313px;
    padding: 30px 50px;
    padding: 20px !important;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    vertical-align: middle;
}


/* For 480 Resolution */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .news-section .paddingright {
         width: 100%;    
         padding-right: 0px;
    }

    .news-section .paddingleft {
        width: 100%;    
        padding-left: 0px;
    }

    .news-section .lt-news,
    .news-section .blog-heading ,
    .news-section .blog-text {
        padding: 0px 15px;
    }
    

    .news-section .news-main-heading {
        font-size: 28px;
        line-height:  1.1em;
    }

    .news-section .blog-content p ,
    .news-section .blog-content  h4,
    .news-section .blog-content  ul li  {
        font-size: 16px;
        line-height: 1.3em;
    }

    /* .news-section .blog-content img {
        width: 100%;
    } */


    .news-section .news-main-heading {
        font-size: 40px;
        line-height:  1.1em;
    }

    .news-section .blog-content h2 ,  .news-section .blog-content h3  {
        font-size: 30px;
        line-height: 149.5%;
    }
    
    
    .news-section .blog-content ol li::marker {
        font-size: 30px;
        line-height: 149.5%;
    }
}

/* @media only screen and (min-device-width : 320px) and (max-device-width : 640px) {
    .news-section .blog-content p ,
.news-section .blog-content  h4  {
        font-size: 16px;
        line-height: 1.3em;
    }

    .news-section .news-main-heading {
        font-size: 40px;
        line-height: 99.667%;
    }

    .news-section .blog-content h2 ,  .news-section .blog-content h3  {
        font-size: 30px;
        line-height: 149.5%;
    }
    
    
    .news-section .blog-content ol li::marker {
        font-size: 30px;
        line-height: 149.5%;
    }
    
} */

/* For 640 Resolution */
@media only screen and (min-device-width : 481px) and (max-device-width : 768px) {
    .news-section .paddingright {
        padding-right: 0px;
    }

    .news-section .paddingleft {
        padding-left: 0px;

    }

    .news-section .col-md-4,
    .news-section .col-md-8 {
        width: 100%;
        margin: auto;
    }

    .news-section .blog-content p ,
    .news-section .blog-content  h4,
    .news-section .blog-content  ul li  {
        font-size: 16px;
        line-height: 1.3em;
    }

    .news-section .news-main-heading {
        font-size: 40px;
        line-height:  1.1em;
    }

    .news-section .blog-content h2 ,  .news-section .blog-content h3  {
        font-size: 30px;
        line-height: 149.5%;
    }
    
    
    .news-section .blog-content ol li::marker {
        font-size: 30px;
        line-height: 149.5%;
    }
}

/* For 640 Resolution */
@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
    .news-section .paddingright {
        padding-right: 0px;
    }

    .news-section .paddingleft {
        padding-left: 0px;
    }

    .news-section .container {
        max-width: 720px;
    }

    .news-section .col-md-4,
    .news-section .col-md-8 {
        width: 100%;
        margin: auto;
    }

    .news-section .news-main-heading {
        font-size: 40px;
        line-height:  1.1em;
    }

    .news-section .blog-content p ,
    .news-section .blog-content  h4,
    .news-section .blog-content  ul li  {
        font-size: 20px!important;
        line-height: 24px;
    }

}

/* For 1024 Resolution */ 
@media  (min-width: 1024px)  and (max-width: 1280px)  {
    .news-section .img-div img {
        width: 100%;
        min-width:56px;
        height: auto;
        min-height:56px;
    }
    
    /* .news-section .botm-border {
        position: relative;
        padding-bottom: 10px;
    } */
    
    .news-section .botm-border::after {
       width: 100%;
        /* min-width: 251.3px; */
        top: 80%;
        left: 2%;
        left: 0%;
        z-index: 2;
    }
    
    .news-section .news-main-heading {
        font-size: 42px;
        line-height:  1.1em;
    }
    
    .news-section .blog-content p ,
    .news-section .blog-content  h4 ,
    .news-section .blog-content  ul li {
        font-size: 15.4px;
        line-height: 23.1px;
    }
    
    .news-section .blog-content h2 ,  .news-section .blog-content h3  {
        font-size: 28px;
        line-height: 41.86px;
    }
    
    .news-section .paddingright {
        padding-right: 10px;
    }
    .news-section .paddingleft{
        padding-left: 20px;

    }
    .news-section .paddingleft2 {
        padding-left: 30px;
    
    }
    
    .news-section .latest-news {
        font-size: 16.8px;
        line-height: 16.66px;
    }
    
    .news-section .tab-div h4 {
        font-size: 14px;
        line-height: 14.7px;
    }
    
    .news-section .side-para {
        font-size: 12.6px;
        line-height: 16.8px;
    
    }
}
@media  (min-width: 1280px)  and (max-width: 1360px) {
    .news-section .img-div img {
        width: 100%;
        min-width:60px;
        height: auto;
        min-height:60px;
    }
    
    /* .news-section .botm-border {
        position: relative;
        padding-bottom: 10px;
    } */
    
    .news-section .botm-border::after {
        width: 95%;
        /* min-width: 269.25px; */
        top: 80%;
        left: 2%;
        left: 0%;
        z-index: 2;
    }
    
    .news-section .news-main-heading {
        font-size: 45px;
        line-height:  1.1em;
    }
    
    .news-section .blog-content p ,
    .news-section .blog-content  h4,
    .news-section .blog-content  ul li  {
        font-size: 16.5px;
        line-height: 24.75px;
    }
    
    .news-section .blog-content h2 ,  .news-section .blog-content h3  {
        font-size: 30px;
        line-height: 44.85px;
    }
    
    .news-section .paddingright {
        padding-right: 35px;
    }
    .news-section .paddingleft{
        padding-left: 35px;

    }
    .news-section .paddingleft2 {
        padding-left: 30px;
    
    }
    
    .news-section .latest-news {
        font-size: 18px;
        line-height: 17.85px;
    }
    
    .news-section .tab-div h4 {
        font-size: 15px;
        line-height:15.75px;
    }
    
    .news-section .side-para {
        font-size: 13.5px;
        line-height: 18px;
    
    }
}
@media  (min-width: 1360px)  and (max-width: 1440px) {
    .news-section .img-div img {
        width: 100%;
        min-width:64px;
        height: auto;
        min-height:64px;
    }
    
    /* .news-section .botm-border {
        position: relative;
        padding-bottom: 10px;
    } */
    
    .news-section .botm-border::after {
        width: 100%;
        /* min-width: 280.2px; */
        top: 80%;
        left: 2%;
        left: 0%;
        z-index: 2;
    }
    
    .news-section .news-main-heading {
        font-size: 48px;
        line-height:  1.1em;
    }
    
    .news-section .blog-content p ,
    .news-section .blog-content  ul li,
    .news-section .blog-content  h4  {
        font-size: 17.6px;
        line-height: 26.4px;
    }
    
    .news-section .blog-content h2 ,  .news-section .blog-content h3  {
        font-size: 32px;
        line-height: 47.84px;
    }
    
    .news-section .paddingright {
        padding-right: 35px;
    }
    .news-section .paddingleft{
        padding-left: 35px;

    }
    .news-section .paddingleft2 {
        padding-left: 30px;
    
    }
    
    .news-section .latest-news {
        font-size: 19.2px;
        line-height: 19.04px;
    }
    
    .news-section .tab-div h4 {
        font-size: 16px;
        line-height:16.8px;
    }
    
    .news-section .side-para {
        font-size: 14.4px;
        line-height: 19.2px;
    
    }
}
@media  (min-width: 1440px)  and (max-width: 1680px) {
    .news-section .img-div img {
        width: 100%;
        min-width:68px;
        height: auto;
        min-height:68px;
    }
    
    /* .news-section .botm-border {
        position: relative;
        padding-bottom: 10px;
    } */
    
    .news-section .botm-border::after {
        width: 100%;
        /* min-width: 280.2px; */
        top: 82%;
        left: 2%;
        left: 0%;
        z-index: 2;
    }
    
    .news-section .news-main-heading {
        font-size: 51px;
        line-height:  1.1em;
    }
    
    .news-section .blog-content p ,
    .news-section .blog-content  h4 ,
    .news-section .blog-content  ul li {
        font-size: 18.7px;
        line-height: 28.05px;
    }
    
    .news-section .blog-content h2 ,  .news-section .blog-content h3  {
        font-size: 34px;
        line-height: 50.83px;
    }
    
    .news-section .paddingright {
        padding-right: 35px;
    }
    .news-section .paddingleft{
        padding-left: 40px;

    }
    .news-section .paddingleft2 {
        padding-left: 30px;
    
    }
    
    .news-section .latest-news {
        font-size:20.4px;
        line-height: 20.23px;
    }
    
    .news-section .tab-div h4 {
        font-size: 17px;
        line-height:17.85px;
    }
    
    .news-section .side-para {
        font-size: 15.3px;
        line-height: 20.4px;
    
    }
}
@media  (min-width: 1680px)  and (max-width: 1919px){
    .news-section .img-div img {
        width: 100%;
        min-width:72px;
        height: auto;
        min-height:72px;
    }
    
    /* .news-section .botm-border {
        position: relative;
        padding-bottom: 10px;
    } */
    
    .news-section .botm-border::after {
        width: 100%;
        /* min-width: 280.2px;   */
        top: 82%;
        left: 2%;
        left: 0%;
        z-index: 2;
    }
    
    .news-section .news-main-heading {
        font-size: 54px;
        line-height: 1.1em;
    }
    
    .news-section .blog-content p ,
    .news-section .blog-content  ul li,
    .news-section .blog-content  h4  {
        font-size: 19.8px;
        line-height: 29.7px;
    }
    
    .news-section .blog-content h2 ,  .news-section .blog-content h3  {
        font-size: 36px;
        line-height: 53.82px;
    }
    
    .news-section .paddingright {
        padding-right: 35px;
    }
    .news-section .paddingleft{
        padding-left: 40px;

    }
    .news-section .paddingleft2 {
        padding-left: 30px;
    
    }
    
    .news-section .latest-news {
        font-size:21.6px;
        line-height: 21.42px;
    }
    
    .news-section .tab-div h4 {
        font-size: 18px;
        line-height:18.9px;
    }
    
    .news-section .side-para {
        font-size: 16.2px;
        line-height: 21.6px;
    
    }
}
@media only screen  and (min-width: 1920px) {}


@media only screen  and (min-width: 1680px) {

    .news-section .container {
        max-width: 1540px;
    }
}