header.header-terms {
    width: 100%;
    min-height: 400px;
    position: relative;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 5.67%, rgba(0, 0, 0, 0.488542) 10.88%, rgba(0, 0, 0, 0) 30.38%);
    background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%); 
    background: url('../images/header-casestudy.webp') no-repeat top;
    background: linear-gradient(172deg, #041E30 0%, #000 100%);
    background-position: top left;
    background-size: cover;
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


header.header-terms .animation-gradient {
    width: 100%;
    position: absolute; 
    object-fit: cover;
    height: 100vh;
    z-index: 1;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 200% 200%;
    opacity: 0.7;
	animation: gradient 7s ease-in infinite;
	height: 100vh;
}

header.header-terms .animation-svg {
    width: 100%;
    position: absolute;
    object-fit: cover;
    height: 100vh;
    z-index: 1;
    opacity: 0.7;
}

header.header-terms .animation-svg svg {
    max-height: 100vh;
    height: 100%;
    width: 100%;
}

header.header-terms .hero {
    position: relative;
    z-index: 2;
    min-height: 400px;
    align-items: flex-end;
    display: flex;
    padding-bottom: 40px;

}

header.header-terms .hero-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%); */
}

header.header-terms .hero-text {
    padding-left: 5px;
    /* border-left:5px solid #F11576; */
    position: relative;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}

header.header-terms .hero-text:before {
    content: "";
    width: 6px;
    background: #F11576;
    height: 92%;
    position: absolute;
    top: 2%;
    left: -10px;
}

header.header-terms .hero-text h4 { 
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 69px;
    /* font-size: 40px !important;
    line-height: 97.99% !important; */
    letter-spacing: 1px;
    color: #FFFFFF;
}

header.header-terms .hero-text h3 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 97.99%;
    /* font-size: 80px !important;
    line-height: 97.99% !important; */
    letter-spacing: 1px;
    color: #F9F5AA;
}

header.header-terms .hero-text h2 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 118px;
    /* font-size: 80px !important;
    line-height: 97.99% !important; */
    letter-spacing: 1px;
    color: #F9F5AA;
    color:#ffffff;
}

header.header-terms .navbar-nav a {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.393478px;
    color: #FFFFFF;
}



/* For 480 Resolution */  


@media only screen and (min-device-width : 320px) and (max-device-width : 480px)  { 
    
    header.header-terms {
        /* background-position: 40%; */
        min-height: 200px;
    }
    
    /* header.header-terms .hero-content {
        top:35%;
    } */

    header.header-terms .hero {
        min-height: 210px;    
    }

    header.header-terms .animation-svg svg {
        height: 100%;
    }

    header.header-terms .hero-text {
        max-width: 350px;
    }


    header.header-terms .hero-text h2 {
        font-size: 21px !important;
        line-height: 21px !important;
        color: #ffffff;
    }


    header.header-terms .hero-text:before {
        width: 4px;
        left: -4px;
        height: 90%;
        top: 0%;
    }
} 


@media only screen and (min-device-width : 480px) and (max-device-width : 767px)  { 
    
    header.header-terms {
        /* background-position: 40%; */
        min-height: 200px;
    }
    
    /* header.header-terms .hero-content {
        top:35%;
    } */

    header.header-terms .hero {
        min-height: 250px;    
    }

    header.header-terms .animation-svg svg {
        height: 100%;
    }

    header.header-terms .hero-text {
        max-width: 400px;
    }


    header.header-terms .hero-text h2 {
        font-size: 30px !important;
        line-height: 30px !important;
        color: #ffffff;
    }

    header.header-terms .hero-text:before {
        width: 4px;
        left: -4px;
        height: 90%;
        top: 0%;
    }
} 


@media only screen and (min-device-width : 768px) and (max-device-width : 1023px)  { 
    
    header.header-terms {
        min-height: 200px;
        align-items: flex-start;
    }
    
    /* header.header-terms .hero-content {
        top:35%;
    } */

    header.header-terms .hero {
        min-height: 250px;    
    }

    header.header-terms .animation-svg svg {
        height: 100%;
    }

    header.header-terms .hero-text {
        max-width: 680px;
    }
    header.header-terms .hero-text h4 {
        font-size: 16px !important;
        line-height: 20px !important;
    }


    header.header-terms .hero-text h2 {
        font-size: 65px;
        line-height: 72px;
        color: #ffffff;
    }

    header.header-terms .hero-text:before {
        width: 4px;
        left: -4px;
        height: 90%;
        top: 0%;
    }
} 


@media  (min-width: 1024px)  and (max-width: 1280px)  
{ 
    /* 84 */

    header.header-terms {
        min-height: 350px;    
    }

    header.header-terms .hero {
        min-height: 350px;    
    }
    

    header.header-terms .hero-text h2 {
        font-size: 84px;
        line-height: 82.6px;
    }

    header.header-terms .hero-text h4 {
        font-size: 42px;
        line-height: 48.3px;
    } 
        
}  


@media  (min-width: 1280px)  and (max-width: 1360px)  
{ 

    header.header-terms {
        min-height: 400px;    
    }

    header.header-terms .hero {
        min-height: 400px;    
    }
    

    /* 90 */
    header.header-terms .hero-text h2 {
        font-size: 90px;
        line-height: 97.99%;
    }

    header.header-terms .hero-text h4 {
        font-size: 45px;
        line-height: 51.7px;
    } 

}  

@media  (min-width: 1360px)  and (max-width: 1440px)  
{  
    /* 96 */ 
    header.header-terms .hero-text h2 {
        font-size: 96px;
        line-height: 97.99%;
    }

    header.header-terms .hero-text h4 {
        font-size: 48px;
        line-height: 55.2px;
    } 

}  

@media  (min-width: 1440px)  and (max-width: 1680px)  
{  
    /* 102 */
    header.header-terms .hero-text h2 {
        font-size: 102px;
        line-height: 97.99%;
    }


    header.header-terms .hero-text h4 {
        font-size: 51px;
        line-height: 58.65px;
    } 

}  

@media  (min-width: 1680px)  and (max-width: 1919px)  
{ 
    /* 108 */
    header.header-terms .hero-text h2 {
        font-size: 108px;
        line-height: 97.99%;
    }

    header.header-terms .hero-text h4 {
        font-size: 54px;
        line-height: 61.2px;
    } 


}  

@media only screen  and (min-width: 1920px) {



} 