.lifeat-ps-section-main {
  background: linear-gradient(121.92deg, #041e30 13.01%, #000000 74.52%);
  padding: 94px 0px;
  font-family: Helvetica !important;
}

.lifeat-ps-section-main .scroll-section {
  height: auto;
}

.life-preffered-square-section {
  margin: 20px 0px 11px 0px !important;
}

.game-on-section-2section {
  display: none;
}

.ethos-heading-stroke h4 {
  /* font-family: 'Work Sans', sans-serif !important;  */
  text-align: center;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #2a5450;
  -webkit-text-stroke: 1px #2a5450;
  font-weight: 700;
  font-size: 100px;
  line-height: 180px;
}

.ethos-heading-stroke {
  position: absolute;
  top: -80px;
  left: 80px;
}

.left-content-ethos .ethos-of-tagline-text h2 {
  color: #fff;
  font-size: 40px !important;
  font-weight: 400;
  z-index: 9;
}

.left-content-ethos .animator-underline h2 {
  color: #fff;
  font-size: 70px;
  font-weight: 700;
  text-transform: capitalize;
}

.left-content-ethos .ethos-of-tagline-text h2 {
  position: relative;
  z-index: 9;
}

.animator-underline span {
  color: White;
  display: inline-block;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  vertical-align: bottom;
}

.about-us-accord-button.text-center button::after {
  display: none;
}

.animator-underline span:after {
  background: linear-gradient(
      to bottom,
      rgba(20, 20, 20, 0.8),
      rgba(20, 20, 20, 0.8)
    )
    center 1.08em/100% 5px no-repeat;
  content: "";
  height: 100%;
  right: 0;
  position: absolute;
  top: 0px;
  width: 400%;
  will-change: transform;
  z-index: 1;
  -webkit-animation: underline-gradient 6s linear infinite;
  animation: underline-gradient 6s linear infinite;
  background-image: linear-gradient(
    270deg,
    rgba(241, 21, 118, 1) 0%,
    rgba(1, 198, 178, 1) 20%,
    rgba(241, 21, 118, 1) 40%,
    rgba(1, 198, 178, 1) 60%,
    rgba(241, 21, 118, 1) 80%,
    rgba(1, 198, 178, 1) 100%
  );
}

.animator-underline {
  position: relative;
  z-index: 9;
}

.right-side-accordion-content .accordion-item,
.right-side-accordion-content .accordion-item {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  background: none;
  border: none;
}

.right-side-accordion-content .accordion-item button::after {
  display: none;
}

.right-side-accordion-content
  .accordion-item
  button.accordion-button.collapsed {
  height: 220px;
  display: flex;
  align-items: flex-start;
  transition: all 0.5s;
}

.right-side-accordion-content
  .accordion-item
  button.accordion-button.collapsed::before {
  opacity: 0;
}

.right-side-accordion-content .accordion-item button.accordion-button {
  height: 140px;
  transition: all 0.5s;
  display: flex;
  align-items: flex-start;
}

.right-side-accordion-content .accordion-item button.accordion-button::before {
  position: absolute;
  left: 0px;
  bottom: 0px;
  content: "";
  width: 50%;
  height: 70%;
  border-bottom: 3px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: linear-gradient(to right, #fe54bb, transparent);
  opacity: 1;
  transition-duration: 0.5s;
  display: none;
}

.right-side-accordion-content
  .accordion-item
  button.accordion-button.collapsed
  .border-divver::after {
  opacity: 0;
}

.right-side-accordion-content .accordion-item button .border-divver::after {
  position: absolute;
  left: 0px;
  bottom: 0px;
  content: "";
  width: 50%;
  height: 70%;
  border-left: 3px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: linear-gradient(to top, #fe54bb, transparent);
  opacity: 1;
  transition-duration: 0.5s;
  display: none;
}

.right-side-accordion-content .accordion-body {
  position: relative;
}

.right-side-accordion-content .accordion-body .bottom-pink-border-div::before {
  position: absolute;
  left: 0px;
  bottom: 0px;
  content: "";
  width: 70%;
  height: 80%;
  border-bottom: 3px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: linear-gradient(to right, #fe54bb, transparent);
}

.right-side-accordion-content .accordion-body .bottom-pink-border-div::after {
  position: absolute;
  left: 0px;
  bottom: 0px;
  content: "";
  width: 50%;
  height: 80%;
  border-left: 3px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: linear-gradient(to top, #fe54bb, transparent);
}

.right-side-accordion-content .accordion-item .green-border-diver::after {
  position: absolute;
  background: linear-gradient(0deg, #fe54bb, transparent);
  right: 0px;
  top: 0px;
  content: "";
  width: fit-content;
  height: 50%;
  border-right: 3px solid;
  border-image-slice: 1;
  border-width: 3px;
  z-index: 99999;
  border-image-source: linear-gradient(to bottom, #01c6b2, transparent);
}

.right-side-accordion-content .accordion-item {
  position: relative;
}

.right-side-accordion-content .accordion-item .green-border-diver::before {
  position: absolute;
  right: 0px;
  top: 0px;
  content: "";
  width: 50%;
  height: fit-content;
  border-top: 3px solid;
  border-image-slice: 1;
  border-width: 3px;
  z-index: 99;
  border-image-source: linear-gradient(to left, #01c6b2, transparent);
}

.right-side-accordion-content .accordion h2.accordion-header {
  position: relative;
  margin-bottom: 3px;
}

.right-side-accordion-content .accordion-header .accordion-button {
  border-radius: 0px !important;
  position: relative;
  box-shadow: none !important;
  padding: 0px !important;
  z-index: 9;
  background: transparent;
}

.accord-head-section {
  padding: 28px !important;
  position: relative;
  z-index: 9998;
  width: 100% !important;
  /* background: #000 !important; */
  z-index: 99999;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.56) 0%,
    #000 75.14%
  ) !important;
}

.accord-head-section::after {
  position: absolute;
  left: 0px;
  bottom: 0px;
  content: "";
  width: fit-content;
  height: 80%;
  border-left: 3px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: linear-gradient(to top, #fe54bb, transparent);
}

.accord-head-section::before {
  position: absolute;
  left: 0px;
  bottom: 0px;
  content: "";
  width: 70%;
  height: fit-content;
  border-bottom: 3px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: linear-gradient(to right, #fe54bb, transparent);
}

.right-side-accordion-content .accordion-item .accordion-body {
  padding: 12px !important;
}

.accord-itemer-one {
  background: url("../images/aboutus-accord.webp") !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.accord-head-section h2 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  line-height: 36.4px;
}

.accord-head-section p {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  text-transform: capitalize;
  text-decoration: underline;
  text-underline-offset: 5px;
}

.about-us-accord-button button {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  background: linear-gradient(180deg, #01c6b2 0%, #048d7f 98.44%);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 15px 20px;
}

.preffered-list-header h2,
.adventure-head-sec h2,
.rainmakers-list-header h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: 43.2px;
}

.adventure-head-sec,
.rainmakers-list-header {
  margin: 40px 0px 20px 0px;
}

.preffered-list-header {
  margin: 16px 0px 23px 0px;
}

.second-accord-image-section img,
.ps-travel-pics-inner .row .col-md-3 img,
.catalysts-images-section img,
.ps-dairy-rows-section .about-sec-image img {
  width: 100%;
  border-radius: 6px !important;
}

.second-accord-image-section {
  position: relative;
}

.second-accord-image-section h2 {
  position: absolute;
  bottom: 20px;
  color: #fff;
  text-align: center;
  font-size: 17.846px;
  font-weight: 600;
  text-transform: capitalize;
  width: 100%;
}

.hybrid-texter-section h2,
.game-on-header h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: 43.2px;
}

.hybrid-texter-section p {
  font-size: 18px;
  color: #fff;
  font-weight: 300;
}

.work-hard-section {
  margin-top: 23px;
}

.hybrid-texter-section {
  margin-top: 48px !important;
}

.section-3-accord-inner .row .col-md-6,
.ps-travel-pics-inner .row .col-md-3,
.accordion-pics-section .row .col-md-3,
.cata-lists-images .row .col-md-6 {
  padding: 0px 5px;
}

.section-3-accord-inner .row .col-md-6 img,
.game-on-section img,
.game-on-section-2section img,
.adventure-image-sec img {
  border-radius: 6.84px;
}

.accord-2-section-3-images {
  margin-top: 25px;
}

.game-on-section,
.game-on-section-2section {
  margin-top: 40px;
}

.game-on-header {
  margin-bottom: 20px;
}

.accord-itemer-two {
  background: url("../images/accordion-2-bg.webp") !important;
}

.accord-itemer-three {
  background: url("../images/accord-3-bg.webp") !important;
}

.accord-itemer-four {
  background: url("../images/accord-4-bg.webp") !important;
}

.accord-itemer-five {
  background: url("../images/accord-5-bg.webp") !important;
}

.accord-itemer-six {
  background: url("../images/accord-6-bg.webp") !important;
}

.bg-properties-class-user {
  background-size: cover !important;
  background-position: bottom !important;
  background-repeat: no-repeat !important;
}

.ps-travel-accord-body {
  padding: 10px 30px 19px 30px;
}

.accordion-inner-body-section {
  padding: 20px;
}

.ps-travel-para p {
  font-size: 18px;
  font-weight: 400 !important;
  color: #fff;
  line-height: 23.4px !important;
}

.cata-lists-images {
  margin-top: 17px;
}

@font-face {
  font-family: "Helvetica";
  font-weight: 400;
  src: local("Helvetica"), url(../font/FreeSans.otf) format("truetype");
}

.para-11 p {
  font-size: 60px;
  line-height: 78px;
  font-weight: 700;
}

.citis-texter {
  margin-left: 10px; 
}

.citis-texter p {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
}

.about-us-accord-button.text-center button::after {
  display: none;
}

.about-us-accord-button.text-center button::before {
  display: none;
}

.anime-spanner-top span {
  color: White;
  display: inline-block;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  vertical-align: bottom;
}

.anime-spanner-top span:after {
  background: linear-gradient(
      to bottom,
      rgba(20, 20, 20, 0.8),
      rgba(20, 20, 20, 0.8)
    )
    center 1.08em/100% 5px no-repeat;
  content: "";
  height: 100%;
  right: 0;
  position: absolute;
  top: 0px;
  width: 400%;
  will-change: transform;
  z-index: 1;
  -webkit-animation: underline-gradient 6s linear infinite;
  animation: underline-gradient 6s linear infinite;
  background-image: linear-gradient(
    270deg,
    rgba(241, 21, 118, 1) 0%,
    rgba(1, 198, 178, 1) 20%,
    rgba(241, 21, 118, 1) 40%,
    rgba(1, 198, 178, 1) 60%,
    rgba(241, 21, 118, 1) 80%,
    rgba(1, 198, 178, 1) 100%
  );
}

.vertical-spanner {
  transform: rotate(-90deg);
  height: 30px;
  width: 56px;
}

.ps-travel-pics {
  margin: 20px 0px 23px 0px;
}

.master-class-headers h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 43px;
}

.master-class-headers {
  margin-bottom: 23px;
}

.about-us-accord-button {
  position: relative;
  z-index: 2;
}

.about-admin-para-sec p {
  font-size: 12px;
  text-align: justify;
  font-weight: 300;
  line-height: 18px;
}

.bottom-details-sec {
  margin-top: 20px;
}

.bottom-details-sec p span {
  font-size: 18px;
  font-weight: 700;
}

.bottom-details-sec p {
  font-size: 14px;
}

.bottom-details-sec p.small-text-bottom {
  font-size: 12px;
  margin-top: 4px !important;
}

.middle-row-section-admin {
  margin: 71px calc(var(--bs-gutter-x)*-.5);
}

.bg-properties-class-user {
  margin-bottom: 27px;
}

.right-side-accordion-inner .bg-properties-class-user:last-child {
  margin-bottom: 0px !important;
}

.before-992-content-accordion-main {
  display: block;
}

.after-992-content-accordion-main {
  display: none;
}

.lifeat-ps-section-main {
  background: linear-gradient(121.92deg, #041e30 13.01%, #000000 74.52%);
  padding: 125px 0px 108px 0px;
  font-family: Helvetica !important;
}

.life-left-job-open-column-inner .life-latest-p-section p {
  font-size: 40px;
  font-weight: 400;
  line-height: normal;
  padding-left: 10px !important;
}

.life-latest-p-section {
  position: relative;
}

.life-bottom-jobber-section {
  display: block;
}

.life-bottom-jobber-section-mobile-viewer {
  display: none;
}

.life-left-job-open-column-inner .life-bottom-jobber-section h2 {
  font-size: 80px;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  gap: 15px;
}

.life-left-job-open-column-inner .life-job-absolute-text p {
  font-family: "Work Sans", sans-serif !important;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #2a5450;
  -webkit-text-stroke: 1px #2a5450;
  font-size: 160px;
  line-height: 180px;
  font-weight: 700;
}

.life-job-absolute-text p {
  width: fit-content;
  position: relative;
}

/* .life-job-absolute-text p::after {
  content: "";
  width: 100%;
  height: 8px;
  position: absolute;
  bottom: 5px;
  left: 0;
  border: 1px solid #2a5450;
} */

.life-job-absolute-text {
  position: absolute;
  top: -70px;
  right: 30px;
}

.life-bottom-jobber-section {
  position: relative;
  z-index: 99;
}

.life-bottom-jobber-section h2 span {
  display: inline-block;
  overflow: hidden;
  position: relative;
  padding-bottom: 15px !important;
}

.life-bottom-jobber-section h2 span::after {
  background: linear-gradient(
      to bottom,
      rgba(20, 20, 20, 0.8),
      rgba(20, 20, 20, 0.8)
    )
    center 1.08em/100% 5px no-repeat;
  content: "";
  height: 100%;
  right: 0;
  position: absolute;
  top: 15px;
  width: 400%;
  will-change: transform;
  z-index: 1;
  -webkit-animation: underline-gradient 6s linear infinite;
  animation: underline-gradient 6s linear infinite;
  background-image: linear-gradient(
    270deg,
    rgba(241, 21, 118, 1) 0%,
    rgba(1, 198, 178, 1) 20%,
    rgba(241, 21, 118, 1) 40%,
    rgba(1, 198, 178, 1) 60%,
    rgba(241, 21, 118, 1) 80%,
    rgba(1, 198, 178, 1) 100%
  );
}

.after-992-life-at-ps-container {
  display: none;
}

.before-992-life-at-ps-container {
  display: block;
}



@media only screen and (max-width:1200px) {
  .left-job-open-column-inner .bottom-jobber-section h2 {
    font-size: 70px !important;
  }
  .life-left-job-open-column-inner .life-latest-p-section p {
    padding-left: 0px !important;
  }
}

@media only screen and (max-width: 1110px) {
  .life-job-absolute-text {
    position: absolute;
    top: -70px;
    right: -20px;
  }
}

@media only screen and (max-width: 991px) {
  .life-at-preffer-row-section .col-md-5,
  .life-at-preffer-row-section .col-md-7 {
    width: 100% !important;
  }
  .hybrid-texter-section {
    margin-top: 45px !important;
  }
  .life-left-job-open-column-inner .life-latest-p-section p {
    padding-left: 0px !important;
  }

  .left-job-open-column-inner .bottom-jobber-section h2 {
    font-size: 80px !important;
  }

  .life-left-side-openings-header {
    margin-bottom: 40px;
  }

  .before-992-content-accordion-main {
    display: none;
  }

  .after-992-content-accordion-main {
    display: block;
  }

  .right-side-accordion-content
    .accordion-item
    button.accordion-button.collapsed {
    height: 100% !important;
  }

  .right-side-accordion-content
    .accordion-item
    button.accordion-button.collapsed::before {
    opacity: 0;
  }

  .right-side-accordion-content .accordion-item button.accordion-button {
    height: 100% !important;
  }

  .right-side-accordion-content
    .accordion-item
    button.accordion-button::before {
    display: none;
  }

  .right-side-accordion-content .accordion-item .border-divver::after {
    display: none;
  }

  .right-side-accordion-content .accordion-body .bottom-pink-border-div::after,
  .right-side-accordion-content
    .accordion-body
    .bottom-pink-border-div::before {
    display: none;
  }

  .right-side-accordion-content .accordion-item .green-border-diver::before,
  .right-side-accordion-content .accordion-item .green-border-diver::after {
    display: none;
  }

  .after-992-green-topper .inner-after-992-greener::after {
    position: absolute;
    background: linear-gradient(0deg, #fe54bb, transparent);
    right: 0px;
    top: 0px;
    content: "";
    width: fit-content;
    height: 70%;
    border-right: 3px solid;
    border-image-slice: 1;
    border-width: 3px;
    z-index: 99999;
    border-image-source: linear-gradient(to bottom, #01c6b2, transparent);
  }

  .after-992-green-topper .inner-after-992-greener {
    position: relative;
    height: 100% !important;
  }

  .after-992-green-topper {
    position: absolute;
    height: 100% !important;
    width: 100%;
    top: 0;
    right: 0;
  }

  .after-992-green-topper .inner-after-992-greener::before {
    position: absolute;
    right: 0px;
    top: 0px;
    content: "";
    width: 50%;
    height: fit-content;
    border-top: 3px solid;
    border-image-slice: 1;
    border-width: 3px;
    z-index: 99;
    border-image-source: linear-gradient(to left, #01c6b2, transparent);
  }

  .after-arrow-image-section {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .inner-arrow-image-section {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .inner-arrow-image-section::after {
    position: absolute;
    content: url("../images/downarrow.svg");
    top: 27px;
    right: 14px;
    height: 12px;
    width: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 0.3s;
    transform-origin: center;
  }

  .accordion-button .inner-arrow-image-section::after {
    transform: rotate(0deg);
  }

  .accordion-button.collapsed .inner-arrow-image-section::after {
    transform: rotate(180deg);
  }

  .after-992-life-at-ps-container {
    display: block;
  }

  .before-992-life-at-ps-container {
    display: none;
  }
}

.life-bottom-jobber-section-mobile-viewer {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .bg-properties-class-user {
    margin-bottom: 40px;
  }

  .life-left-side-openings-header {
    padding: 0px 20px;
  }

  .life-job-absolute-text p::after {
    display: none !important;
  }

  .animator-underline h2 {
    margin: 0px !important;
  }

  .life-bottom-jobber-section-mobile-viewer .life-animator-we-bottom-767-after {
    position: relative;
    width: 100%;
    max-width: 200px;
    margin: -20px 200px 0px auto;
    height: 25px;
    overflow: hidden;
  }

  .life-bottom-jobber-section-mobile-viewer
    .life-animator-we-bottom-767-after::after {
    background: linear-gradient(
        to bottom,
        rgba(20, 20, 20, 0.8),
        rgba(20, 20, 20, 0.8)
      )
      center 1.08em/100% 5px no-repeat;
    content: "";
    height: 100%;
    right: 0;
    position: absolute;
    top: 0px;
    width: 400%;
    will-change: transform;
    z-index: 1;
    -webkit-animation: underline-gradient 6s linear infinite;
    animation: underline-gradient 6s linear infinite;
    background-image: linear-gradient(
      270deg,
      rgba(241, 21, 118, 1) 0%,
      rgba(1, 198, 178, 1) 20%,
      rgba(241, 21, 118, 1) 40%,
      rgba(1, 198, 178, 1) 60%,
      rgba(241, 21, 118, 1) 80%,
      rgba(1, 198, 178, 1) 100%
    );
  }

  .life-job-absolute-text p {
    font-size: 120px !important;
    margin-bottom: 0px !important;
  }

  .life-at-ps-top-section .after-767-displayer-animator {
    margin-top: -20px;
  }

  .life-bottom-jobber-section-mobile-viewer h2 {
    font-size: 70px;
    font-weight: 700;
  }

  .life-left-job-open-column-inner .life-latest-p-section p {
    font-size: 30px;
  }

  .after-767-anime-inner {
    position: relative;
    width: 130px;
    margin-right: 0px !important;
    height: 20px;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    vertical-align: bottom;
  }

  .after-767-anime-inner::after {
    content: "";
    background: linear-gradient(
        to bottom,
        rgba(20, 20, 20, 0.8),
        rgba(20, 20, 20, 0.8)
      )
      center 1.08em/100% 5px no-repeat;
    content: "";
    height: 100%;
    right: 0;
    position: absolute;
    top: 0;
    width: 400%;
    will-change: transform;
    z-index: 1;
    -webkit-animation: underline-gradient 6s linear infinite;
    animation: underline-gradient 6s linear infinite;
    background-image: linear-gradient(
      270deg,
      rgba(241, 21, 118, 1) 0%,
      rgba(1, 198, 178, 1) 20%,
      rgba(241, 21, 118, 1) 40%,
      rgba(1, 198, 178, 1) 60%,
      rgba(241, 21, 118, 1) 80%,
      rgba(1, 198, 178, 1) 100%
    );
  }

  .animator-underline span:after {
    display: none;
  }

  .careerjob-section .row {
    margin: 0px auto !important;
  }

  .section-3-accord-inner .row {
    gap: 15px;
  }

  .accord-head-section h2 {
    font-size: 25px !important;
    line-height: normal;
  }

  .accord-head-section p {
    margin: 0px !important;
    font-size: 14px;
  }

  .lifeat-ps-section-main .row {
    gap: 30px 0px;
  }

  .para-11 p {
    font-size: 40px !important;
    line-height: normal !important;
    font-weight: 700 !important;
  }

  .citis-texter p {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }

  .accordion-pics-section .row .col-md-3,
  .ps-travel-pics-inner .row .col-md-3 {
    width: 50% !important;
  }

  .accordion-pics-section .row,
  .ps-travel-pics-inner .row,
  .cata-lists-images .row {
    gap: 15px 0px !important;
  }

  .ps-dairy-rows-section.admins-sec .row {
    gap: 20px 0px;
  }

  .lifeat-ps-section-main {
    padding: 70px 0px !important;
  }

  /* .right-side-accordion-content .accordion-item button::after {
        display: block;
        color: #fff !important;
        content: url('../images/downarrow.svg');
        background-image: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
    } */
  .life-bottom-jobber-section {
    display: none;
  }

  .life-bottom-jobber-section-mobile-viewer {
    display: block;
  }
}

.life-at-section-heading-tag {
  position: relative;
  z-index: 999;
}

@media only screen and (max-width: 674px) {
  .animator-underline h2 {
    font-size: 60px !important;
  }

  .lifeat-ps-section-main .ethos-of-tagline-text h2 {
    font-size: 40px !important;
  }

  .ethos-heading-stroke h4 {
    font-size: 100px !important;
    line-height: 160px;
  }

  .ethos-heading-stroke {
    top: -60px !important;
  }

  .lifeat-ps-section-main {
    padding: 60px 0px !important;
  }
}

@media only screen and (max-width: 640px) {
  .animator-underline h2 {
    font-size: 40px !important;
    left: 0px !important;
    transform: none !important;
  }

  .lifeat-ps-section-main .ethos-of-tagline-text h2 {
    font-size: 40px !important;
    color: #fff !important;
    left: 0px !important;
    transform: none !important;
  }

  .ethos-heading-stroke h4 {
    font-size: 100px !important;
    line-height: 160px;
  }

  .ethos-heading-stroke {
    top: -60px !important;
  }

  .careerjob-section .content-box {
    padding-right: 0px !important;
  }

  /* .lifeat-ps-section-main {
        padding: 40px 0px !important; 
      } */
}

@media only screen and (max-width: 574px) {
  .ps-travel-para p {
    font-size: 14px;
    line-height: normal !important;
  }
  .second-accord-image-section h2 {
    font-size: 14px;
  }
  .hybrid-texter-section h2,
  .game-on-header h2 {
    font-size: 16px;
    line-height: 28.8px;
  }
  .vertical-spanner {
    transform: none;
  }
  .hybrid-texter-section p {
    font-size: 14px;
    line-height: 33px;
  }

  .ps-calc-inner {
    display: block !important;
  }

  .para-11 p {
    font-size: 50px !important;
  }

  .citis-texter p {
    font-size: 16px !important;
  }

  .preffered-list-header h2,
  .adventure-head-sec h2,
  .rainmakers-list-header h2 {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 28.8px;
  }
}

@media only screen and (max-width: 474px) {
  .accord-head-section h2 {
    font-size: 18px !important;
    line-height: normal;
  }

  .accordion-pics-section .row {
    gap: 15px 0px !important;
  }

  .ps-travel-pics .row {
    gap: 15px 0px !important;
  }

  .cata-lists-images .row {
    gap: 15px 0px !important;
  }

  .ps-dairy-rows-section.admins-sec .row {
    gap: 15px 0px !important;
  }

  .game-on-section-2section {
    display: block;
  }

  .game-on-section {
    display: none;
  }

  .accordion-pics-section .row {
    gap: 15px 0px !important;
  }

  .accordion-inner-body-section .section-3-accord-inner .row {
    gap: 15px 0px !important;
  }

  .scroll-section.career-page-scroll-sectioner .row {
    gap: 30px 0px !important;
  }

  .life-at-ps-section-after-767-sectioner .after-767-anime-inner {
    margin-right: 60px !important;
  }

  .lifeat-ps-section-main .ethos-of-tagline-text h2 {
    font-size: 28px !important;
    color: #fff !important;
    left: 0px !important;
    transform: none !important;
  }

  .life-at-ps-top-section .left-content-ethos .animator-underline h2 {
    font-size: 48px !important;
    left: 0px !important;
    transform: none !important;
  }

  .accord-head-section p {
    margin: 0px !important;
    font-size: 12px;
  }

  .about-us-accord-button.text-center button {
    font-size: 14px;
  }

  .left-content-ethos .carrer-job-section h2 {
    font-size: 48px !important;
    left: 0px !important;
    transform: none !important;
  }

  .careerjob-section .content-box .career-job-tagliner h2 {
    font-size: 28px !important;
    color: #fff !important;
    left: 0px !important;
    transform: none !important;
  }

  .ethos-heading-stroke h4 {
    font-size: 60px !important;
    line-height: normal !important;
  }

  .ethos-heading-stroke {
    top: -25px !important;
    left: 60px !important;
  }

  .lifeat-ps-section-main {
    padding: 25px 0px 40px 0px !important;
    margin-top: 10px;
  }

  .life-left-job-open-column-inner .life-latest-p-section p {
    font-size: 26px;
    line-height: normal;
    padding-left: 0px !important;
  }

  .life-bottom-jobber-section-mobile-viewer h2 {
    font-size: 50px;
    font-weight: 700;
  }

  .life-job-absolute-text p {
    font-size: 60px !important;
    margin-bottom: 0px !important;
  }

  .life-bottom-jobber-section-mobile-viewer .life-animator-we-bottom-767-after {
    position: relative;
    width: 100%;
    max-width: 125px;
    margin: -20px 180px 0px auto;
    height: 21px;
    overflow: hidden;
  }

  .row.life-at-preffer-row-section .container {
    padding: 0px 20px;
  }

  .life-job-absolute-text {
    position: absolute;
    top: -65px;
    right: 30%;
  }

  .life-at-preffer-row-section {
    gap: 0px !important;
  }
}

@media only screen and (max-width: 400px) {
  .life-bottom-jobber-section-mobile-viewer .life-animator-we-bottom-767-after {
    position: relative;
    width: 100%;
    max-width: 125px;
    margin: -20px 100px 0px auto;
    height: 25px;
    overflow: hidden;
  }
}