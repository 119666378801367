header.header-single {
    width: 100%;
    height: 100%;
    position: relative;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 5.67%, rgba(0, 0, 0, 0.488542) 10.88%, rgba(0, 0, 0, 0) 30.38%);
    background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%); 
    background: url('../images/header-single.webp') no-repeat top;
    background-position: top left;
    background-size: cover;
}

header.header-single .menu {
    position: unset;
}

/* For 480 Resolution */  
@media only screen   
and (min-device-width : 320px)   
and (max-device-width : 768px)  
{ /* STYLES GO HERE */

    header.header-single {
        background-position: 50%;
    }
    
    header.header-single .hero-content {
        top:35%;
    }

} 


/* For 640 Resolution */  
@media only screen   
and (min-device-width : 481px)   
and (max-device-width : 640px)  
{ /* STYLES GO HERE */}  


/* For 1024 Resolution */  
@media only screen   
and (min-device-width : 768px)   
and (max-device-width : 1024px)  
{ /* STYLES GO HERE */} 




@media  (min-width: 1024px)  and (max-width: 1280px)  
{ 
    
}  


@media  (min-width: 1280px)  and (max-width: 1360px)  
{ 

}  

@media  (min-width: 1360px)  and (max-width: 1440px)  
{  

}  

@media  (min-width: 1440px)  and (max-width: 1680px)  
{  

}  

@media  (min-width: 1680px)  and (max-width: 1919px)  
{ 


}  

@media only screen  and (min-width: 1920px) {



} 