.header-JobOpening {
    width: 100%;
    min-height: 50vh;
    position: relative;
    background: linear-gradient(172deg, #041E30 0%, #000 100%);
    /* fill: linear-gradient(172deg, #041E30 0%, #000 100%); */
    /* background-position: top left; */
    /* background-size: cover; */
}
header.header-JobOpening .hero {
    min-height: 50vh;
    position: relative;
    z-index: 2;

}

header.header-JobOpening .hero-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50% , -50%);
}

header.header-JobOpening .hero-text {
    padding-left: 5px;
    /* border-left:5px solid #F11576; */
    position: relative;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}

header.header-JobOpening .hero-text:before {
    content: "";
    width: 6px;
    background: #F11576;
    height: 93%;
    position: absolute;
    top: -5px;
    left: -10px;
}

header.header-JobOpening .hero-text h6{ 
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 43px;
    line-height: 70%;
    letter-spacing: 1px;
    color: #FFFFFF;
}

/* header.header-JobOpening .hero-text h3 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 97.99%;
    letter-spacing: 1px;
    color: #F9F5AA;
} */

header.header-JobOpening .hero-text h2 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 97.99%;
    letter-spacing: 1px;    
    color: #ffffff;
}
/* header.header-JobOpening .hero-text .spanh2{
    color: #F9F5AA;
} */
header.header-JobOpening .navbar-nav a {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.393478px;
    color: #FFFFFF;
}

/* For 480 Resolution */  
@media only screen   
and (min-device-width : 320px)   
and (max-device-width : 768px)  
{ /* STYLES GO HERE */

    header.header-JobOpening {
        /* background: url('../images/header-solution-bg-mb.webp') no-repeat top; */
        background-position: 50%;
        min-height: 35vh;
    }

    header.header-JobOpening .hero {
        min-height: 35vh;    
    }
    
    header.header-JobOpening .hero-content {
        top:65%;
    }

    /* header.header-JobOpening .animation-svg svg {
        height: 100%;
    } */

    header.header-JobOpening .hero-text {
        max-width: 300px;
    }
 
    header.header-JobOpening .hero-text h6 {
        font-size: 16px;
        line-height: 20px;
    }

    header.header-JobOpening .hero-text h2 {
        font-size: 30px;
        line-height: 30px;
    }

    header.header-JobOpening .hero-text:before {
        width: 4px;
        left: -5px;
        top: 2px;
        height: 90%;
    }
} 

/* For 1024 Resolution */  
@media only screen   
and (min-device-width : 768px)   
and (max-device-width : 1024px)  
{ 

    header.header-JobOpening .hero-text {
        max-width: 680px;
    }

    header.header-JobOpening .hero-text {
        margin-left: 5%;
    }

    header.header-JobOpening .hero-text:before {
        top: 0px;
    }

    header.header-JobOpening .hero-text h6 {
        font-size: 40px;
        line-height: 46px;
    }

    header.header-JobOpening .hero-text h2 {
        font-size: 75px;
        line-height: 82px;
    }
} 

/* For 1024 Resolution */ 
@media  (min-width: 1024px)  and (max-width: 1280px)  
{ 
    /* 84 */
    header.header-JobOpening .hero-text {
        max-width: 800px;
        margin: auto;
    }

    header.header-JobOpening .hero-text h2 {
        font-size: 84px;
        line-height: 97.99%;
    }

    header.header-JobOpening .hero-text h6 {
        font-size: 42px;
    } 
        
}  


@media  (min-width: 1280px)  and (max-width: 1360px)  
{ 
    /* 90 */
    header.header-JobOpening .hero-text {
        max-width: 800px;
        margin: auto;
    }

    header.header-JobOpening .hero-text h2 {
        font-size: 90px;
        line-height: 97.99%;
    }

    header.header-JobOpening .hero-text h6 {
        font-size: 45px;
    } 

}  

@media  (min-width: 1360px)  and (max-width: 1440px)  
{  
    /* 96 */ 
    header.header-JobOpening .hero-text {
        max-width: 850px;
        margin: auto;
    }
    
    header.header-JobOpening .hero-text h2 {
        font-size: 96px;
        line-height: 97.99%;
    }

    header.header-JobOpening .hero-text h6 {
        font-size: 48px;
    } 

}  

@media  (min-width: 1440px)  and (max-width: 1680px)  
{  
    /* 102 */
    header.header-JobOpening .hero-text {
        max-width: 900px;
        margin: auto;
    }

    header.header-JobOpening .hero-text h2 {
        font-size: 102px;
        line-height: 97.99%;
    }


    header.header-JobOpening .hero-text h6 {
        font-size: 51px;
    } 

}  

@media  (min-width: 1680px)  and (max-width: 1919px)  
{ 
    /* 108 */
    header.header-JobOpening .hero-text {
        max-width: 900px;
        margin: auto;
    }

    header.header-JobOpening .hero-text h2 {
        font-size: 108px;
        line-height: 97.99%;
    }

    header.header-JobOpening .hero-text h6 {
        font-size: 54px;
    } 


}  

@media only screen  and (min-width: 1920px) {



} 