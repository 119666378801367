header.header-service {
    width: 100%;
    min-height: 100vh;
    position: relative;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 5.67%, rgba(0, 0, 0, 0.488542) 10.88%, rgba(0, 0, 0, 0) 30.38%);
    background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%); 
    background: url('../images/header-service-bg.webp') no-repeat top;
    background-position: top left;
    background-size: cover;
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


header.header-service .animation-gradient {
    width: 100%;
    position: absolute;
    object-fit: cover;
    height: 100vh;
    z-index: 1;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
 	  background-size: 200% 200%;
    opacity: 0.4;
	  animation: gradient 7s ease-in infinite;
	  height: 100vh;
}

header.header-service .animation-svg {
    width: 100%;
    position: absolute;
    object-fit: cover;
    height: 100vh;
    z-index: 1;
    opacity: 0.7;
}

header.header-service .animation-svg svg {
    max-height: 100vh;
    width: 100%;
}

header.header-service .hero {
    min-height: 100vh;
    position: relative;
    z-index: 2;

}

header.header-service .hero-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
}

header.header-service .hero-text {
    padding-left: 5px;
    /* border-left:5px solid #F11576; */
    position: relative;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    min-width: 730px;
    max-width: 950px;

}

header.header-service .hero-text:before {
    content: "";
    width: 6px;
    background: #F11576;
    height: 94%;
    position: absolute;
    top: 2%;
    left: -10px;
}

header.header-service .hero-text h4 { 
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    /* font-size: 40px !important; */
    line-height: 97.99%;
    letter-spacing: 1px;
    color: #FFFFFF;
}

header.header-service .hero-text h3 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    /* font-size: 80px !important; */
    line-height: 97.99%;
    letter-spacing: 1px;
    color: #F9F5AA;
}



header.header-service .hero-text .text1,
header.header-service .hero-text .text2 {
    height: 140px;
    overflow: hidden;
  }
  
header.header-service .hero-text .text1 > div > h3,
header.header-service .hero-text .text2 > div > h2  {
    color: #fff;
    padding: 4px 0px;
    /* height: 45px; */
    margin-bottom: 120px;
    display: inline-block;

    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    /* font-size: 80px !important; */
    line-height: 97.99%;
    letter-spacing: 1px;
    color: #F9F5AA;
}

#flip1 div:first-child {
    animation: show 5s linear infinite;
  }

  
#flip2 div:first-child {
    animation: show 5s linear infinite;
    /* margin-top: -600px; */
   
}

  /* #flip1 div h3 {
    background:#42c58a;
  }
  #flip1 div:first-child h3 {
    background:#4ec7f3;
  }
  #flip1 div:last-child h3 {
    background:#DC143C;
  } */

  /* @keyframes show {
    0% {
      margin-top: -270px;
    }
    5% {
      margin-top: -180px;
    }
    33% {
      margin-top: -180px;
    }
    38% {
      margin-top: -90px;
    }
    66% {
      margin-top: -90px;
    }
    71% {
      margin-top: 0px;
    }
    99.99% {
      margin-top: 0px;
    }
    100% {
      margin-top: -270px;
    }
  } */

  /* @keyframes show {
    0% {
      margin-top: -480px;
    }
    5% {
      margin-top: -320px;
    }
    33% {
      margin-top: -320px;
    }
    38% {
      margin-top: -90px;
    }
    66% {
      margin-top: -160px;
    }
    71% {
      margin-top: 0px;
    }
    99.99% {
      margin-top: 0px;
    }
    100% {
      margin-top: -480px;
    }
  } */


  /* @keyframes show {
    0% {
      margin-top: -600px;
    }
    1% {
      margin-top: -480px;
    }
    12% {
        margin-top: -480px;
      }
    24% {
      margin-top: -480px;
    }
    48% {
      margin-top: -240px;
    }
    72% {
      margin-top: -240px;
    }
    96% {
      margin-top: 0px;
    }
    99.99% {
      margin-top: 0px;
    }
    100% {
      margin-top: -600px;
    }
  } */
  
  /* working code  */

  
  /* @keyframes show {

    0% {
      margin-top: -630px;
    }

    16% {
      margin-top: -630px;
    }

    32% {
        margin-top: -480px;
      }
    48% {
      margin-top: -480px;
    }
    64% {
      margin-top: -240px;
    }
    80% {
      margin-top: -240px;
    }
    96% {
      margin-top: 0px;
    }
    99.99% {
      margin-top: 0px;
    }
    100% {
      margin-top: -630px;
    }
  } */
  

  @keyframes show {

    0% {
      margin-top: -630px;
    }
    1% {
      margin-top: -630px;
    }


    16% {
      margin-top: -480px;
    }

    32% {
        margin-top: -480px;
      }
    48% {
      margin-top: -240px;
    }
    64% {
      margin-top: -240px;
    }
    80% {
      margin-top: 0px;
    }
    96% {
      margin-top: 0px;
    }
    99.99% {
      margin-top: 0px;
    }
    100% {
      margin-top: -630px;
    }
  }
  

  

/* 
header.header-service .hero-text h2 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    font-size: 80px !important;
    line-height: 97.99%;
    letter-spacing: 1px;    
    color: #ffffff;
} */

header.header-service .navbar-nav a {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.393478px;
    color: #FFFFFF;
}


header.header-service .hero-text {
    min-width: 1000px;
    margin: auto;
}

/* For 480 Resolution */  
@media only screen   
and (min-device-width : 320px)   
and (max-device-width : 480px)  
{ /* STYLES GO HERE */} 


/* For 480 Resolution */  
@media only screen   
and (min-device-width : 320px)   
and (max-device-width : 767px)  
{ /* STYLES GO HERE */

    header.header-service {
        background: url('../images/header-solution-bg-mb.webp') no-repeat top;
        background-position: 65%;
        background-size: cover;
    }
    
    header.header-service .hero-content {
        top:35%;
    }

    header.header-service .animation-svg svg {
        height: 100%;
    }

    header.header-service .hero-text {
        max-width: 300px !important;
        min-width: 300px !important;
    }
    header.header-service .hero-text h4 {
        font-size: 16px !important;
        line-height: 20px !important;
    }

    header.header-service .hero-text h6 {
        font-size: 16px !important;
        line-height: 20px !important;
    }

    header.header-service .hero-text h2 {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    header.header-service .hero-text h3 {
        font-size: 30px !important;
        line-height: 30px !important;
    }
    header.header-service .hero-text:before {
        width: 4px;
        left: -4px;
    }

    header.header-service .hero-text .text1, header.header-service .hero-text .text2 {
      height: 40px;
    }

    header.header-service .hero-text .text1 > div > h3, header.header-service .hero-text .text2 > div > h2 {
      margin-bottom: 50px;
    }

    /* @keyframes show {
      0% {margin-top:-270px;}
      5% {margin-top:-180px;}
      33% {margin-top:-180px;}
      38% {margin-top:-90px;}
      66% {margin-top:-90px;}
      71% {margin-top:0px;}
      99.99% {margin-top:0px;}
      100% {margin-top:-270px;}
    } */

    /* working */
    
    /* @keyframes show {

      0% {
        margin-top: -270px;
      }
  
      16% {
        margin-top: -270px;
      }
  
      32% {
          margin-top: -180px;
        }
      48% {
        margin-top: -180px;
      }
      64% {
        margin-top: -90px;
      }
      80% {
        margin-top: -90px;
      }
      96% {
        margin-top: 0px;
      }
      99.99% {
        margin-top: 0px;
      }
      100% {
        margin-top: -270px;
      }
    } */
    

      @keyframes show {

      0% {
        margin-top: -270px;
      }
  
      1% {
        margin-top: -270px;
      }
  
      16% {
        margin-top: -180px;
      }
  
      32% {
          margin-top: -180px;
        }
      48% {
        margin-top: -90px;
      }
      64% {
        margin-top: -90px;
      }
      80% {
        margin-top: 0px;
      }
      96% {
        margin-top: 0px;
      }
      99.99% {
        margin-top: 0px;
      }
      100% {
        margin-top: -270px;
      }
    }
} 


/* For 480 Resolution */  
@media only screen   
and (min-device-width : 768px)   
and (max-device-width : 1023px)  
{ /* STYLES GO HERE */

    header.header-service {
        background: url('../images/header-solution-bg-mb.webp') no-repeat top;
        background-position: 65%;
        background-size: cover;
    }
    
    header.header-service .hero-content {
        top:35%;
    }

    header.header-service .animation-svg svg {
        height: 100%;
    }

    header.header-service .hero-text {
        max-width: 650px !important;
        min-width: 650px !important;
    }
    header.header-service .hero-text h4 {
        font-size: 40px !important;
        line-height: 30px !important;
    }

  
    header.header-service .hero-text h2 {
        font-size: 75px !important;
        line-height: 70px !important;
    }

    header.header-service .hero-text h3 {
        font-size: 75px !important;
        line-height: 70px !important;
    }

    header.header-service .hero-text:before {
        width: 4px;
        left: -4px;
    }

    header.header-service .hero-text .text1, header.header-service .hero-text .text2 {
      height: 85px;
    }

    header.header-service .hero-text .text1 > div > h3, header.header-service .hero-text .text2 > div > h2 {
      margin-bottom: 75px;
    }

    #flip1 div:first-child {
      animation: show 5s linear infinite;
      /* animation: unset; */
    }
  
    
  #flip2 div:first-child {
    animation: show 5s linear infinite;    
    /* animation: unset; */
   }
  
  

   @keyframes show {

    0% {
      margin-top: -450px;
    }

    1% {
      margin-top: -450px;
    }

    16% {
      margin-top: -300px;
    }

    32% {
        margin-top: -300px;
      }
    48% {
      margin-top: -150px;
    }
    64% {
      margin-top: -150px;
    }
    80% {
      margin-top: 0px;
    }
    96% {
      margin-top: 0px;
    }
    99.99% {
      margin-top: 0px;
    }
    100% {
      margin-top: -450px;
    }
  }
} 




@media  (min-width: 1024px)  and (max-width: 1280px)  
{ 
    /* 84 */
    header.header-service .hero-text {
        min-width: 740px;
    }

    header.header-service .hero-text h3,
    header.header-service .hero-text h2 {
        font-size: 84px;
        line-height: 97.99%;
    }

    header.header-service .hero-text h4 {
        font-size: 42px;
    } 
        
}  


@media  (min-width: 1280px)  and (max-width: 1360px)  
{ 
    /* 90 */
    header.header-service .hero-text {
        min-width: 750px;
        margin: auto;
    }

    header.header-service .hero-text h3,
    header.header-service .hero-text h2 {
        font-size: 90px;
        line-height: 97.99%;
    }

    header.header-service .hero-text h4 {
        font-size: 45px;
    } 

}  

@media  (min-width: 1360px)  and (max-width: 1440px)  
{  
    /* 96 */ 

    header.header-service .hero-text {
        min-width: 790px;
        margin: auto;
    }
    
    header.header-service .hero-text h3,
    header.header-service .hero-text h2 {
        font-size: 96px;
        line-height: 97.99%;
    }

    header.header-service .hero-text h4 {
        font-size: 48px;
    } 

}  

@media  (min-width: 1440px)  and (max-width: 1680px)  
{  
    header.header-service .hero-text {
        min-width: 850px;
        margin: auto;
    }

    /* 102 */
    header.header-service .hero-text h3,
    header.header-service .hero-text h2 {
        font-size: 102px;
        line-height: 97.99%;
    }


    header.header-service .hero-text h4 {
        font-size: 51px;
    } 

}  

@media  (min-width: 1680px)  and (max-width: 1919px)  
{ 
    header.header-service .hero-text {
        min-width: 920px;
        margin: auto;
    }

    /* 108 */
    header.header-service .hero-text h3,
    header.header-service .hero-text h2 {
        font-size: 108px;
        line-height: 97.99%;
    }

    header.header-service .hero-text h4 {
        font-size: 54px;
    } 


}  

@media only screen  and (min-width: 1920px) {



} 