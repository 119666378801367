.job-openings-main-section {
    background: linear-gradient(121.92deg, #041E30 13.01%, #000000 74.52%);
    padding: 94px 0px;
    font-family: Helvetica !important;
}
.left-job-open-column-inner .latest-p-section p{
    font-size: 40px;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize; 
    padding-left: 10px !important;
}

.bottom-jobber-section {
    display: block;
}
.bottom-jobber-section-mobile-viewer {
    display: none;
}
.left-job-open-column-inner .bottom-jobber-section h2 {
    font-size: 80px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize; 
    display: flex;
    gap: 15px;
}
.left-job-open-column-inner .job-absolute-text p{
    font-family: 'Work Sans', sans-serif !important; 
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #2A5450;
    -webkit-text-stroke: 1px #2A5450;
    font-size: 160px;
    line-height: 180px;
    font-weight: 700;
}
.job-absolute-text p{
    width: fit-content;
    position: relative;
}

/* .job-absolute-text p::after {
    content: '';
    width: 100%;
    height: 8px;
    position: absolute;
    bottom: 5px;
    left: 0;
    border: 1px solid #2A5450; 
} */

.job-absolute-text {
    position: absolute;
    top: -20px;
    right: 25%;
} 
.bottom-jobber-section {
    position: relative;
    z-index: 99;
}
.bottom-jobber-section h2 span {
    display: inline-block;
    overflow: hidden;
    position: relative;
    padding-bottom: 15px !important;
}
.bottom-jobber-section h2 span::after {
    background: linear-gradient(to bottom, rgba(20, 20, 20, 0.8), rgba(20, 20, 20, 0.8)) center 1.08em/100% 5px no-repeat;
    content: "";
    height: 100%;
    right: 0;
    position: absolute;
    top: 25px;
    width: 400%;
    will-change: transform;
    z-index: 1;
    -webkit-animation: underline-gradient 6s linear infinite;
    animation: underline-gradient 6s linear infinite;
    background-image: linear-gradient(270deg, rgba(241, 21, 118, 1) 0%, rgba(1, 198, 178, 1) 20%, rgba(241, 21, 118, 1) 40%, rgba(1, 198, 178, 1) 60%, rgba(241, 21, 118, 1) 80%, rgba(1, 198, 178, 1) 100%);
}

.card-images-section {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    overflow: hidden;
}

.right-side-cards-job {
    gap: 78px 0px;
}
.bottom-absolute-section-desc {
    padding: 52px 25px;
    background: #fff;
}

.left-busy-head h2 {
    color: #000;
    font-size: 26px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize; 
    margin: 0px !important;
}
.job-sec-link span {
    color: #25635C;
    font-size: 18px;
    font-weight: 700;
    line-height: 23.4px;
    text-decoration: underline;
}
.job-sec-link {
    gap: 0px 11px;
}



@media only screen and (max-width: 991px) {
    .job-openings-row .col-md-6 {
        width: 100% !important;
    }
    .left-side-openings-header {
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 767px) {
    .bottom-absolute-section-desc {
        padding: 20px 25px;
    }
    .left-busy-head h2 {
      font-size: 20px;
    }
    .job-sec-link span {
      font-size: 16px;
      line-height: 20px;
    }
    .right-side-cards-job {
      gap: 35px 0px;
    }
    .bottom-jobber-section {
        display: none;
    }
    .bottom-jobber-section-mobile-viewer {
        display: block;
    }
    .left-job-open-column-inner .latest-p-section p {
        font-size: 30px;
      }
      .bottom-jobber-section-mobile-viewer h2 {
        font-size: 70px;
        font-weight: 700;
        position: relative;
      }
      .job-absolute-text p::after {
        display: none;
      }
      .job-absolute-text p {
        font-size: 120px !important;
        margin-bottom: 0px !important;
      }
      .job-absolute-text {
        top: -60px;
        right: 40%;
      }
      .bottom-jobber-section-mobile-viewer .job-animator-we-bottom-767-after {
        position: relative;
        width: 100%;
        max-width: 200px;
        margin: -20px 200px 0px auto;
        height: 25px;
        overflow: hidden;
    }
    .bottom-jobber-section-mobile-viewer .job-animator-we-bottom-767-after::after {
        background: linear-gradient(to bottom, rgba(20, 20, 20, 0.8), rgba(20, 20, 20, 0.8)) center 1.08em/100% 5px no-repeat;
        content: "";
        height: 100%;
        right: 0;
        position: absolute;
        top: 0px;
        width: 400%;
        will-change: transform;
        z-index: 1;
        -webkit-animation: underline-gradient 6s linear infinite;
        animation: underline-gradient 6s linear infinite;
        background-image: linear-gradient(270deg, rgba(241, 21, 118, 1) 0%, rgba(1, 198, 178, 1) 20%, rgba(241, 21, 118, 1) 40%, rgba(1, 198, 178, 1) 60%, rgba(241, 21, 118, 1) 80%, rgba(1, 198, 178, 1) 100%);
    }   
}


@media only screen and (max-width: 474px) {
    .bottom-absolute-section-desc {
        padding: 15px;
      }
      .left-busy-head h2 {
        font-size: 16px;
      }
      .job-sec-link span {
        font-size: 14px;
        line-height: normal;
      }
    .job-openings-main-section {
        padding: 35px 0px 44px 0px !important;
    }
    .left-job-open-column-inner .latest-p-section p {
        font-size: 26px;
        line-height: normal;
        padding-left: 0px !important;
        position: relative;
    }
    .bottom-jobber-section-mobile-viewer h2 {
        font-size: 50px;
        font-weight: 700;
    }
    .job-absolute-text p {
        font-size: 60px !important;
        margin-bottom: 0px !important;
    }
    .job-absolute-text {
        top: -68px;
        right: 49%;
    }
    .jobopenings-inner-section .container {
        padding: 0px 20px;
    }
    .bottom-jobber-section-mobile-viewer .job-animator-we-bottom-767-after {
        position: relative;
        width: 100%;
        max-width: 133px;
        margin: -20px 110px 0px auto;
        height: 21px;
        overflow: hidden;
    }
}
